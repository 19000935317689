import { css } from 'styled-components';

const style = () => css`
    background: #061b2c;
    padding: 10px 5px;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        margin: 0 auto !important;
        padding-top: 77px;
        padding-bottom: 77px;
        .ant-col-xs-24 {
            margin-bottom: 20px;
        }
        .questions-wrapper {
            @media (max-width: 1024px) {
                margin-top: 40px;
            }
        }
    }
    .mobile-button {
        display: none;
    }
    .desktop-button {
        display: block;
    }

    @media (max-width: 1024px) {
        .desktop-button {
            display: none;
        }
        .mobile-button {
            display: block;
        }
    }

    .caption {
        color: #ffffff;
        font-size: 20px;
    }

    h1 {
        color: #9afaa3 !important;
        /* font-size: 72px; */
        font-size: clamp(2rem, 4vw, 9rem);
        margin-bottom: 10px;
    }

    .ant-collapse {
        > .ant-collapse-item {
            border-bottom: 1px solid #475569;

            .ant-collapse-header {
                font-size: 20px;
                padding: 20px 5px;
                @media (max-width: 500px) {
                    font-size: 15px;
                    padding: 12px 40px 12px 1px;
                }
            }

            > .ant-collapse-header,
            .ant-collapse-content {
                color: #ffffff;
            }
        }
    }
`;

export default style();
