import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { UserDetails } from '../../../components/users/view-user';
import { ApiClient } from '../../../api';

const UserDetailsContainer = () => {
    const userId = useParams<'userId'>()?.userId;
    // const { data, isFetching } =
    useInfiniteQuery<Pagination<User>>(['users', userId], ApiClient.fetchUsers, {
        refetchOnWindowFocus: false,
    });

    return <UserDetails />;
};

export default UserDetailsContainer;
