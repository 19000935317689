import React from 'react';
import { useInfiniteQuery } from 'react-query';

import { ApiClient } from '../../api';
import Trackers from '../../components/trackers';
import { Columns } from './data';

const TrackerContainer = () => {
    const [search, setSearch] = React.useState<string>('');

    const { data, isFetching, fetchNextPage, fetchPreviousPage, isFetchingNextPage, isFetchingPreviousPage } =
        useInfiniteQuery<Pagination<User>>(['trackers', search], ApiClient.fetchTrackers, {
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                return lastPage.nextPage;
            },
        });

    const users = React.useMemo(() => {
        return data?.pages.map((page) => page.data).flat();
    }, [data]);

    const total = React.useMemo(() => {
        return data?.pages?.[0]?.total;
    }, [data]);

    return (
        <Trackers
            data={users}
            loading={isFetching}
            columns={Columns}
            total={total ?? 0}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            isFetchingNextPage={isFetchingNextPage}
            isFetchingPreviousPage={isFetchingPreviousPage}
            setFetchSearch={setSearch}
        />
    );
};

export default TrackerContainer;
