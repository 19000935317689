import React from 'react';
import omit from 'lodash/omit';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import styles from './styles/league-form';
import { API, ApiClient } from '../../../api';
import { mutationError } from '../../../utils/helper';
import { RewardList, SelectGame } from '../../common';

const NewLeagueFormWrapper = styled.div`
    ${styles}
`;

interface IProps {
    form?: FormInstance;
    buttonTitle?: string;
    leagueId?: string;
}

const NewLeagueForm: React.FC<IProps> = ({ form: _form, buttonTitle, leagueId }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isEndDisabled, setIsEndDisabled] = React.useState<boolean>(false);

    const [form] = Form.useForm(_form);

    const onFinish = React.useCallback(async (values: any) => {
        setLoading(true);
        try {
            await API.post(API.PATH.createLeague, values);
            setLoading(false);
            ApiClient.showSuccess('League successfully added');
            navigate(-1);
        } catch (e: any) {
            mutationError(e);
            setLoading(false);
        }
    }, []);

    const onUpdate = React.useCallback(
        async (values: any) => {
            setLoading(true);
            try {
                await API.patch(
                    API.PATH.updateLeague(leagueId as string),
                    omit({ ...values }, ['rewards', 'coin', 'start', 'end', 'gameId', 'frequency']),
                );
                setLoading(false);
                ApiClient.showSuccess('League successfully updated');
                navigate(-1);
            } catch (e: any) {
                mutationError(e);
                setLoading(false);
            }
        },
        [leagueId],
    );

    return (
        <NewLeagueFormWrapper>
            <Form
                layout="vertical"
                form={form}
                onFinish={leagueId ? onUpdate : onFinish}
                initialValues={{
                    rewards: [
                        {
                            position: 1,
                            from: 1,
                        },
                    ],
                }}
            >
                <div className="container">
                    <Row gutter={16}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Game'}
                                name="gameId"
                                rules={[{ required: true, message: 'Select league game' }]}
                            >
                                <SelectGame
                                    fetchDetails={!!leagueId}
                                    disabled={!!leagueId}
                                    placeholder={'Select league game'}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={'League Name'}
                                name="name"
                                rules={[{ required: true, message: 'Enter league name' }]}
                            >
                                <Input placeholder={'Enter league name'} className={'weplay-input'} />
                            </Form.Item>
                            <Form.Item
                                label="League Description"
                                name="description"
                                rules={[{ required: true, message: 'Enter Description' }]}
                            >
                                <Input.TextArea rows={5} placeholder="Enter Description" className={'weplay-input'} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={'League Frequency'}
                                name="frequency"
                                rules={[{ required: false, message: 'Enter league frequency' }]}
                            >
                                <Select
                                    options={[
                                        { label: 'Weekly', value: 'weekly' },
                                        { label: 'Daily', value: 'daily' },
                                    ]}
                                    placeholder={'Select league frequency'}
                                    disabled={!!leagueId}
                                    onChange={(value) => {
                                        const start = form.getFieldValue('start');
                                        if (start) {
                                            if (value === 'weekly') {
                                                form.setFieldsValue({
                                                    end: moment(start).add(7, 'days'),
                                                });
                                            } else if (value === 'daily') {
                                                form.setFieldsValue({
                                                    end: moment(start).add(1, 'days'),
                                                });
                                            }
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={'Coins Required to join'}
                                name="coin"
                                rules={[{ required: true, message: 'Enter league coin value' }]}
                            >
                                <InputNumber
                                    disabled={!!leagueId}
                                    placeholder={'Enter number of coins for league'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={'League Start Date'}
                                name="start"
                                rules={[{ required: true, message: 'Enter league start date' }]}
                            >
                                <DatePicker
                                    disabled={!!leagueId}
                                    showTime
                                    placeholder={'Select league start date'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    showNow={false}
                                    disabledDate={(current) => current.isBefore(moment())}
                                    onChange={(date) => {
                                        const frequency = form.getFieldValue('frequency');
                                        if (frequency === 'weekly') {
                                            form.setFieldsValue({
                                                end: moment(date).add(7, 'days'),
                                            });
                                            setIsEndDisabled(true);
                                        } else if (frequency === 'daily') {
                                            form.setFieldsValue({
                                                end: moment(date).add(1, 'days'),
                                            });
                                            setIsEndDisabled(true);
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={'League End Date'}
                                name="end"
                                rules={[{ required: true, message: 'Enter league end date' }]}
                            >
                                <DatePicker
                                    disabled={!!leagueId || isEndDisabled}
                                    showTime
                                    placeholder={'Select league end date'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    showNow={false}
                                    disabledDate={(current) => current.isBefore(moment())}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <RewardList disabled={!!leagueId} title="League Rewards" />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button type="primary" htmlType="submit" className="create-button" loading={loading}>
                                {buttonTitle ?? 'Add League'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </NewLeagueFormWrapper>
    );
};

export default NewLeagueForm;
