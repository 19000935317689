import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Button, Card, Popover, Rate, Typography } from 'antd';
import { LineChartOutlined, ThunderboltOutlined } from '@ant-design/icons';

import style from './styles';
import { ROUTES } from '../../../../router';
import { popoverStyles } from './styles/popover';
import { API } from '../../../../api';

const { Title, Link } = Typography;

const GameCardContainer = styled.div`
    ${style}
`;

const PopOverWrapper = styled.div`
    ${popoverStyles}
`;

interface GameCardProps {
    data: Game;
    loading?: boolean;
    setLoading?: any;
}

const GameCard: FC<GameCardProps> = ({ data }) => {
    const navigate = useNavigate();
    const { isLoading, mutate: toggleHighlight } = useMutation<any>('toggleHighlight');
    const { isLoading: isLoadingTrend, mutate: toggleTrending } = useMutation<any>('toggleTrending');

    const cardStyle = {
        minWidth: '200px',
        borderRadius: '16px',
        boxShadow: '5px 8px 24px 5px rgba(231, 231, 233, 0.6)',
        padding: '0.7rem',
    };

    const viewGame = (id) => {
        navigate(ROUTES.VIEW_GAMES.split(':').shift() + `${id}`, {
            state: { gameId: id },
        });
    };

    function setTrending(e: React.MouseEvent) {
        e.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toggleTrending(data.id);
    }

    function setHighLight(e: React.MouseEvent) {
        e.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toggleHighlight(data.id);
    }

    const addTrending = (
        <PopOverWrapper>
            {data?.trending ? (
                <span className="trending-text">Trending</span>
            ) : (
                <Button className="trending-btn primary-button" loading={isLoadingTrend} onClick={setTrending}>
                    Set Trending
                </Button>
            )}
        </PopOverWrapper>
    );

    const addHighlight = (
        <PopOverWrapper>
            {data?.highlighted ? (
                <span className="highlight-text">Highlighted</span>
            ) : (
                <Button className="highlight-btn primary-button" loading={isLoading} onClick={setHighLight}>
                    Set HighLight
                </Button>
            )}
        </PopOverWrapper>
    );

    return (
        <GameCardContainer>
            <Card
                cover={<img alt={data?.title} src={API.getImageUrl(data?.images?.at(0))} />}
                style={cardStyle}
                onClick={() => viewGame(data?.id)}
            >
                <Title level={5}>{data.title}</Title>

                <>
                    <Link className={'developer-name'}>
                        <img src={API.getImageUrl(data?.createdBy?.avatar)} alt="user-avatar" className="user-avatar" />
                        {data?.createdBy?.fullName}
                    </Link>
                    <div className="button-container">
                        <Rate allowHalf disabled value={Number(data?.rating)} />
                        <div className="icons">
                            <Popover content={addTrending}>
                                <LineChartOutlined style={{ marginRight: 10, color: data?.trending ? 'green' : '' }} />
                            </Popover>

                            <Popover content={addHighlight}>
                                <ThunderboltOutlined style={{ color: data?.highlighted ? 'orange' : '' }} />
                            </Popover>
                        </div>
                    </div>
                </>
            </Card>
        </GameCardContainer>
    );
};

export default GameCard;
