import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Card, Col, Row, Skeleton, Table, Typography } from 'antd';
import { FaCoins } from 'react-icons/fa';
import moment from 'moment';
import ClockGif from '../../assets/images/animat-clock-gif.gif';
import { ApiClient } from '../../api';
import { GameDetails } from '../common';
import { capitalizeFirstLetter } from '../../utils/helper';

interface IProps {
    data: LeagueDetails;
}

const Wrapper = styled.div`
    h2.ant-typography {
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 24px;
        text-transform: capitalize;
    }

    .ant-card-head-title {
        padding: 8px 0;
    }

    .game-details,
    .data-card {
        box-shadow: rgb(231 231 233 / 60%) 5px 8px 24px 5px;
    }

    .data-card {
        width: 100%;
        margin-bottom: 20px;
        border: none;
    }

    .fees {
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .fees-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    h5.ant-typography {
        color: rgba(0, 0, 0, 0.7);
        margin: unset;
        padding: unset;
        line-height: unset;
    }

    .ant-card-body {
        padding: 15px 15px 0 24px;
    }

    .ant-table-wrapper {
        margin-top: 20px;
    }
    .ant-table-title {
        padding-left: 16px;
        padding-top: 0;
        padding-bottom: 0;
        /* padding: 0; */
    }

    h4.ant-typography {
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.75);
        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
    }

    .description {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.67);
        margin-top: 0.5rem;
    }
`;
const { Title } = Typography;

const columns = [
    {
        title: 'Position',
        dataIndex: 'position',
    },
    {
        title: 'From',
        dataIndex: 'from',
    },
    {
        title: 'To',
        dataIndex: 'to',
    },
    {
        title: 'Coins',
        dataIndex: 'coin',
    },
    {
        title: 'Points',
        dataIndex: 'points',
    },
];

const LeagueDetails: React.FC<IProps> = ({ data }) => {
    const { data: gameData, isFetching } = useQuery(['game-detail', data.gameId], ApiClient.getGameDetails, {
        enabled: Boolean(data.gameId),
        refetchOnWindowFocus: false,
        refetchInterval: false,
    });

    return (
        <Wrapper>
            <Row gutter={10}>
                <Col span={12}>
                    <Card title={<Title level={2}>{data?.name}</Title>} bordered={false} className="data-card">
                        <div className="fees-container">
                            Coins:{' '}
                            <span className="fees">
                                {data?.coin} <FaCoins color="green" fontSize={20} />{' '}
                            </span>
                        </div>
                        <h5 className="description">Frequency: {capitalizeFirstLetter(data?.frequency)}</h5>
                        <h6 className="description">{data?.description}</h6>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card className="data-card">
                        <Row>
                            <Col span={14} style={{ background: 'transparent' }}>
                                <Title level={5} style={{ color: '#16a085' }}>
                                    START AT
                                </Title>
                                <p>{moment(data?.start).format('MM/DD/YYYY HH:mm')}</p>
                                <Title level={5} style={{ color: '#d35400' }}>
                                    ENDS AT
                                </Title>
                                <p>{moment(data?.end).format('MM/DD/YYYY HH:mm')}</p>
                            </Col>
                            <Col span={10}>
                                <img src={ClockGif} alt="gif" height={100} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Skeleton loading={isFetching}>
                        <GameDetails item={gameData} disabled />
                    </Skeleton>
                </Col>
            </Row>

            <Table
                title={() => <Title level={4}>League Rewards</Title>}
                dataSource={data.rewards}
                columns={columns}
                pagination={false}
            />
        </Wrapper>
    );
};

export default LeagueDetails;
