import { css } from 'styled-components';
import background from '../../../../assets/icons/background.svg';
import { COLORS } from '../../../../config/theme';
const style = () => css`
    height: 100vh;
    width: 100vw;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .sign-in-card {
        width: 450px;
        max-width: 90vw;
        height: 500px;
        max-height: 100%;
        background-color: ${(props: any) => props.theme[COLORS.WHITE]};
        border-radius: 10px;
        box-shadow: ${(props: any) => props.theme[COLORS.CARD_SHADOW]};
        display: flex;
        justify-content: center;
        align-items: center;
        .heading {
            font-size: 20px;
            text-align: center;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            font-weight: bold;
            margin-bottom: 15px;
        }
        .sub-heading {
            font-size: 13px;
            text-align: center;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            font-weight: 400;
            margin-bottom: 40px;
        }
        .primary-button {
            margin-left: auto;
        }
        .form-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
`;

export default style();
