import { css } from 'styled-components';

const style = () => css`
    padding: 100px 20px 20px;

    .ant-layout-footer {
        padding: 0px !important;
        margin-top: 100px;
    }
    .delete-account-container {
        width: 100%;
        display: flex;
        justify-content: center;
        .delete-account-card {
            width: 100%;
            max-width: 500px;
            padding: 20px;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0px 0px 10px 0px #0000001a;
            border: 1px solid #f0f0f0;
            display: flex;
            flex-direction: column;
            align-items: center;
            h1 {
                font-size: 24px;
                margin-bottom: 20px;
                text-align: center;
            }
            img {
                width: 70%;
                margin-bottom: 20px;
            }
            .phone-input {
                width: 100%;
                .otp-input {
                    height: 50px;
                    border-radius: 5px;
                }
            }
            .primary-button {
                width: 100%;
                margin-top: 50px;
                background: #ff4d4f;
                border: none;
            }
        }
    }
`;

export default style();
