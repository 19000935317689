import React, { FC, useMemo } from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Icon, {
    MenuOutlined,
    SettingOutlined,
    DashboardOutlined,
    TeamOutlined,
    AppstoreOutlined,
    CodeOutlined,
} from '@ant-design/icons';
import { MdMeetingRoom } from 'react-icons/md';
import { IoServerOutline, IoTrophyOutline } from 'react-icons/io5';
import { MdForkRight } from 'react-icons/md';
import { RiCoinsLine } from 'react-icons/ri';

import logo from '../../assets/images/logo.png';

import style from './sidebar.styles';
import { ROUTES } from '../../router';
import { GameSvg } from '../../assets/icons';

const MenuWrapper = styled.div`
    ${style}
`;

interface SideBarProps {
    onPress?: () => void;
}

const SideBar: FC<SideBarProps> = ({ onPress }) => {
    const _location = useLocation().pathname;

    const keys = useMemo(() => {
        return _location.split('/').filter((a) => !!a);
    }, []);

    return (
        <MenuWrapper>
            <Menu
                mode="inline"
                theme={'light'}
                defaultSelectedKeys={[keys[0]]}
                style={{ height: '100%', borderRight: 0 }}
            >
                <div className="header">
                    <MenuOutlined onClick={onPress} />
                    <img className={'logo'} src={logo} alt={'weplay-logo'} />
                </div>
                <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                    <Link to={ROUTES.DASHBOARD} />
                    Dashboard
                </Menu.Item>
                <Menu.Item
                    key="payments"
                    icon={
                        <span className="icon-span">
                            <RiCoinsLine className="extra-icon" />
                        </span>
                    }
                >
                    <Link to={ROUTES.PAYMENTS} />
                    Payments
                </Menu.Item>
                <Menu.Item key="category" icon={<AppstoreOutlined />}>
                    <Link to={ROUTES.CATEGORY} />
                    Category
                </Menu.Item>
                <Menu.Item key="developers" icon={<CodeOutlined />}>
                    <Link to={ROUTES.DEVELOPER} />
                    Developers
                </Menu.Item>
                <Menu.Item key="games" icon={<Icon component={GameSvg} />}>
                    <Link to={ROUTES.GAMES} />
                    Games
                </Menu.Item>
                <Menu.Item
                    key="rooms"
                    icon={
                        <span className="icon-span">
                            <MdMeetingRoom className="extra-icon" />
                        </span>
                    }
                >
                    <Link to={ROUTES.ROOMS} />
                    Rooms
                </Menu.Item>
                <Menu.Item
                    key="leagues"
                    icon={
                        <span className="icon-span">
                            <IoTrophyOutline className="extra-icon" />
                        </span>
                    }
                >
                    <Link to={ROUTES.LEAGUES} />
                    Leagues
                </Menu.Item>
                <Menu.Item
                    key="knockout"
                    icon={
                        <span className="icon-span">
                            <MdForkRight className="extra-icon" />
                        </span>
                    }
                >
                    <Link to={ROUTES.KNOCKOUT} />
                    Knockouts
                </Menu.Item>
                <Menu.Item key="users" icon={<TeamOutlined />}>
                    <Link to={ROUTES.USERS} />
                    Users
                </Menu.Item>
                <Menu.Item
                    key="trackers"
                    icon={
                        <span className="icon-span">
                            <IoServerOutline className="extra-icon" />
                        </span>
                    }
                >
                    <Link to={ROUTES.TRACKER} />
                    Trackers
                </Menu.Item>
                <Menu.Item key="settings" icon={<SettingOutlined />}>
                    <Link to={ROUTES.SETTINGS} />
                    Settings
                </Menu.Item>
            </Menu>
        </MenuWrapper>
    );
};

export default SideBar;
