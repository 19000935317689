import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    width: 100%;
    box-shadow: ${(props) => props.theme[COLORS.CARD_SHADOW]};
    background: ${(props) => props.theme[COLORS.WHITE]};
    border-radius: 8px;
    padding: 16px;
    .stats-heading {
        font-size: 1.2rem;
        font-weight: 900;
        color: #8884d8;
        text-transform: uppercase;
    }
    .total-hours {
        font-weight: 600;
        color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

export default style();
