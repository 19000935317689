import { css } from 'styled-components';

const style = () => css`
    .header {
        display: flex;
        .flex-1 {
            flex: 1;
        }
    }
    .game-container {
        margin-bottom: 20px;
        border-radius: 5px;
    }
    .ant-list-footer {
        padding-top: 0;
    }
`;

export default style();
