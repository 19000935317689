import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col, Divider, Image, Layout, Row, Typography } from 'antd';
import styles from '../styles/footer-styles';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';

import logo from '../../../assets/images/logo.png';
import icon from '../../../assets/images/weplay-icon.png';
import PlayStore from '../../../assets/images/google.svg';
import AppStore from '../../../assets/images/appstore.svg';
import { ROUTES } from '../../../router';
import { FaGlobe } from 'react-icons/fa';

const { Text } = Typography;

const HomeFooterWrapper = styled(Layout.Footer)`
    ${styles}
`;

const HomeFooter = () => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = 'https://games.weplay.africa/files/weplay.apk';
        link.download = 'weplay.apk';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <HomeFooterWrapper>
            <div className="container">
                <Row gutter={8}>
                    <Col className={'link-section logo'} xs={12} sm={0} style={{ display: 'none' }}>
                        <img src={icon} alt="app-logo" />
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                        <div className="footer-about">
                            <div>
                                <img className="footer-logo" src={logo} alt="app-name-logo" />
                            </div>
                            <Text>
                                Weplay is an esports-focused mobile social gaming app that allows players to battle in
                                multiplayer games with other players from around the world.
                            </Text>

                            <Row style={{ marginTop: 20, gap: 8 }}>
                                <div
                                    className="icon-wrapper"
                                    onClick={() => {
                                        window.open('https://www.weplay.game');
                                    }}
                                >
                                    <FaGlobe className="icon" />
                                    <div className="web-text-wrapper">
                                        <span>PLAY ON</span>
                                        <span className="web-text">WEBSITE</span>
                                    </div>
                                </div>
                                <Image
                                    src={PlayStore}
                                    preview={false}
                                    onClick={handleDownload}
                                    className="playstore-img"
                                />
                                <Image
                                    src={AppStore}
                                    preview={false}
                                    onClick={() => {
                                        window.open('https://apps.apple.com/us/app/weplay-arcade/id1642292314');
                                    }}
                                    className="appstore-img"
                                />
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={16} className="bottom-section">
                    <div className="footer-links">
                        <a href="https://www.linkedin.com/company/weplayarcade/" target="_blank" rel="noreferrer">
                            <LinkedinOutlined style={{ fontSize: '24px', color: '#000' }} />
                        </a>
                        <a href="https://www.instagram.com/weplay.arcade/" target="_blank" rel="noreferrer">
                            <InstagramOutlined style={{ fontSize: '24px', color: '#000' }} />
                        </a>
                        <a
                            href="https://www.facebook.com/profile.php?id=100082271257452"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FacebookOutlined style={{ fontSize: '24px', color: '#000' }} />
                        </a>
                        <a href="https://twitter.com/WeplayArcade" target="_blank" rel="noreferrer">
                            <TwitterOutlined style={{ fontSize: '24px', color: '#000' }} />
                        </a>

                        <Col className="copyright">© Weplay {new Date().getFullYear()}</Col>
                    </div>
                    <Col className="term-con-con">
                        <Link to={ROUTES.TERMS_CONDITION}>
                            <Text>Terms and Conditions</Text>
                        </Link>
                    </Col>
                    <Col className="term-con-con">
                        <Link to={ROUTES.FAIR_PLAY_POLICY}>
                            <Text>Fair play Policy</Text>
                        </Link>
                    </Col>
                    <Col className="term-con-con">
                        <Link to={ROUTES.PRIVACY_POLICY}>
                            <Text>Privacy Policy</Text>
                        </Link>
                    </Col>

                    <Col className="term-con-con">
                        <Text>UK | EN</Text>
                    </Col>
                </Row>
            </div>
        </HomeFooterWrapper>
    );
};

export default HomeFooter;
