import { paginationStyles } from '../../common-styles';

const PaginationConfig = {
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total}`,
    itemRender: (_, type, originalElement) => {
        switch (type) {
            case 'next':
                return originalElement;
            case 'prev':
                return originalElement;
            default:
                return null;
        }
    },
    showSizeChanger: false,
};

export default PaginationConfig;

export { paginationStyles };
