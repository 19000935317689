import { combineReducers } from 'redux';
import authReducer from '../../containers/authentication/store/reducer';
import { AuthReducer } from '../../containers/authentication/store/types';

export const rootReducer = combineReducers({
    auth: authReducer,
});

export interface RootState {
    auth: AuthReducer;
}
