import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    box-shadow: ${(props) => props.theme[COLORS.CARD_SHADOW]};
    background: ${(props) => props.theme[COLORS.WHITE]};
    border-radius: 16px;
    padding: 16px;
    display: flex;

    div {
        :first-child {
            flex: 1;
        }
    }

    .card-title {
        font-size: 16px;
        color: #8c8c8d;
        padding-bottom: 0.25em;
    }
    .anticon {
        padding: 8px;
        border-radius: 14px;
        background-color: rgba(131, 129, 254, 0.3);
        color: rgb(131, 129, 254);
    }
`;

export default style();
