import { css } from 'styled-components';

const style = () => css`
    .ant-upload.ant-upload-drag {
        border-radius: 5px;
    }

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 24px 0px;
    }

    .upload-container {
        padding: 5px 0;
    }

    .upload-item {
        margin: 10px 0 0;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .ant-form-item-control {
            width: 100%;
        }
    }
    .ant-upload-text {
        font-size: 12px;
        font-weight: 400;
        margin-left: 10px;
        color: rgba(0, 0, 0, 0.7);
    }
    .required label:after {
        color: #e74c3c;
        content: ' *';
        display: inline;
    }
    .required-label:after {
        color: #e74c3c;
        content: ' *';
        display: inline;
    }
`;

export default style;
