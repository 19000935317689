import React, { FC } from 'react';
import { List } from 'antd';
import { useIsFetching } from 'react-query';

import PendingReviewList from './PendingReviews';

interface Props {
    reviews?: Pagination<Review>;
    gameId?: string;
}

const PendingReviews: FC<Props> = ({ gameId, reviews }) => {
    const isFetching = useIsFetching(['pendingReview', gameId], { exact: true });

    return (
        <div>
            <List
                loading={!!isFetching}
                className="comment-list"
                header={`${reviews?.total || 0} reviews`}
                itemLayout="horizontal"
                dataSource={reviews && reviews?.data}
                locale={{ emptyText: <h1 style={{ fontSize: '15px' }}>No Pending Reviews</h1> }}
                renderItem={(item: any) => <PendingReviewList data={item} />}
            />
        </div>
    );
};

export default PendingReviews;
