import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import moment from 'moment';
import React from 'react';
import { lpad } from '../../../utils/helper';

const Columns = (onDelete: (categoryId: string) => Promise<void>, onEdit) => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (value) => lpad(value, 5).toUpperCase(),
        },
        {
            title: 'TITLE',
            dataIndex: 'name',
        },
        {
            title: 'CREATED AT',
            dataIndex: 'createdAt',
            render: (value) => moment(value).format('MM/DD/YYYY'),
        },
        {
            title: 'ACTIONS',
            dataIndex: 'id',
            align: 'left',
            render: (id, record) => (
                <div className={'action-button'}>
                    <div>
                        <EditOutlined onClick={() => onEdit?.(record)} />
                    </div>
                    <div>
                        <Popconfirm
                            title="Sure to delete?"
                            okText={'Yes'}
                            cancelText={'No'}
                            onConfirm={async () => await onDelete(id)}
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </div>
                </div>
            ),
            width: '10%',
        },
    ];
};
export { Columns };
