import React from 'react';
import { Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { playClickAction } from '../../../../utils/helper';

const { Title, Text } = Typography;

const Wrapper = styled.div<Pick<CardProps, 'slideIndex' | 'index'>>`
    position: absolute;
    bottom: 14%;
    color: #fff;
    overflow-x: hidden;
    left: 9%;
    z-index: 3;

    .ant-typography {
        color: #fff;
        font-size: 18px;
    }

    h5.ant-typography {
        font-size: clamp(1.5rem, 5vw, 7rem);
        margin: unset;
        padding: unset;
        line-height: unset;
        font-weight: 800;
    }

    @media (max-width: 600px) {
        bottom: 100px;
        left: 32px;
        right: 32px;

        h5.ant-typography {
            font-size: 2rem;
        }
    }

    @media (max-width: 430px) {
        h5.ant-typography {
            font-size: 24px;
            padding-top: 8px;
        }
    }
`;

export interface TrendingGame {
    id: string;
    title: string;
    thumbnail: string;
    description: string;
    createdBy: Developer;
    images: ImagesEntity[];
}

interface ImagesEntity {
    imageURL: string;
}

interface CardProps {
    slideIndex?: number;
    index: number;
    item: TrendingGame;
}

const SliderCard: React.FC<CardProps> = ({ slideIndex, index, item }) => {
    return (
        <Wrapper className="bottom-content" index={index} slideIndex={slideIndex}>
            <div className="content">
                <Title level={5}>{item?.title}</Title>
                <Text>{item?.createdBy.fullName}</Text>
                <br />
                <Button className="primary-button" style={{ marginTop: '10px' }} onClick={playClickAction}>
                    Play for free
                    <ArrowRightOutlined />
                </Button>
            </div>
        </Wrapper>
    );
};

export default SliderCard;
