const titleMap = {
    dashboard: 'Weplay Insights',
    settings: 'Settings',
    payments: 'Deposits & Withdrawals',
    users: 'Users',
    games: 'Games',
    category: 'Categories',
    developers: 'Game Developers',
    rooms: 'Game Rooms',
    leagues: 'Weplay Leagues',
    knockout: 'Knockout Tournaments',
    trackers: 'Trackers',
};

export const getTitleFromPath = (pathname: string) => {
    // Name after the host name
    const path = pathname.split('/')[1];
    return titleMap[path] || path;
};
