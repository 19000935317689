const prefix = 'review';

//Rating apis

export const getRatingStats = (gameId: string) => `${prefix}/aggregate/${gameId}`;

//Reviews and Comments

export const getReviews = (id: string | number) => `${prefix}/admin/${id}`;

export const approveReview = (reviewId: string | number) => `${prefix}/${reviewId}/approval/true`;
export const hideReview = (reviewId: string | number) => `${prefix}/${reviewId}/approval/false`;
