import { axios } from '..';

function InMemoryJWTManager() {
    let _accessToken: string | undefined;

    const setAccessToken = (accessToken?: string) => {
        _accessToken = accessToken;
        if (accessToken) {
            (axios.defaults.headers as any).Authorization = `Bearer ${accessToken}`;
        } else {
            resetAccessToken();
        }
    };

    const getAccessToken = () => _accessToken;

    const hasAccessToken = () => !!_accessToken;

    const resetAccessToken = () => {
        _accessToken = undefined;
        delete (axios.defaults.headers as any).Authorization;
    };

    return {
        // Auth Access Token
        setAccessToken,
        getAccessToken,
        resetAccessToken,

        hasAccessToken,
    };
}

export default InMemoryJWTManager();
