import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { DrawerLayout } from '../../Layout';
import style from './styles';
import { PaginationConfig, paginationStyles } from '../common';

const UserWrapper = styled.div`
    ${style}
    ${paginationStyles}
`;

interface IProps {
    columns: any;
    data?: User[];
    loading: boolean;
    total?: number;
    fetchNextPage?: () => void;
    fetchPreviousPage?: () => void;
    isFetchingNextPage?: boolean;
    isFetchingPreviousPage?: boolean;
    setFetchSearch?: (value: string) => void;
}

const Users: FC<IProps> = ({
    columns,
    data,
    loading,
    total,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    setFetchSearch,
}) => {
    const [search, setSearch] = React.useState<any>('');
    const [page, setPage] = React.useState<any>(1);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPage(1);
            setFetchSearch?.(search);
        }, 500);
        return () => clearTimeout(timeout);
    }, [search]);

    return (
        <DrawerLayout>
            <UserWrapper>
                <div className={'header'}>
                    <div className="flex-1" />
                    <div>
                        <Input
                            value={search}
                            allowClear
                            placeholder={'Search Users'}
                            prefix={<SearchOutlined />}
                            onChange={(event) => {
                                setSearch(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <Table
                    loading={loading || isFetchingNextPage || isFetchingPreviousPage}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={data}
                    locale={{ emptyText: 'No Users' }}
                    pagination={{
                        ...PaginationConfig,
                        total: total,
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total}`,
                        onChange: (pageTable) => {
                            if (pageTable > page) {
                                fetchNextPage?.();
                            } else if (pageTable < page) {
                                fetchPreviousPage?.();
                            }
                            setPage(pageTable);
                        },
                    }}
                />
            </UserWrapper>
        </DrawerLayout>
    );
};

export default Users;
