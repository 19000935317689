import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Form, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { DrawerLayout } from '../../../Layout';
import styles from '../new-game/styles';
import NewGameForm from '../new-game/NewGameForm';
import TopBar from '../../top-bar';
import { ApiClient } from '../../../api';
import { generateImageData } from '../../../utils/helper';

const AddGameWrapper = styled.div`
    ${styles}
`;

const EditGame = () => {
    const gameId = useParams<'gameId'>()?.gameId;
    const [form] = Form.useForm();

    const { isLoading } = useQuery<Game>(['game', gameId], ApiClient.getGameDetails, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            const createdBy = response?.createdBy;
            form.setFieldsValue({
                ...response,
                categories: response?.categories.map((val) => val.id),
                images: response?.images?.map(generateImageData),
                thumbnail: response?.thumbnail ? [generateImageData(response.thumbnail)] : [],
                createdBy: createdBy.id,
            });
        },
    });

    return (
        <DrawerLayout>
            <AddGameWrapper>
                <TopBar title="Games" subtitle={'Edit Game'} isAdd={false} path={-1} />
                <Skeleton loading={isLoading}>
                    <NewGameForm form={form} buttonTitle={'Update'} gameId={gameId} />
                </Skeleton>
            </AddGameWrapper>
        </DrawerLayout>
    );
};

export default EditGame;
