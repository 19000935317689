import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Col, Divider, Form, FormProps, Input, InputNumber, Row } from 'antd';
import { selectStyles } from '../../../common-styles';
import { SelectGame } from '../../../common';

const FormWrapper = styled(Form)`
    ${selectStyles}
`;

interface IProps extends FormProps {
    loading?: boolean;
}

const GameRoomForm: FC<IProps> = ({ children, loading, ...props }) => {
    return (
        <FormWrapper
            layout={'vertical'}
            initialValues={{
                rewards: [
                    {
                        position: 1,
                        from: 1,
                    },
                ],
            }}
            {...props}
        >
            <Form.Item label={'Game'} name="gameId" rules={[{ required: true, message: 'Select room game' }]}>
                <SelectGame
                    // fetchDetails={!!leagueId}
                    // disabled={!!leagueId}
                    placeholder={'Select league game'}
                />
            </Form.Item>
            <Form.Item
                name={'name'}
                label={'Room Name'}
                rules={[{ required: true, message: 'Please enter room name!' }]}
            >
                <Input className={'weplay-input'} placeholder={'Enter room name'} />
            </Form.Item>
            <Form.Item
                name={'description'}
                label={'Room description'}
                rules={[{ required: true, message: 'Please enter room description!' }]}
            >
                <Input.TextArea rows={4} className={'weplay-input'} placeholder={'Enter room description'} />
            </Form.Item>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name={'fee'}
                        label={'Room Joining Fee'}
                        rules={[{ required: true, message: 'Please enter room joining fee!' }]}
                    >
                        <InputNumber
                            controls={false}
                            className={'weplay-input'}
                            placeholder={'Enter room joining fee'}
                            style={{ width: '100%' }}
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={'platformFee'}
                        label={'Platform Fee'}
                        rules={[{ required: true, message: 'Please enter platform fee!' }]}
                    >
                        <InputNumber
                            controls={false}
                            className={'weplay-input'}
                            placeholder={'Enter platform fee'}
                            style={{ width: '100%' }}
                            min={0}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left" orientationMargin="0">
                Winners Rewards
            </Divider>
            <Row gutter={16}>
                <Col span={0}>
                    <Form.Item name={['rewards', 0, 'position']} hidden initialValue={1} />
                    <Form.Item name={['rewards', 0, 'from']} hidden initialValue={1}>
                        <InputNumber disabled />
                    </Form.Item>
                    <Form.Item name={['rewards', 0, 'to']} hidden initialValue={1}>
                        <InputNumber disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={'Points'}
                        name={['rewards', 0, 'points']}
                        rules={[{ required: true, message: 'Enter winning points' }]}
                    >
                        <InputNumber
                            // disabled={disabled}
                            placeholder={'Enter winning points'}
                            className={'weplay-input'}
                            style={{ width: '100%' }}
                            min={0}
                            controls={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={'Coins'}
                        name={['rewards', 0, 'coin']}
                        rules={[{ required: true, message: 'Enter reward coins' }]}
                    >
                        <InputNumber
                            // disabled={disabled}
                            placeholder={'Enter reward coins'}
                            className={'weplay-input'}
                            style={{ width: '100%' }}
                            min={0}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {children}
            <Form.Item style={{ textAlign: 'right', margin: 0 }}>
                <Button className={'primary-button'} htmlType={'submit'} loading={loading}>
                    Save
                </Button>
            </Form.Item>
        </FormWrapper>
    );
};

export default GameRoomForm;
