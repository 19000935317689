import { css } from 'styled-components';

const style = () => css`
    .add-container {
        /* padding: 10px; */
        width: 100%;
        height: 50px;
        /* margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .add {
            width: auto;
            height: 35px;
            margin-right: 20px;
            background-color: #111;
            color: #fff;
            border: 1px solid #111;
        }
        .plus-icon {
            width: 15px;
            height: 15px;
            margin-right: 7px;
        }
    }

    .drop-down {
        background: blue !important;
    }
    .select-box {
        .add {
            color: red;
        }
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 43px !important;
    }
`;

export default style();
