import { Col, Image, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Logo from '../../../assets/images/Layer-2.png';
import PlayStore from '../../../assets/images/google.svg';
import PlayStoreMob from '../../../assets/images/google-mob.svg';
import AppStore from '../../../assets/images/appstore.svg';
import AppStoreMob from '../../../assets/images/apple-mob.svg';
import { FaGlobe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NavWrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 10px;

    z-index: 100;
    //background: red;

    //width: calc(100% - 32px);
    //max-width: 1530px;
    height: auto;

    opacity: 1;
    transition: opacity 500ms ease-in-out;

    .nav-container {
        width: calc(100% - 32px);
        max-width: 1530px;
        background: #ffffff;
        border-radius: 10px;
        margin: 0 auto;
        row-gap: 16px;
        //box-shadow: 0 2px 8px rgba(172, 168, 168, 0.25);
        -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icons-wrapper {
            display: flex;
        }
        .icon-wrapper {
            display: flex;
            align-items: center;
            background: #000000;
            border-radius: 5px;
            padding: 2px 8px;
            gap: 8px;
            min-width: 100px;
            cursor: pointer;
            .web-text-wrapper {
                display: flex;
                flex-direction: column;
                gap: 2px;
            }
            .web-text {
                font-size: 10px;
            }
            .icon {
                font-size: 20px;
                color: #fff;
            }
            span {
                font-size: 7px;
                font-weight: 500;
                color: #fff;
            }
        }
        .logo-img {
            max-height: 36px;
            max-width: 150px;
        }
        .playstore-img {
            cursor: pointer;
            max-height: 50px;
            margin-left: 8px;
        }
        .playstore-img-mob,
        .appstore-img-mob {
            cursor: pointer;
            display: none;
        }
        .appstore-img {
            cursor: pointer;
            max-height: 40px;
            margin-left: 12px;
            margin-right: 10px;
        }
    }
    @media (max-width: 765px) {
        .nav-container {
            padding: 8px 10px;
            min-height: 50px;
            .icons-wrapper {
                gap: 0px;
                .icon-wrapper {
                    min-width: 20px;
                    height: 38px;
                    .web-text-wrapper {
                        display: none !important;
                    }
                }
            }

            .logo-img {
                height: 28px;
                width: auto;
            }
            .playstore-img {
                display: none;
            }
            .playstore-img-mob,
            .appstore-img-mob {
                display: block;
                margin-left: 6px;
                height: 38px;
            }
            .appstore-img {
                display: none;
            }
            .appstore-img-mob {
                margin-right: 2px;
            }
        }
    }
`;

interface Props {
    isContact?: boolean;
}

const HomeNavbar: FunctionComponent<Props> = () => {
    const navigate = useNavigate();

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = 'https://games.weplay.africa/files/weplay.apk';
        link.download = 'weplay.apk';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <NavWrapper id="navbar">
            <Row className="nav-container">
                <Col>
                    <Image
                        onClick={() => {
                            navigate('/');
                            //scroll the page to top
                            window.scrollTo(0, 0);
                        }}
                        src={Logo}
                        preview={false}
                        className="logo-img"
                    />
                </Col>
                <div className="icons-wrapper">
                    <div
                        className="icon-wrapper"
                        onClick={() => {
                            window.open('https://www.weplay.game');
                        }}
                    >
                        <FaGlobe className="icon" />
                        <div className="web-text-wrapper">
                            <span>PLAY ON</span>
                            <span className="web-text">WEBSITE</span>
                        </div>
                    </div>
                    <Image src={PlayStore} onClick={handleDownload} preview={false} className="playstore-img" />
                    <Image src={PlayStoreMob} onClick={handleDownload} preview={false} className="playstore-img-mob" />
                    <Image
                        src={AppStore}
                        onClick={() => {
                            window.open('https://apps.apple.com/us/app/weplay-arcade/id1642292314');
                        }}
                        preview={false}
                        className="appstore-img"
                    />
                    <Image
                        src={AppStoreMob}
                        onClick={() => {
                            window.open('https://apps.apple.com/us/app/weplay-arcade/id1642292314');
                        }}
                        preview={false}
                        className="appstore-img-mob"
                    />
                </div>
            </Row>
        </NavWrapper>
    );
};

export default HomeNavbar;
