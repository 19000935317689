import { css } from 'styled-components';

const style = () => css`
    display: flex;
    width: 100%;
    min-height: 92vh;
    /* height: 90vh; */
    /* background-color: aliceblue; */
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    .main-image {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 auto !important;
    }

    .para {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(1.4rem, 2.5vw, 2.5rem);
        color: #0fe880 !important;
        /* color: #fff; */
        margin-bottom: unset;
        text-align: center;

        span {
            font-size: clamp(2.1rem, 3.5vw, 4rem);
            color: #111;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8);
        }
    }
    .red {
        color: #f83608 !important;
    }

    h2 {
        margin: unset;
        padding: unset;
        line-height: unset;
        font-style: normal;
        font-weight: 500;
        font-size: clamp(1rem, 2.3vw, 2rem);
        text-align: center;
    }
    h4 {
        font-size: clamp(2rem, 5.5vw, 7rem);
        text-transform: uppercase;
        margin: unset;
        font-weight: bold;
        text-align: center;
    }
    .weplay-small {
        width: 300px;
    }
    .grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .grid-content {
            width: 95%;
            display: grid;
            column-gap: 20px;
            row-gap: 20px;
            margin-bottom: 40px;
            .one {
                grid-area: one;
            }
            .two {
                grid-area: two;
                width: 90%;
            }
            .three {
                grid-area: three;
                width: 60%;
                margin-left: 100px;
            }
            .four {
                grid-area: four;
                width: 50%;
                margin: 0 auto 0 -20px;
            }
            .five {
                grid-area: five;
                width: 80%;
                margin: 0 auto;
            }
            .six {
                grid-area: six;
                height: 80%;
                width: 80%;
            }
            .seven {
                grid-area: seven;
                width: 50%;
                margin: auto 0;
            }
            .eight {
                grid-area: eight;
                width: 90%;
            }
            .nine {
                grid-area: nine;
                width: 90%;
                margin: 0 auto;
            }
            .ten {
                grid-area: ten;
                width: 100%;
                margin: 0 auto;
            }
            .eleven {
                grid-area: eleven;
                width: 100%;
                height: 100%;
            }
            grid-template-areas:
                '. three three three five five six . nine ten'
                'one three three three five five six seven nine ten'
                'one two . . five five six eight eight eleven'
                '. two . four five five . eight eight eleven';
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 0.3fr 1fr 0.8fr 0.2fr;
            @media screen and (max-width: 968px) {
                grid-template-areas:
                    '. three three three five five six .'
                    'one three three three five five six seven'
                    'one two . . five five six eight'
                    '. two . four five five . eight';
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: 0.3fr 1fr 0.8fr 0.2fr;
                .ten {
                    display: none;
                }
                .eleven {
                    display: none;
                }
                .nine {
                    display: none;
                }
                .eight {
                    width: 100%;
                }
            }

            @media screen and (max-width: 768px) {
                grid-template-areas:
                    'three three five five six .'
                    'three three five five six seven'
                    '. . five five six eight'
                    '. four five five . eight';
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: 0.3fr 1fr 0.8fr 0.2fr;
                .one {
                    display: none;
                }
                .two {
                    display: none;
                }
                .three {
                    width: 100%;
                    margin-left: 0px;
                }
            }

            @media screen and (max-width: 500px) {
                grid-template-areas:
                    'three three five five'
                    'three three five five'
                    '. . five five'
                    '. four five five';
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 0.3fr 1fr 0.8fr 0.2fr;
                .six {
                    display: none;
                }
                .seven {
                    display: none;
                }
                .eight {
                    display: none;
                }
            }
        }
    }
`;

export default style();
