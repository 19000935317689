import { css } from 'styled-components';

const styles = () => css`
    .password-container {
        background-color: #fff;
        /* padding: 10px; */
        border-radius: 5px;
        padding: 15px 20px;
        min-height: 300px;
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
        display: none;
        content: '';
    }
    .submit-button {
        height: 40px !important;
        padding: 0 15px !important;
        /* margin-left: 16px; */
        border-radius: 5px;
        background-color: #2980b9;
        border: 1px solid #2980b9;
    }
`;

export default styles();
