import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Leagues from '../../components/leagues';
import { Columns } from './data';
import { ApiClient } from '../../api';
import { ROUTES } from '../../router';
import LeagueRoomDetailContainer, { LeagueDetailRef } from './LeagueRoomDetailContainer';

const LeaguesContainer = () => {
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const detailRef = useRef<LeagueDetailRef>(null);

    const { data, isFetching } = useQuery('leagues', ApiClient.getLeagues, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    async function onDelete() {
        console.log('delete call');
    }

    const onEdit = (id: string) => navigate(ROUTES.EDIT_LEAGUES.split(':').shift() + `${id}`);

    const onDetailClick = (record: any) => detailRef.current?.openModal(record);

    return (
        <>
            <Leagues
                search={search}
                setSearch={setSearch}
                data={data}
                loading={isFetching}
                columns={Columns(onDelete, onEdit, onDetailClick)}
            />
            <LeagueRoomDetailContainer ref={detailRef} />
        </>
    );
};

export default LeaguesContainer;
