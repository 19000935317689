import React, { FC, useMemo, useState } from 'react';
import { AutoComplete, AutoCompleteProps, Skeleton } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import { useDebounce } from '../../utils/hooks';
import { ApiClient } from '../../api';
import GameDetails from './game-details/GameDetails';

type IProps = AutoCompleteProps & {
    fetchDetails?: boolean;
};

const SelectGame: FC<IProps> = (props) => {
    const queryClient = useQueryClient();

    const [filter, setFilter] = React.useState<string | undefined>(undefined);
    const [open, setOpen] = useState<boolean>();

    const debouncedFilter = useDebounce(filter, 500);

    const { data } = useQuery<any[]>(['select-game', debouncedFilter], ApiClient.searchGames, {
        refetchOnWindowFocus: false,
    });

    useQuery(['game-detail', props.value], ApiClient.getGameDetails, {
        enabled: !!props.fetchDetails && Boolean(props.value),
        refetchOnWindowFocus: false,
        onSuccess: async (game) => {
            await queryClient.cancelQueries(['select-game', debouncedFilter]);
            setFilter(game.title);
            if (game) {
                setOpen(false);
            }
        },
    });

    const item = useMemo(() => {
        return data?.find((val) => val.id == props.value);
    }, [data, props.value]);

    if (props.value && open == false) {
        return (
            <Skeleton loading={!item}>
                <GameDetails
                    disabled={props.disabled}
                    item={item}
                    onChange={() => {
                        setOpen(true);
                    }}
                />
            </Skeleton>
        );
    }

    return (
        <AutoComplete
            options={data}
            allowClear
            showSearch
            open={open}
            onSearch={(value) => setFilter(value)}
            fieldNames={{
                label: 'title',
                value: 'id',
            }}
            {...props}
            onDropdownVisibleChange={setOpen}
        />
    );
};

export default SelectGame;
