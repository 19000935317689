import { RoutesType } from './routes';

export const ROUTES: { [key in RoutesType]: string } = {
    HOME: '/home',
    CONTACT: '/contact',

    SIGN_IN: '/sign-in',

    DASHBOARD: '/dashboard',

    PAYMENTS: '/payments',

    CATEGORY: '/category',

    DEVELOPER: '/developers',

    GAMES: '/games',
    ADD_GAMES: '/games/create',
    VIEW_GAMES: `/games/view/:gameId`,
    EDIT_GAMES: '/games/edit/:gameId',

    LEAGUES: '/leagues',
    ADD_LEAGUES: '/leagues/create',
    EDIT_LEAGUES: '/leagues/edit/:leagueId',

    KNOCKOUT: '/knockout',
    ADD_KNOCKOUT: '/knockout/create',
    EDIT_KNOCKOUT: '/knockout/edit/:knockoutId',

    ROOMS: '/rooms',

    USERS: '/users',
    USERS_DETAILS: '/users/:userId',
    TRACKER: '/trackers',

    SETTINGS: '/settings',

    PRIVACY_POLICY: '/privacy-policy',
    TERMS_CONDITION: '/terms-and-condition',
    COOKIE_POLICY: '/cookie-policy',
    FAIR_PLAY_POLICY: '/fair-play-policy',
    FAQS: '/faqs',
    REQUEST_ACCOUNT_DELETION: '/request-account-deletion',
};
