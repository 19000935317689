import { css } from 'styled-components';

const style = () => css`
    border-top: 1px solid #bdc3c7;
    padding-bottom: 30px;
    padding-top: 20px;

    .progress-container {
        width: 90%;
        /* display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: flex-start;
        justify-items: flex-start;
        place-items: center; */
        /* place-content: center; */
        /* height: 40px; */
        /* display: flex;
        justify-content: space-between;
        align-items: center; */
        /* justify-content: center; */

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .ant-rate {
            font-size: 14px;
        }
    }
    .ant-statistic-content-value-int,
    .ant-statistic-content-value-decimal {
        font-weight: 600;
    }
`;

export default style();
