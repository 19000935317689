import { css } from 'styled-components';

const style = () => css`
    .reviews-container {
        margin-top: 20px;
        h1 {
            font-size: 20px;
        }
        .ant-comment-content-author-name {
            font-size: 14px !important;
            font-weight: 600;
            text-transform: capitalize;
        }
        .custom-avatar {
            width: 32px;
            height: 32px;
            background-color: #2e86de;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            border-radius: 10rem;
            text-transform: uppercase;
            color: #fff;
            font-size: 18px;
        }
        .ant-comment-content {
            font-size: 15px;
        }
        .ant-rate {
            font-size: 11px;
        }
        .ant-comment-inner {
            padding: 0 !important;
            margin-top: 9px;
        }
        .ant-comment-actions {
            margin-top: 3px;
            .reply-to {
                margin-left: 15px;
            }
        }
        .ant-list-split .ant-list-header {
            border: none !important;
        }
        .approved-btn {
            height: 28px !important;
            display: block;
            margin: 4px 0 4px auto;
            color: #fff;
            border-radius: 5px;
            padding: 5px;
            font-size: 10px;
            background: rgb(87, 187, 138);
            color: white;
            border: 1px solid rgb(87, 187, 138);
            margin-left: 10px;

            &:hover {
                opacity: 0.8;
            }
        }
        .hide-review {
            height: 28px !important;
            display: block;
            margin: 4px 0 4px auto;
            color: #fff;
            border-radius: 5px;
            padding: 5px;
            font-size: 10px;
            background: #e74c3c;
            color: white;
            border: none;
            margin-left: 10px;

            &:hover {
                opacity: 0.8;
            }
        }
        .reply-to {
            height: 28px !important;
            display: block;
            margin: 4px 0 4px auto;
            color: #fff;
            border-radius: 5px;
            padding: 5px;
            font-size: 10px;
            background: rgb(87, 187, 138);
            color: white;
            border: none;
            margin-left: 10px;

            &:hover {
                opacity: 0.8;
            }
        }

        .reply-content {
            background-color: #f5f6fa;
            padding: 10px;
            min-height: 50px;
            width: 70%;
            border-radius: 3px;
            h4 {
                margin-bottom: 0 !important;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
`;

export default style();
