import 'antd/dist/antd.min.css';
import { createGlobalStyle } from 'styled-components';
import { COLORS } from '../../config/theme';

const GlobalStyle = createGlobalStyle`
    html{
        scroll-behavior: smooth;
    }
    body{
        margin:0;
        padding:0;
        font-family: 'Sora', sans-serif;
        scroll-behavior: smooth;
    }
    .ant-form-item {
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        .ant-form-item-label {
            font-weight: 500;
            font-size: 13px;
        }
    }
    ::selection{
      background: ${(props: any) => props.theme[COLORS.PRIMARY]};
    }
    input:-webkit-autofill {
        background-color: ${(props: any) => props.theme[COLORS.FORM_COLOR]} !important;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]} !important;
        -webkit-box-shadow: 0 0 0 1000px ${(props: any) => props.theme[COLORS.FORM_COLOR]} inset;
        :focus,
        .ant-input-affix-wrapper > input.ant-input:focus {
            -webkit-box-shadow: 0 0 0 1000px white inset !important;
        }
    }

    .weplay-input {
        background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        box-sizing: border-box;
        border-radius: 5px;
        height: 45px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};

        input[type='password'] {
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};

            :focus {
                background: white;
            }
        }

        .ant-input-password-icon {
            color: ${(props: any) => props.theme[COLORS.ICONS]};
        }
    }

    .weplay-input:focus {
        background: ${(props: any) => props.theme[COLORS.WHITE]};
        box-shadow: none;
    }

    .ant-input-number.weplay-input {
          input {
                height: 45px;
          }
    }
    

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        background: ${(props: any) => props.theme[COLORS.WHITE]};
        box-shadow: none;
    }
    .primary-button {
        background: ${(props: any) => props.theme[COLORS.PRIMARY]};
        border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px 10px;
        height: 45px;
        color: ${(props: any) => props.theme[COLORS.WHITE]};
        text-shadow: none;
        font-size: 13px;
        min-width: 70px;
        &:hover {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            color: ${(props: any) => props.theme[COLORS.WHITE]};
        }
        &:focus {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            color: ${(props: any) => props.theme[COLORS.WHITE]};
        }
    }
    
    .ant-card, .ant-alert, .ant-popover-inner, .ant-modal-content{
      border-radius: 8px;
    }
    
    .ant-modal-content{
      overflow: hidden;
    }
    
    .ant-popover-buttons, .ant-picker-ok{
      button{
        border-radius: 4px;
      }
    }
    
    .ant-notification-notice{
        border-radius:8px;
        .ant-notification-notice-content{
            .ant-notification-notice-with-icon{
               span{
                   margin-top:10px;
               }
               .ant-notification-notice-message{
                   font-size:14px;
                   font-weight:bold;
               }
               .ant-notification-notice-description{
                    font-size:12px;
                    font-weight:normal;
               }
            }
        }
    }
`;

export default GlobalStyle;
