import omit from 'lodash/omit';
import { API } from '../api';

export function createComment(data: any) {
    return API.post(API.PATH.createComment, data).then((res) => res.data);
}

export function editComment(data: any) {
    return API.patch(API.PATH.editComment(data.id), omit(data, ['id'])).then((res) => res.data);
}

export function deleteComment(commentId: string) {
    return API.delete(API.PATH.deleteComment(commentId)).then((res) => res.data);
}
