import { QueryFunctionContext } from 'react-query';
import { API } from '../api';
import omit from 'lodash/omit';

interface DeveloperInputData {
    fullName: string;
    avatar: AvatarEntity[];
}

interface AvatarEntity {
    uid: string;
    name: string;
    size: number;
    type: string;
    status: string;
    response: {
        url: string;
    };
    url: string;
}

export function addDeveloper(data: DeveloperInputData) {
    const avatar = data.avatar?.[0];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data.avatar = API.getImageUrl(avatar?.url || avatar?.response?.url);
    return API.post(API.PATH.addDeveloper, data).then((res) => res.data);
}

export function getDevelopers({ signal }: QueryFunctionContext) {
    return API.get(API.PATH.getDevelopers, { signal }).then((res) => res.data);
}

export function updateDeveloper(data: DeveloperInputData & { id: string }) {
    const avatar = data.avatar?.[0];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data.avatar = API.getImageUrl(avatar?.url || avatar?.response?.url);
    return API.patch(API.PATH.editDeveloper(data.id), omit(data, ['id'])).then((res) => res.data);
}
