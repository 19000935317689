import { css } from 'styled-components';
import { selectStyles } from '../../../common-styles';

const style = () => css`
    .container {
        box-shadow: 0 2px 6px rgb(0 0 0 / 7%);
        background: #ffffff;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 5px;
        padding: 40px 25px;

        .create-button {
            height: 40px !important;
            padding: 0 15px !important;
            margin-left: 16px;
            border-radius: 5px;
            background-color: #2980b9;
            border: 1px solid #2980b9;
        }

        ${selectStyles}
    }

    .ant-form-vertical .ant-form-item-label > label {
        height: auto;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 600;
    }

    .ant-modal-header {
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;

        .ant-modal-title {
            font-size: 15px;
            font-weight: 600;
        }
    }

    .ant-modal-body {
        padding: 12px 24px;
    }
`;

export default style();
