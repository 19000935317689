import { css } from 'styled-components';

const style = () => css`
    /* width: 90%; */
    margin: 0 auto;
    .container {
        /* box-shadow: 0px 2px 6px rgb(0 0 0 / 7%); */
        background: #ffffff;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 5px;
        padding: 15px 20px;

        .delete-icon {
            width: 15px;
            height: 15px;
            cursor: pointer;
        }

        .edit-button {
            height: 28px !important;
            padding: 0 12px !important;
            display: block;
            margin: 4px 0;
            color: #fff;
            border-radius: 5px;
            font-size: 12px;
            background: #111;
            border: 1px solid #111;

            &:hover {
                opacity: 0.8;
            }
        }

        .game-info {
            width: 100%;
            /* background-color: red; */
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;

            .ant-typography h3,
            h3.ant-typography {
                font-style: normal;
                font-weight: 700;
                font-size: 23px;
                color: rgba(0, 0, 0, 0.8);
            }
            .ant-typography h5,
            h5.ant-typography {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                margin-top: 10px;
                color: rgba(0, 0, 0, 0.7);
            }
        }
        .developer-name {
            font-weight: 500;
            font-size: 14px;
            margin-left: 10px;
            text-transform: capitalize;
        }
        .description {
            font-size: 15px;
            margin-top: 20px;
            padding-right: 30px;
        }

        .rating_container {
            margin-top: 20px;

            h1 {
                font-size: 30px;
            }
        }

        .carousal {
            width: 90%;
            img {
                height: 350px;
                width: 90%;
                object-fit: contain;
            }
        }

        .ant-carousel .slick-next,
        .ant-carousel .slick-prev {
            color: #111 !important;
            height: 25px !important;
            width: 25px !important;
        }
    }
`;

export default style();
