import React, { useEffect } from 'react';
import { ApiClient } from '../../api';
import DashBoard from '../../components/dashboard';
import { useQuery } from 'react-query';
import moment, { Moment } from 'moment';

const DashBoardContainer = () => {
    const [gameStats, setGameStats] = React.useState<{ [key: string]: any }>({});
    const [dateRange, setDateRange] = React.useState<[Moment, Moment]>([moment().subtract(1, 'month'), moment()]);
    const { data: withdrawalsData } = useQuery('withdrawals', ApiClient.getWithdrawalStats);
    const { data: depositsData } = useQuery('deposits', ApiClient.getDepositStats);
    const { data: insightsData } = useQuery(
        ['insights', dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')],
        ApiClient.getInsights,
    );
    const { data: userSignUpsData } = useQuery(
        ['userSignUps', dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')],
        ApiClient.getDailySignups,
    );
    const { data: gamePlaysData } = useQuery(
        ['dailyActiveUsers', dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')],
        ApiClient.getDailyGamePlays,
    );

    console.log('userSignUpsDara', userSignUpsData, gamePlaysData);

    const gameIds = [
        { id: '207c2dce-f981-4256-9374-aec13c4af16e', name: '8 Ball Pool' },
        { id: '066a8a31-dbb7-4f7b-ace6-d9af81bdaa74', name: 'Chess' },
        { id: '71dc9e62-0ebf-47a2-9e12-f7e4a7f5b93d', name: 'Draft (Checkers)' },
        { id: 'ca9f3e08-cf35-4ff9-acfb-43ac6aa1b598', name: 'Cycle Sprint' },
        { id: '418a6746-c94a-47b1-94b1-17621ad2e7db', name: 'Temple Run 2' },
        { id: '3695a1da-d373-4131-bedd-d97f349dcff8', name: 'Rogue One: Boots on The Ground' },
        { id: '2084f6b6-e469-4997-b646-96becc7f5fcf', name: 'Tennis' },
        { id: 'fd3920ed-9cf2-42fc-8dfb-818a20594936', name: 'Racing' },
        { id: '7626b9d5-ff48-42b0-919b-7465f174126d', name: 'Head Soccer' },
        { id: '5b1e87a1-c0a1-436c-87bb-313ca7924fa7', name: 'Candy Match Saga' },
        { id: '31ff4ac3-70d8-4969-9345-b2a3edf463fd', name: 'Domino Block' },
        { id: '0f8770f0-b642-4af6-85a0-7c9be16cb4bb', name: 'Cookie Crush' },
        { id: 'a61ed44c-9deb-4f1b-b5bb-d1377fbf0933', name: 'Foosball' },
    ];

    useEffect(() => {
        gameIds.forEach((game) => {
            ApiClient.getGameStats(game.id).then((stats) => {
                setGameStats((prevStats) => ({
                    ...prevStats,
                    [game.id]: { name: game.name, minutesPlayed: stats, id: game.id },
                }));
            });
        });
    }, []);

    return (
        <DashBoard
            gameStats={gameStats}
            depositsData={depositsData}
            withdrawalsData={withdrawalsData}
            dateRange={dateRange}
            setDateRange={setDateRange}
            insightsData={insightsData}
        />
    );
};

export default DashBoardContainer;
