import moment from 'moment';

const StatusCodes = {
    '0': 'Success',
    '1': 'Insufficient Funds',
    '2': 'Less Than Minimum Transaction Value',
    '3': 'More Than Maximum Transaction Value',
    '4': 'Would Exceed Daily Transfer Limit',
    '5': 'Would Exceed Minimum Balance',
    '6': 'Unresolved Primary Party',
    '7': 'Unresolved Receiver Party',
    '8': 'Would Exceed Maxiumum Balance',
    '11': 'Debit Account Invalid',
    '12': 'Credit Account Invalid',
    '13': 'Unresolved Debit Account',
    '14': 'Unresolved Credit Account',
    '15': 'Duplicate Detected',
    '17': 'Internal Failure',
    '20': 'Unresolved Initiator',
    '1032': 'Request cancelled by user',
};

const Columns = [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: 'Name',
        dataIndex: ['user', 'fullName'],
    },
    {
        title: 'Email',
        dataIndex: ['user', 'email'],
    },
    {
        title: 'Phone Number',
        dataIndex: ['user', 'phoneNumber'],
        render: (value, record) => `${record?.user?.countryCode || ''}-${value || ''}`,
    },
    {
        title: 'Transaction Date',
        dataIndex: 'transactionDate',
        render: (value) => moment(value, 'YYYYMMDDHHmmss').format('DD/MM/YYYY HH:mm:ss'),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: (value) => `KES ${value}`,
    },
    {
        title: 'Receipt Number',
        dataIndex: 'mpesaReceiptNumber',
    },
    {
        title: 'Status',
        dataIndex: 'resultCode',
        render: (value) => StatusCodes[value],
    },
];

export const Columns2 = [
    {
        title: 'Name',
        dataIndex: ['user', 'fullName'],
    },
    {
        title: 'Email',
        dataIndex: ['user', 'email'],
    },
    {
        title: 'Phone Number',
        dataIndex: ['user', 'phoneNumber'],
        render: (value, record) => `${record?.user?.countryCode || ''}-${value || ''}`,
    },
    {
        title: 'Transaction Date',
        dataIndex: 'createdAt',
        render: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
        title: 'Amount',
        dataIndex: ['initiationDetails', 'amount'],
        render: (value) => `KES ${value ?? '-'}`,
    },
    {
        title: 'Receipt Number',
        dataIndex: 'TransactionID',
    },
    {
        title: 'Status',
        dataIndex: 'ResultCode',
        render: (value) => StatusCodes[value],
    },
];

export { Columns };
