const prefix = 'category';

export const createCategory = `${prefix}`;

export const getCategories = `${prefix}`;

export const getSingleCategory = (categoryId: string | number) => `${prefix}/${categoryId}`;

export const updateCategory = (categoryId: string | number) => `${prefix}/${categoryId}`;

export const deleteCategory = (categoryId: string | number) => `${prefix}/${categoryId}`;
