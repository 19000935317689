import { css } from 'styled-components';

const style = () => css`
    user-select: none;
    background-color: #ffffff;
    .ant-layout-content,
    .ant-layout-footer {
        .container {
            max-width: 1600px;
            padding: 0 8px;
            margin: auto;
        }
    }

    > .ant-layout-content {
        .primary-button {
            padding: 0 32px;
            border-radius: 16px;
            background: #0fe880 !important;
            color: #000000 !important;
            border: none !important;

            font-weight: 400;
            font-size: 16px;
            line-height: 140%;

            margin-bottom: 16px;
        }
    }
    .textWrapper {
        flex: 1;
        @media (max-width: 768px) {
            flex: inherit;
        }
    }

    .ant-layout-footer {
        padding: 24px 8px 14px;
    }
    /* .scroll-snap {
        scroll-snap-type: y mandatory;
    } */
`;

export default style();
