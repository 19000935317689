import React, { FC } from 'react';
import { Button, Form, FormProps, Input } from 'antd';

interface IProps extends FormProps {
    loading?: boolean;
}

const CategoryForm: FC<IProps> = ({ children, loading, ...props }) => {
    return (
        <Form layout={'vertical'} {...props}>
            <Form.Item
                name={'name'}
                label={'Category Title'}
                rules={[{ required: true, message: 'Please enter the title of category!' }]}
            >
                <Input className={'weplay-input'} placeholder={'Enter category title'} />
            </Form.Item>
            {children}
            <Form.Item style={{ textAlign: 'right', margin: 0 }}>
                <Button className={'primary-button'} htmlType={'submit'} loading={loading}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CategoryForm;
