import { Col, Rate, Row, Statistic } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import ProgressBar from './progress-bar';
import styles from './styles/total-rating';

// const {Title} = Typography;

const RatingWrapper = styled.div`
    ${styles}
`;

interface Props {
    data: any;
}

const TotalRating: FC<Props> = ({ data }) => {
    const stats = React.useMemo(() => {
        const _review = data?.stats?.reduce((prevValue, value) => {
            if (!prevValue[value.rating]) {
                prevValue[value.rating] = parseFloat(value.percentage);
            }
            return prevValue;
        }, {} as { [key: string]: number });

        return _review || {};
    }, [data?.stats]);

    return (
        <RatingWrapper>
            <Row gutter={10}>
                <Col span={3}>
                    <Statistic title="Total Ratings" value={data?.rating?.count} />
                </Col>
                <Col span={8} style={{ marginRight: '5px' }}>
                    <div className="progress-container">
                        <div className="rate">5</div>
                        <ProgressBar color={'#9ace6a'} percentage={stats['5']} />
                    </div>

                    <div className="progress-container">
                        <div className="rate">4</div>
                        <ProgressBar color={'#9ace6a'} percentage={stats['4']} />
                    </div>
                    <div className="progress-container">
                        <div className="rate">3</div>
                        <ProgressBar color={'#ffcf02'} percentage={stats['3']} />
                    </div>
                    <div className="progress-container">
                        <div className="rate">2</div>
                        <ProgressBar color={'#ff9f02'} percentage={stats['2']} />
                    </div>
                    <div className="progress-container">
                        <div className="rate">1</div>
                        <ProgressBar color={'#ff6f31'} percentage={stats['1']} />
                    </div>
                </Col>
                <Col span={4}>
                    {' '}
                    <Statistic title="Average Rating" value={data?.rating?.average} />
                    <Rate value={data?.rating?.average} disabled />
                </Col>
            </Row>
        </RatingWrapper>
    );
};

export default TotalRating;
