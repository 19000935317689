import { QueryFunctionContext } from 'react-query';
import { API } from '../api';

export function getCategories({ signal }: QueryFunctionContext) {
    return API.get(API.PATH.getCategories, { signal }).then((res) => res.data);
}

export function createCategory(data: any) {
    return API.post(API.PATH.createCategory, data).then((res) => res.data);
}

export function updateCategory(data: any) {
    return API.patch(API.PATH.updateCategory(data.id), { name: data.name }).then((res) => res.data);
}

export function deleteCategory(categoryId: string) {
    return API.delete(API.PATH.deleteCategory(categoryId)).then((res) => res.data);
}
