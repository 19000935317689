import { css } from 'styled-components';

const paginationStyles = () => css`
    .ant-pagination {
        .ant-pagination-total-text {
            margin-right: auto;
        }

        .ant-pagination-item,
        .ant-pagination-jump-next {
            display: none;
        }

        .ant-pagination-prev,
        .ant-pagination-next {
            height: 30px;
            width: 42px;
            line-height: 28px;
        }

        .ant-pagination-prev {
            margin-right: unset;

            button {
                border-radius: 5px 0 0 5px;
            }
        }

        .ant-pagination-next {
            button {
                border-radius: 0 5px 5px 0;
            }
        }
    }
`;

export default paginationStyles();
