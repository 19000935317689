import { useMemo } from 'react';
import { API } from '../../api';

export default function useImageSource(url?: string) {
    return useMemo(() => {
        if (url?.startsWith('/files/')) {
            return API.baseUrl.concat(url);
        }
        return url;
    }, [url]);
}
