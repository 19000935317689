export const UnAuthenticatedRoutes = [
    'CONTACT',
    'SIGN_IN',
    'PRIVACY_POLICY',
    'TERMS_CONDITION',
    'COOKIE_POLICY',
    'FAIR_PLAY_POLICY',
    'FAQS',
    'REQUEST_ACCOUNT_DELETION',
] as const;

export const AuthenticatedRoutes = [
    'HOME',

    'DASHBOARD',

    'PAYMENTS',

    'CATEGORY',

    'DEVELOPER',

    'GAMES',
    'ADD_GAMES',
    'VIEW_GAMES',
    'EDIT_GAMES',

    'LEAGUES',
    'ADD_LEAGUES',
    'EDIT_LEAGUES',

    'KNOCKOUT',
    'ADD_KNOCKOUT',
    'EDIT_KNOCKOUT',

    'ROOMS',

    'USERS',
    'USERS_DETAILS',
    'TRACKER',

    'SETTINGS',
] as const;

export type AuthenticatedRoutesType = typeof AuthenticatedRoutes[number];
export type UnAuthenticatedRoutesType = typeof UnAuthenticatedRoutes[number];

export type RoutesType = AuthenticatedRoutesType | UnAuthenticatedRoutesType;
