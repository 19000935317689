import React, { useState } from 'react';
import { Button, Input, notification } from 'antd';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import { isValidPhoneNumber, parsePhoneNumber, CountryCode } from 'libphonenumber-js';
import styles from './styles';
import HomeNavbar from '../faqs/HomeNavbar';
import HomeFooter from '../../components/homapage/component/HomeFooter';
import './phone.css';
import deleteImage from '../../assets/images/delete.png';
import axios from 'axios';
import { API } from '../../api';

const DeleteAccountWrapper = styled.div`
    ${styles}
`;

const DeleteAccountContainer = () => {
    const [step, setStep] = useState<string>('enterPhone');
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
    const [countryCode, setCountryCode] = useState<CountryCode | undefined>('KE');
    const [otp, setOtp] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const sendOtp = () => {
        if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
            notification.error({
                message: 'Error',
                description: 'Please enter a valid phone number',
            });
            return;
        }
        setLoading(true);
        axios
            .get(API.baseUrl + '/auth/otp/' + phoneNumber)
            .then(() => {
                setStep('enterOtp');
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: 'OTP sent to your phone and registered email',
                });
            })
            .catch(() => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong. Please try again later',
                });
            });
    };

    const deleteAccount = () => {
        if (!otp || otp.length < 6) {
            notification.error({
                message: 'Error',
                description: 'Please enter the OTP sent to your phone',
            });
            return;
        }
        if (!phoneNumber) {
            notification.error({
                message: 'Error',
                description: 'Please enter a valid phone number',
            });
            return;
        }
        const { countryCallingCode, nationalNumber } = parsePhoneNumber(phoneNumber, { defaultCountry: countryCode });
        setLoading(true);
        axios
            .post(API.baseUrl + '/auth/delete-account', {
                phoneNumber: nationalNumber,
                countryCode: `+${countryCallingCode}`,
                otp,
            })
            .then(() => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: 'Account successfully deleted',
                });
            })
            .catch(() => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong. Please try again later',
                });
            });
    };

    return (
        <>
            <HomeNavbar />
            <DeleteAccountWrapper>
                <div className="delete-account-container">
                    <div className="delete-account-card">
                        <h1>Delete Weplay Account</h1>
                        <img src={deleteImage} alt="delete" />
                        {step === 'enterPhone' ? (
                            <div className="phone-input">
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={phoneNumber}
                                    defaultCountry="KE"
                                    onCountryChange={(value) => setCountryCode(value)}
                                    onChange={(value) => setPhoneNumber(value)}
                                    style={{ width: '100%', innerHeight: '50px' }}
                                />
                            </div>
                        ) : (
                            <div className="phone-input">
                                <Input
                                    className="otp-input"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                            </div>
                        )}
                        <Button
                            className="primary-button"
                            loading={loading}
                            onClick={() => {
                                if (step === 'enterPhone') {
                                    sendOtp();
                                } else {
                                    deleteAccount();
                                }
                            }}
                        >
                            {step === 'enterPhone' ? 'Send OTP' : 'Delete Account'}
                        </Button>
                    </div>
                </div>
                <HomeFooter />
            </DeleteAccountWrapper>
        </>
    );
};

export default DeleteAccountContainer;
