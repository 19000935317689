import React, { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { MarkdownFileName, useMarkdownFile } from '../utils/hooks';
import remarkGfm from 'remark-gfm';

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 16px 16px 16px 0;
    min-height: 99vh;

    table,
    tr,
    td,
    th {
        border: 1px solid #555;
    }
    td,
    th {
        padding: 10px;
    }

    h2 {
        text-align: center;
    }

    @media (max-width: 500px) {
        padding: 16px 8px 16px 0;
    }
`;

interface MarkdownLayoutProps {
    title: string;
    mdFileName: MarkdownFileName;
}

const MarkdownLayout: FC<MarkdownLayoutProps> = ({ title, mdFileName }) => {
    const file = useMarkdownFile(mdFileName);
    return (
        <Wrapper>
            <Typography.Title level={2}>{title}</Typography.Title>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{file}</ReactMarkdown>
        </Wrapper>
    );
};

export default MarkdownLayout;
