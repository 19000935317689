import { css } from 'styled-components';
import { COLORS } from '../../config/theme';

const style = () => css`
    .ant-input-affix-wrapper {
        height: 40px;
        border-radius: 12px;
        width: 100%;
        max-width: 350px;

        .ant-input-prefix {
            margin-right: 6px;
        }
    }
    .ant-tabs-tab {
        border-radius: 5px !important;
    }
    .ant-table {
        box-shadow: ${(props) => props.theme[COLORS.CARD_SHADOW]};
        border-radius: 15px;
        overflow: hidden;
        margin-top: 10px;
        .ant-table-thead > tr > th {
            font-weight: 600;
        }
    }
`;

export default style();
