// import { QueryFunctionContext } from 'react-query';
import { ApiClient } from '..';
import { API } from '../api';

export function getFeatureFlags() {
    return API.get(API.PATH.get_feature_flags).then((response) => response.data);
}

export function enableFeatureFlag(id: string) {
    return API.patch(API.PATH.enable_feature_flag(id)).then((response) => response.data);
}

export function disableFeatureFlag(id: string) {
    return API.patch(API.PATH.disable_feature_flag(id)).then((response) => response.data);
}

export function updateFeatureFlag(data: any) {
    const { id } = data;
    if (!id) {
        ApiClient.showError('Feature flag id is required');
    }
    delete data.id;
    return API.put(API.PATH.update_feature_flag(id), data).then((response) => response.data);
}
