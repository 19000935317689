import React, { FC } from 'react';
import styled from 'styled-components';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

interface Props {
    nextSlide?: () => void | any;
    prevSlide?: () => void | any;
}

const BtnSlideWrapper = styled.div`
    position: absolute;
    bottom: 5%;
    left: 10%;
    display: flex;
    gap: 0.5rem;
    z-index: 4;
    justify-content: space-between;
    .left-icon,
    .right-icon {
        font-size: 45px;
        color: #fff;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
            transition: all 0.3s ease-in-out;
            color: #bbb8b8;
        }
    }
`;

const BtnSlider: FC<Props> = ({ nextSlide, prevSlide }) => {
    return (
        <BtnSlideWrapper>
            <div>
                <LeftCircleOutlined className="left-icon" onClick={prevSlide} />
            </div>
            <div>
                <RightCircleOutlined className="right-icon" onClick={nextSlide} />
            </div>
        </BtnSlideWrapper>
    );
};

export default BtnSlider;
