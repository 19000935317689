import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import style from './style';
import { imgData } from './data';
import { playClickAction } from '../../../../utils/helper';

const { Title } = Typography;

const BubbleWrapper = styled.div`
    ${style}
`;

const AvatarBubble = ({ image }: any) => {
    return (
        <div className="avatar-bubble">
            <img src={image.image} alt="avatar" />
        </div>
    );
};

const BubbleAvatar = () => {
    const randomizeAnimation = () => {
        const bubbles = document.querySelectorAll('.avatar-bubble');
        const padding = 42; // Padding on both sides
        const containerWidth = (document.querySelector('.bubbles-container') as any)?.offsetWidth - 2 * padding;
        const bubbleWidth = 150; // Assuming each bubble is 50px wide
        const spacePerBubble = containerWidth / bubbles.length;

        bubbles.forEach((bubble: any, index) => {
            const bubbleCenter = index * spacePerBubble + spacePerBubble / 2;
            const bubbleLeft = bubbleCenter - bubbleWidth / 2 + padding; // Add padding to the left

            const randomDuration = Math.random() * 5 + 5; // Between 5 and 10 seconds
            bubble.style.left = `${bubbleLeft}px`;
            bubble.style.animationDuration = `${randomDuration}s`;
            bubble.style.animationDelay = `${index}s`;

            bubble.addEventListener('animationend', () => {
                bubble.style.bottom = '-160px'; // Reset to start position
                bubble.style.left = `${Math.random() * (containerWidth - bubbleWidth) + padding}px`; // Adjust for padding when resetting position
                bubble.style.animation = 'none';
                setTimeout(() => {
                    // Reset animation for continuous loop
                    bubble.style.animation = '';
                }, 10);
            });
        });
    };

    useEffect(() => {
        randomizeAnimation();
    }, []);

    return (
        <BubbleWrapper>
            <div className="container">
                <div className="bubbles-container">
                    {imgData.map((image, index) => (
                        <AvatarBubble key={index} image={image} />
                    ))}
                </div>

                <div className="container1">
                    <Title className="title">Join the gamers</Title>
                    <Title className="caption">compete and win rewards and prizes</Title>
                    <Button className="primary-button" onClick={playClickAction}>
                        Start Playing Now
                        <ArrowRightOutlined />
                    </Button>
                </div>
            </div>
        </BubbleWrapper>
    );
};

export default BubbleAvatar;
