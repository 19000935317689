import React from 'react';
import styled from 'styled-components';
import LogoLarge from '../../../assets/images/Logo-large.png';
import TextChange from './TextChangeNew';
import MouseScrollAnimation from './MouseScrollAnimation';
import { useImageSource } from '../../../utils/hooks';

const WelcomeWrapper = styled.div`
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        z-index: 2;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%),
            linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
    }

    .main-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
    }

    video {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    .text-container {
        position: absolute;
        height: 100%;
        /* top: 2%; */
        text-align: center;
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 90;
        padding-left: 10px;
        padding-right: 10px;
        .image-div {
            width: 100%;
            gap: 1.5rem;

            img {
                width: 50%;
                height: auto;
                object-fit: contain;
            }
            @media (max-width: 820px) {
                img {
                    width: 60%;
                }
            }
            @media (max-width: 500px) {
                flex-direction: column;
                gap: 0.7rem;
                img {
                    width: 80%;
                    margin-bottom: 32px;
                }
            }
        }
        h4 {
            font-size: clamp(2rem, 5.5vw, 7rem);
            text-transform: uppercase;
            margin: unset;
            font-weight: bold;
            text-align: center;
        }
    }
`;

interface Props {
    onClick?: (event: React.MouseEvent) => void;
}

const WelcomePage: React.FC<Props> = () => {
    const videoLink = useImageSource('/files/home.mp4');
    return (
        <WelcomeWrapper>
            <div className="main-bg">
                <video preload="none" src={videoLink} autoPlay loop muted playsInline />
            </div>

            <div className="text-container">
                <div className="image-div" style={{ marginBottom: '1rem' }}>
                    <img src={LogoLarge} alt="logo" className="logo-large" />
                    <TextChange />
                </div>
            </div>
            <MouseScrollAnimation />
        </WelcomeWrapper>
    );
};

export default WelcomePage;
