import React from 'react';
import { useQuery } from 'react-query';
import { Modal, Skeleton } from 'antd';
import { ApiClient } from '../../api';
import LeagueDetails from '../../components/leagues/LeagueDetails';

export interface LeagueDetailRef {
    openModal: any;
}

const LeagueRoomDetail = React.forwardRef<LeagueDetailRef>((_, ref) => {
    const [leagueId, setLeagueId] = React.useState<string>();

    const { data, isFetching } = useQuery(['league', leagueId], ApiClient.getLeagueDetails, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(leagueId),
    });

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: (record: any) => setLeagueId(record?.id || record.toString()),
        }),
        [],
    );

    return (
        <Modal
            destroyOnClose
            title={'League Details'}
            visible={Boolean(leagueId)}
            onCancel={() => setLeagueId(undefined)}
            footer={null}
            maskClosable={false}
            width={800}
        >
            <Skeleton loading={isFetching}>
                <LeagueDetails data={data} />
            </Skeleton>
        </Modal>
    );
});

LeagueRoomDetail.displayName = 'League Details';

export default LeagueRoomDetail;
