import { css } from 'styled-components';

const style = () => css`
    background: #ffffff;

    .link-section {
        > .ant-typography {
            font-weight: 700;
            font-size: 21px;
            color: #080809;
        }

        > span.ant-typography {
            display: block;
            font-weight: 400;
            font-size: 18px;
            color: #5e6282;
        }

        @media (max-width: 576px) {
            text-align: center;
        }

        &.logo {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 48px;
                aspect-ratio: 1;
            }
        }
    }

    .footer-about {
        margin: 0 auto;
        .footer-logo {
            margin-bottom: 16px;
        }
        > .ant-typography {
            font-weight: 400;
            font-size: 1rem;
            color: #5e6282;
        }
        .playstore-img {
            cursor: pointer;
            max-height: 50px;
        }
        .appstore-img {
            cursor: pointer;
            max-height: 36px;
        }

        @media (max-width: 765px) {
            .playstore-img {
                height: 50px;
            }
            .appstore-img {
                height: 50px;
                max-height: 50px;
            }
        }
    }

    .link-section,
    .bottom-section > .ant-col {
        margin-bottom: 16px;
    }

    .bottom-section {
        justify-content: flex-end;
        gap: 15px;
        .footer-links {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            padding: 0 10px;
            gap: 15px;
        }

        > :first-child {
            margin-right: auto;

            @media (max-width: 650px) {
                width: 100%;
                text-align: center;
            }
        }

        @media (max-width: 650px) {
            justify-content: space-between;
            text-align: center;

            > :first-child {
                width: 100%;
                text-align: center;
            }
        }

        @media (max-width: 470px) {
            > div {
                width: 100%;
                justify-content: center;
            }
        }
    }

    .copyright {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        letter-spacing: 0.5px;
    }

    .social-icon-container {
        display: flex;
        justify-content: space-between;

        .social-icon {
            font-size: 24px;
            color: #0f1a2a;
        }
    }

    .term-con-con {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        > span.ant-typography,
        a > span.ant-typography {
            white-space: nowrap;
            padding: 0 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #000000;
            margin-bottom: 8px;
        }

        > span.ant-typography {
            :last-child {
                font-weight: 700;
                letter-spacing: 0.15em;
                text-transform: uppercase;

                display: flex;
                align-items: center;

                svg {
                    color: #0f1a2a;
                    margin-left: 4px;
                }
            }
        }
    }
    .icon-wrapper {
        display: flex;
        align-items: center;
        background: #000000;
        border-radius: 5px;
        padding: 2px 8px;
        gap: 8px;
        min-width: 100px;
        cursor: pointer;
        max-height: 36px;
        .web-text-wrapper {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }
        .web-text {
            font-size: 10px;
        }
        .icon {
            font-size: 20px;
            color: #fff;
        }
        span {
            font-size: 7px;
            font-weight: 500;
            color: #fff;
        }
    }
    @media (max-width: 765px) {
        .icon-wrapper {
            height: 50px;
            max-height: 50px;
            min-width: 120px;
            span {
                font-size: 8px;
                font-weight: 500;
                color: #fff;
            }
            .icon {
                font-size: 24px;
                color: #fff;
            }
            .web-text {
                font-size: 14px;
            }
        }
    }
`;

export default style();
