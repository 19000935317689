import React, { FunctionComponent, useEffect } from 'react';
import { DrawerLayout } from '../../Layout';
import { Input, Table, Tabs } from 'antd';
import { PaginationConfig, paginationStyles } from '../common';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import style from './style';

const PaymentsWrapper = styled.div`
    ${style}
    ${paginationStyles}
`;

interface IProps {
    columns: any;
    columns2: any;
    data?: MPesaTransactions[];
    loading: boolean;
    total?: number;
    fetchNextPage?: () => void;
    fetchPreviousPage?: () => void;
    isFetchingNextPage?: boolean;
    isFetchingPreviousPage?: boolean;
    setFetchSearch?: (value: string) => void;
    activeTab: string;
    setActiveTab: (value: string) => void;
}
const PaymentComponent: FunctionComponent<IProps> = ({
    columns,
    columns2,
    data,
    loading,
    total,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    activeTab,
    setActiveTab,
    setFetchSearch,
}: IProps) => {
    const [page, setPage] = React.useState<any>(1);
    const [search, setSearch] = React.useState<any>('');

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPage(1);
            setFetchSearch?.(search);
        }, 500);
        return () => clearTimeout(timeout);
    }, [search]);

    return (
        <DrawerLayout>
            <PaymentsWrapper>
                <Tabs
                    defaultActiveKey="deposits"
                    type="card"
                    tabBarStyle={{ marginBottom: 0 }}
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    tabBarExtraContent={
                        <Input
                            value={search}
                            allowClear
                            placeholder={'Search Phone/receipt'}
                            prefix={<SearchOutlined />}
                            onChange={(event) => {
                                setSearch(event.target.value);
                            }}
                        />
                    }
                >
                    <Tabs.TabPane tab="Deposits" key="deposits">
                        <Table
                            loading={loading || isFetchingNextPage || isFetchingPreviousPage}
                            rowKey={'id'}
                            columns={columns}
                            dataSource={data}
                            locale={{ emptyText: 'No Users' }}
                            pagination={{
                                ...PaginationConfig,
                                total: total,
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total}`,
                                onChange: (pageTable) => {
                                    if (pageTable > page) {
                                        fetchNextPage?.();
                                    } else if (pageTable < page) {
                                        fetchPreviousPage?.();
                                    }
                                    setPage(pageTable);
                                },
                            }}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Withdrawals" key="withdrawals">
                        <Table
                            loading={loading || isFetchingNextPage || isFetchingPreviousPage}
                            rowKey={'id'}
                            columns={columns2}
                            dataSource={data}
                            locale={{ emptyText: 'No Users' }}
                            pagination={{
                                ...PaginationConfig,
                                total: total,
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total}`,
                                onChange: (pageTable) => {
                                    if (pageTable > page) {
                                        fetchNextPage?.();
                                    } else if (pageTable < page) {
                                        fetchPreviousPage?.();
                                    }
                                    setPage(pageTable);
                                },
                            }}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </PaymentsWrapper>
        </DrawerLayout>
    );
};

export default PaymentComponent;
