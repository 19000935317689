import React from 'react';
import { RoutesType } from './routes';
import SignInContainer from '../containers/authentication/sign-in';
import {
    CategoryContainer,
    CookiePolicyContainer,
    DashBoardContainer,
    DeveloperContainer,
    EditGameContainer,
    EditKnockoutContainer,
    EditLeagueContainer,
    GamesContainer,
    HomeContainer,
    KnockoutContainer,
    LeaguesContainer,
    NewGameContainer,
    NewKnockoutContainer,
    NewLeagueContainer,
    PrivacyPolicyContainer,
    RoomsContainer,
    SettingsContainer,
    TermsConditionContainer,
    UserDetailsContainer,
    UsersContainer,
    ViewGameContainer,
    FaqsContainer,
    DeleteAccountContainer,
    TrackersContainer,
} from '../containers';

import { Error404Container } from '../containers/errors';
import ContactContainer from '../containers/contact';
import FairPlayPolicyContainer from '../containers/pages/FairPlayPolicy';
import PaymentContainer from '../containers/payments';

const COMPONENTS: { [key in RoutesType]: JSX.Element } = {
    HOME: <HomeContainer />,
    CONTACT: <ContactContainer />,

    SIGN_IN: <SignInContainer />,
    DASHBOARD: <DashBoardContainer />,
    PAYMENTS: <PaymentContainer />,

    CATEGORY: <CategoryContainer />,
    DEVELOPER: <DeveloperContainer />,

    GAMES: <GamesContainer />,
    ADD_GAMES: <NewGameContainer />,
    VIEW_GAMES: <ViewGameContainer />,
    EDIT_GAMES: <EditGameContainer />,

    LEAGUES: <LeaguesContainer />,
    ADD_LEAGUES: <NewLeagueContainer />,
    EDIT_LEAGUES: <EditLeagueContainer />,

    KNOCKOUT: <KnockoutContainer />,
    ADD_KNOCKOUT: <NewKnockoutContainer />,
    EDIT_KNOCKOUT: <EditKnockoutContainer />,

    ROOMS: <RoomsContainer />,

    USERS: <UsersContainer />,
    USERS_DETAILS: <UserDetailsContainer />,
    SETTINGS: <SettingsContainer />,

    PRIVACY_POLICY: <PrivacyPolicyContainer />,
    TERMS_CONDITION: <TermsConditionContainer />,
    COOKIE_POLICY: <CookiePolicyContainer />,
    FAIR_PLAY_POLICY: <FairPlayPolicyContainer />,
    FAQS: <FaqsContainer />,
    REQUEST_ACCOUNT_DELETION: <DeleteAccountContainer />,
    TRACKER: <TrackersContainer />,
};

const ERROR_COMPONENTS = {
    ERROR_404: <Error404Container />,
};

export { COMPONENTS, ERROR_COMPONENTS };
