import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import moment from 'moment';
import React from 'react';
import { lpad } from '../../../utils/helper';

const Columns = (onDelete: (roomId: string) => Promise<void>, onEdit, onDetailClick) => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (value) => lpad(value, 5).toUpperCase(),
        },
        {
            title: 'Room Name',
            dataIndex: 'name',
        },
        {
            title: 'Joining Fee',
            dataIndex: 'fee',
        },
        {
            title: 'Platform Fee',
            dataIndex: 'platformFee',
        },
        // {
        //     title: 'Winning Reward',
        //     dataIndex: 'coins',
        // },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            render: (value) => moment(value).format('MM/DD/YYYY'),
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            align: 'left',
            render: (id, record) => (
                <div className={'action-button'}>
                    <div>
                        <EyeOutlined onClick={() => onDetailClick?.(record)} />
                    </div>
                    <div>
                        <Popconfirm
                            title="Sure to delete?"
                            okText={'Yes'}
                            cancelText={'No'}
                            onConfirm={async () => await onDelete(id)}
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </div>
                    <div style={{ display: 'none' }}>
                        <EditOutlined onClick={() => onEdit?.(record)} />
                    </div>
                </div>
            ),
            width: '10%',
        },
    ];
};
export { Columns };
