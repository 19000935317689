import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    .ant-card {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: ${(props) => props.theme[COLORS.CARD_SHADOW]};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .ant-card-cover {
        margin-left: 0;
        margin-right: 0;
        aspect-ratio: 1.25;
        background: black;
        border-radius: 8px;
        overflow: hidden;
        max-width: 300px;

        img {
            aspect-ratio: 1.25;
            object-fit: cover;
            border-radius: 8px;
        }
    }

    .ant-card-body {
        padding: 10px;

        h5 {
            margin-bottom: 0;
        }

        .ant-rate {
            font-size: 10px;
            margin-bottom: 5px;
        }

        .ant-typography h5,
        h5.ant-typography {
            text-transform: capitalize !important ;
            margin-bottom: 0.7rem;
            font-size: 15px;
            letter-spacing: 1px;
            color: rgba(0, 0, 0, 0.8);
        }

        .developer-name {
            display: block;
            font-size: 12px;
            text-transform: capitalize;
            .user-avatar {
                height: 28px;
                width: 28px;
                margin-right: 10px;
                border-radius: 20px;
            }
        }

        .button-container {
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`;

export default style();
