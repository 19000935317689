import { css } from 'styled-components';

const style = () => css`
    padding: 0 10px;
    overflow: hidden;
    background-color: transparent;
    .ant-collapse {
        > .ant-collapse-item {
            position: relative;

            border-bottom-left-radius: 32px;
            border-bottom-right-radius: 32px;

            > .ant-collapse-header {
                transition: visibility 0.5s ease-in-out;

                text-indent: -57px;
                padding-left: 73px;
                @media (max-width: 540px) {
                    text-indent: -32px;
                    padding-left: 48px;
                }
            }

            &.ant-collapse-item-active {
                > .ant-collapse-header {
                    display: none;
                }
            }

            :not(:first-child) {
                padding-top: 32px;
                margin-top: -32px;
            }

            :first-child {
                border-radius: 32px;
            }

            .ant-collapse-header,
            .panel-header h1 {
                font-size: 32px;
                font-weight: 600;

                @media (max-width: 540px) {
                    font-size: 1.4rem;
                }
            }

            .ant-typography {
                font-weight: 400;
                font-size: 24px;
                color: #ffffff;
                @media (max-width: 540px) {
                    font-size: 1.1rem;
                }
            }

            .panel-header {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                h1,
                + .ant-typography {
                    padding-left: 57px;
                    @media (max-width: 540px) {
                        padding-left: 32px;
                    }
                }

                h1 {
                    color: #ffffff;

                    text-indent: -57px;
                    @media (max-width: 540px) {
                        text-indent: -32px;
                    }
                }
            }

            > .ant-collapse-header,
            .ant-collapse-content {
                color: #ffffff;
            }

            .ant-collapse-content-box {
                .panel-image {
                    width: 322px;
                    height: 260px;
                    @media (max-width: 540px) {
                        width: 120px;
                        height: auto;
                    }
                }
            }
        }
    }
`;

export default style();
