import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useQuery } from 'react-query';
import CategoryCard from './CategoryCard';
import { ApiClient } from '../../../../api';

const HomeCategoriesWrapper = styled.div`
    padding: 32px 0;
    overflow: hidden;

    .categories-title {
        /* font-size: 84px; */
        font-size: clamp(2.2rem, 5vw, 8rem);
        font-weight: 600;
        white-space: nowrap;
        color: #f83608;
        margin: 32px 0;
        text-align: center;
        overflow: hidden;
        // animation: animateTitle 30s alternate infinite;

        @media (max-width: 800px) {
            white-space: initial;
        }

        .ant-typography {
            color: #ffffff;
            text-shadow: -1px -1px 0 #d5d6db, 1px -1px 0 #d5d6db, -1px 1px 0 #d5d6db, 1px 1px 0 #d5d6db;
        }
    }

    .card-container {
        overflow: hidden;
        position: relative;
        width: 100vw;
        height: 150px;
        .card-track,
        .card-track-2 {
            display: flex;
            position: absolute;
            justify-content: center;
            left: 0;
            align-items: center;
            height: 80px;
            width: 200%;
            height: 100%;
        }
        .card-track {
            animation: slide 10s linear infinite;
            @keyframes slide {
                0% {
                    left: 0;
                }
                100% {
                    left: -100%;
                }
            }
        }
        .card-track-2 {
            animation: slide2 10s linear infinite;
            @keyframes slide2 {
                0% {
                    left: 0;
                }
                100% {
                    left: 100%;
                }
            }
        }
    }
`;

const { Text } = Typography;

const HomeCategories = () => {
    const [sortedData, setSortedData] = useState<CategoriesEntity[]>([]);
    const { data, isFetched } = useQuery<CategoriesEntity[]>('categories', ApiClient.getCategories);

    useEffect(() => {
        if (data && data.length) {
            const sorted = data.sort((a) => (a.name === 'Board' ? -1 : 1));
            setSortedData([...sorted, ...sorted]); // Duplicate the data for seamless transition
        }
    }, [data]);

    if (!isFetched) {
        return null;
    }

    return (
        <HomeCategoriesWrapper>
            <div className="card-container">
                <div className="card-track" id="carousel">
                    {sortedData?.map((item, index) => (
                        <CategoryCard data={item} key={index} index={index} />
                    ))}
                </div>
            </div>
            <div className="categories-title">
                <Text>Discover </Text>
                Categories
                <Text> to choose from</Text>
            </div>
            <div className="card-container">
                <div className="card-track-2" id="carousel">
                    {sortedData?.map((item, index) => (
                        <CategoryCard data={item} key={index} index={index} />
                    ))}
                </div>
            </div>
        </HomeCategoriesWrapper>
    );
};

export default HomeCategories;
