import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { PartitionOutlined } from '@ant-design/icons';
import styles from './styles';

const { Title } = Typography;

const DashboardCardWrapper = styled.div`
    s${styles}
`;

const DashboardCard = () => {
    return (
        <DashboardCardWrapper>
            <div>
                <div className={'card-title'}>Total Category</div>
                <Title level={4}>18</Title>
            </div>
            <div>
                <PartitionOutlined color={'white'} style={{ fontSize: 26 }} />
            </div>
        </DashboardCardWrapper>
    );
};

export default DashboardCard;
