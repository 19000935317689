import React from 'react';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import styled from 'styled-components';
import { API, ApiClient } from '../../../api';
import styles from './styles';
import { mutationError } from '../../../utils/helper';

const { Title } = Typography;

const PasswordWrapper = styled.div`
    ${styles}
`;

const ChangePassword = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [form] = Form.useForm();

    const onFinish = React.useCallback(async (values: any) => {
        setLoading(true);
        try {
            const submitData = { ...values };
            delete submitData['confirmPassword'];

            await API.post(API.PATH.changePassword, submitData);
            setLoading(false);
            ApiClient.showSuccess('Password Updated Successfully');
            form.resetFields();
        } catch (e: any) {
            mutationError(e);
            setLoading(false);
        }
    }, []);

    return (
        <PasswordWrapper>
            <Row gutter={16}>
                <Col span={11}>
                    <div className="password-container">
                        <Title level={4}> Change Password</Title>

                        <Form layout="vertical" onFinish={onFinish} form={form} style={{ padding: '10px' }}>
                            <Row gutter={10}>
                                <Col span={24}>
                                    <Form.Item
                                        label={'Current Password'}
                                        name="currentPassword"
                                        rules={[{ required: true, message: 'Enter current password' }]}
                                    >
                                        <Input.Password
                                            className={'weplay-input'}
                                            placeholder={'Enter current password'}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                label={'New Password'}
                                name="newPassword"
                                rules={[
                                    { required: true, message: 'Enter new password' },
                                    () => ({
                                        validator(_, value) {
                                            if (value?.length > 7) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('Password must contain atleast 8 characters'),
                                            );
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password placeholder={'Minimum 8 characters'} className={'weplay-input'} />
                            </Form.Item>

                            <Form.Item
                                label={'Confirm Password'}
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('The two passwords that you entered do not match!'),
                                            );
                                        },
                                    }),
                                ]}
                                dependencies={['newPassword']}
                                hasFeedback
                            >
                                <Input.Password
                                    placeholder={'Enter Confirm Password'}
                                    className={'weplay-input'}
                                    name="url"
                                />
                            </Form.Item>

                            <Button className="primary-button submit-button" htmlType="submit" loading={loading}>
                                Change
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </PasswordWrapper>
    );
};

export default ChangePassword;
