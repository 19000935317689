import React, { forwardRef } from 'react';
import { Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { CategoryForm } from '../../../components/category/components';
import { ApiClient } from '../../../api';

export interface NewCategoryFormRef {
    openModal: () => void;
}

const NewCategoryForm = forwardRef<NewCategoryFormRef>((_, ref) => {
    const queryClient = useQueryClient();
    const [visible, setVisible] = React.useState(false);

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: () => setVisible(true),
        }),
        [],
    );

    const { mutate: onFinish, isLoading } = useMutation(ApiClient.createCategory, {
        onSuccess: async (newRoom) => {
            await queryClient.cancelQueries('categories');

            queryClient.setQueryData('categories', (old: any) => {
                return [newRoom, ...(old || [])];
            });

            ApiClient.showSuccess('Category successfully added');
            setVisible(false);
        },
    });

    return (
        <Modal
            destroyOnClose
            title={'Create new category'}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <CategoryForm onFinish={onFinish} loading={isLoading} />
        </Modal>
    );
});

NewCategoryForm.displayName = 'New Category Form';

export default NewCategoryForm;
