import { Col, Image, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../assets/images/Layer-2.png';
import PlayStore from '../../assets/images/google.svg';
import AppStore from '../../assets/images/appstore.svg';

const NavWrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 10px;

    z-index: 100;
    //background: red;

    height: auto;

    .nav-container {
        width: calc(100% - 32px);
        max-width: 1530px;
        background: #ffffff;
        border-radius: 10px;
        margin: 0 auto;
        //box-shadow: 0 2px 8px rgba(172, 168, 168, 0.25);
        -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo-img {
            max-height: 36px;
            max-width: 150px;
        }
        .playstore-img {
            cursor: pointer;
            max-height: 50px;
        }
        .appstore-img {
            cursor: pointer;
            max-height: 36px;
            margin-left: 8px;
        }
    }
    @media (max-width: 765px) {
        .nav-container {
            padding: 8px 10px;
        }
        .logo-img {
            height: 30px;
            width: auto;
        }
        .playstore-img {
            height: 30px;
        }
        .appstore-img {
            height: 30px;
        }
    }
`;

const HomeNavbar: FunctionComponent<any> = () => {
    const navigate = useNavigate();

    return (
        <NavWrapper id="navbar">
            <Row className="nav-container">
                <Col>
                    <Image
                        onClick={() => {
                            navigate('/');
                            //scroll the page to top
                            window.scrollTo(0, 0);
                        }}
                        src={Logo}
                        preview={false}
                        className="logo-img"
                    />
                </Col>
                <Col>
                    <Image
                        src={PlayStore}
                        onClick={() => {
                            window.open(
                                'https://play.google.com/store/apps/details?id=com.weplayet.weplay&hl=en&gl=US',
                            );
                        }}
                        preview={false}
                        className="playstore-img"
                    />
                    <Image
                        src={AppStore}
                        onClick={() => {
                            window.open('https://apps.apple.com/us/app/weplay-arcade/id1642292314');
                        }}
                        preview={false}
                        className="appstore-img"
                    />
                </Col>
            </Row>
        </NavWrapper>
    );
};

export default HomeNavbar;
