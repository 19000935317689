import { API } from '../../api';

export const generateImageData = (url: string) => {
    return {
        uid: Math.random() * Math.random() * 100000,
        name: url.split('/').pop(),
        status: 'done',
        url: API.getImageUrl(url),
        thumbUrl: API.getImageUrl(url),
    };
};
