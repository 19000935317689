import { QueryFunctionContext } from 'react-query';
import { API } from '../api';

export function fetchTrackers({ signal, pageParam = 1, queryKey }: QueryFunctionContext) {
    const nameObj = queryKey[1] ? { name: queryKey[1] } : {};
    return API.get(API.PATH.getAllTrackers, {
        params: {
            limit: 10,
            page: pageParam,
            ...nameObj,
        },
        signal: signal,
    }).then((response) => response.data);
}
