import { css } from 'styled-components';

const leagueRewardStyles = () => css`
    > .ant-divider-with-text {
        margin-bottom: 8px;
    }

    .league-reward {
        > .ant-row {
            position: relative;
            margin-right: 20px !important;
        }

        .ant-form-item {
            margin-bottom: 16px;
            > .ant-form-item-label {
                text-align: center;
            }

            .position {
                height: 45px;
                line-height: 45px;
                text-align: center;
            }
        }
        .ant-form-item-with-help {
            margin-bottom: 0;
        }

        .remove-action {
            position: absolute;
            right: -20px;
            top: 12px;
            font-size: 20px;
            display: none;
        }

        .league-field:last-child {
            .remove-action {
                display: inline-block;
            }
        }

        .league-field:only-child,
        .league-field:not(:last-child) {
            .remove-action {
                display: none !important;
                cursor: not-allowed;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
        .add-button {
            height: 32px;
            padding: 4px 15px;
        }
    }
`;

export default leagueRewardStyles();
