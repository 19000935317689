import React, { FC } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Card, Rate, Typography } from 'antd';

import style from './styles';
import { ROUTES } from '../../../../../router';
import { API } from '../../../../../api';

const { Title } = Typography;

const GameCardContainer = styled.div`
    ${style}
`;

interface GameCardProps {
    data: Game;
}

const TrendingCard: FC<GameCardProps> = ({ data }) => {
    const navigate = useNavigate();
    const { isLoading, mutate } = useMutation<any, any, string>('toggleTrending');

    const cardStyle = {
        minWidth: '200px',
        borderRadius: '16px',
        boxShadow: '5px 8px 24px 5px rgba(231, 231, 233, 0.6)',
        padding: '0.7rem',
    };

    const viewGame = (id) => {
        navigate(ROUTES.VIEW_GAMES.split(':').shift() + `${id}`, {
            state: { gameId: id },
        });
    };

    function setTrending(e: React.MouseEvent) {
        e.stopPropagation();
        mutate(data.id);
    }

    return (
        <GameCardContainer>
            <Badge.Ribbon text={data?.trending && 'Trending'} color={'#52c41a'}>
                <Card
                    cover={<img alt={data?.title} src={API.getImageUrl(data?.images?.at(0))} />}
                    style={cardStyle}
                    onClick={() => viewGame(data?.id)}
                >
                    <Title level={5}>{data.title}</Title>

                    <div className="button-container">
                        {/* <Badge
                            className="site-badge-count-109"
                            count={(data?.trending && 'Trending') || (data?.highlighted && 'HightLighted')}
                            style={{ backgroundColor: '#52c41a' }}
                        /> */}
                        <Rate value={+data?.rating} allowHalf disabled />
                        <Button loading={isLoading} onClick={setTrending} className="remove">
                            Remove
                        </Button>
                    </div>
                </Card>
            </Badge.Ribbon>
        </GameCardContainer>
    );
};

export default TrendingCard;
