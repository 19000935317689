import React, { forwardRef } from 'react';
import { Form, Input, Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { CategoryForm } from '../../../components/category/components';
import { ApiClient } from '../../../api';

export interface EditCategoryFormRef {
    openModal: (values: any) => void;
}

const EditCategoryForm = forwardRef<EditCategoryFormRef>((_, ref) => {
    const queryClient = useQueryClient();

    const [visible, setVisible] = React.useState(false);

    const [form] = Form.useForm();

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: (values) => {
                form.setFieldsValue(values);
                setVisible(true);
            },
        }),
        [],
    );

    const { mutate: onFinish, isLoading } = useMutation(ApiClient.updateCategory, {
        onSuccess: async (newCate) => {
            await queryClient.cancelQueries('categories');

            queryClient.setQueryData('categories', (old: any) => {
                return old?.map((value) => {
                    if (value.id == newCate.id) {
                        value = newCate;
                    }
                    return value;
                });
            });

            ApiClient.showSuccess('Category successfully updated');
            setVisible(false);
        },
    });

    return (
        <Modal
            destroyOnClose
            title={'Edit category'}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <CategoryForm form={form} onFinish={onFinish} loading={isLoading}>
                <Form.Item hidden name={'id'}>
                    <Input />
                </Form.Item>
            </CategoryForm>
        </Modal>
    );
});

EditCategoryForm.displayName = 'Edit Category Form';

export default EditCategoryForm;
