import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import styles from './styles';
import HomeFooter from './component/HomeFooter';
import Help from './component/Help';
import CollapseTest from './component/CollapseTest';
import HomeCategories from './component/category/HomeCategories';
import FirstPage from './component/FirstPage';
import WelcomePage from './component/WelcomePage';
import HomeNavbar from './component/HomeNavbar';
import ImageSlider from './component/image-slider';
import BubbleAvatar from './component/bubble-avatar';
import WelcomeText from './component/welcome-text';
import CookieConsent from 'react-cookie-consent';

const HomePageWrapper = styled(Layout)`
    ${styles}
`;

const HomePage = () => {
    return (
        <HomePageWrapper>
            <Layout.Content>
                <WelcomePage />
                <HomeNavbar />
                <WelcomeText />
                <FirstPage />
                <ImageSlider />
                <HomeCategories />
                <CollapseTest />
                <BubbleAvatar />
                <Help />
            </Layout.Content>
            <HomeFooter />
            <CookieConsent
                enableDeclineButton
                style={{ color: 'GrayText', background: 'black' }}
                buttonText="Accept"
                declineButtonText="Decline"
                buttonStyle={{
                    color: '#fff',
                    fontSize: '14px',
                    background: 'transparent',
                    border: '1px solid #fff',
                    borderRadius: '5px',
                    padding: '7px 15px',
                }}
                declineButtonStyle={{
                    color: '#fff',
                    fontSize: '14px',
                    background: 'transparent',
                    border: '1px solid #fff',
                    borderRadius: '5px',
                    padding: '7px 15px',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'black' }}>
                    <div
                        className="textWrapper"
                        style={{ display: 'flex', flexDirection: 'column', marginRight: '10px', color: '#efefef' }}
                    >
                        We use Cookies to make our site work, customize content and your experience, provide social
                        media features and measure site usage. To do so, we sometimes share your data with selected
                        partners.
                        <br />
                        <div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            You can accept or decline by clicking the buttons below, or by visiting anytime the{' '}
                            <b style={{ color: 'white', margin: '0px 5px' }}>Privacy settings</b>
                        </div>
                        <br />
                        <div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            For more information please visit our{' '}
                            <span
                                onClick={() => {
                                    location.href = '/privacy-policy';
                                }}
                                style={{ margin: '0px 5px', color: '#0492c2' }}
                            >
                                Privacy Policy
                            </span>{' '}
                            and{' '}
                            <span
                                onClick={() => {
                                    location.href = '/cookie-policy';
                                }}
                                style={{ margin: '0px 5px', color: '#0492c2' }}
                            >
                                Cookie Policy
                            </span>
                        </div>
                    </div>
                </div>
            </CookieConsent>
        </HomePageWrapper>
    );
};

export default HomePage;
