import { css } from 'styled-components';
import { COLORS } from '../../config/theme';

const style = () => css`
    position: sticky;
    top: 0;
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            width: 140px;
        }
        .anticon {
            display: none;
            font-size: 18px;
        }
    }
    .ant-menu {
        .ant-menu-item {
            :active {
                background: unset;
            }

            :hover {
                color: ${(props: any) => props.theme[COLORS.PRIMARY]} !important;
            }

            :after {
                right: unset;
                left: 0;
                border-right-width: 3px;
                border-right-color: ${(props: any) => props.theme[COLORS.PRIMARY]};
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }

        .ant-menu-item-selected {
            background: unset !important;
            :after {
                opacity: 1;
                transform: scaleY(1);
            }
        }
    }

    .ant-menu-inline-collapsed {
        .header {
            .logo {
                display: none;
            }

            .anticon,
            .ant-menu-item-icon {
                display: block;
            }
        }
        .ant-menu-item-selected {
            span.anticon {
                color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            }
        }
    }

    .ant-menu-inline {
        .ant-menu-item {
            padding-left: 16px !important;

            span.anticon,
            .icon-span {
                height: 40px;
                display: flex;
                align-items: center;
                padding-left: 12px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            span.ant-menu-title-content {
                margin-left: unset;
                padding-left: 10px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }

        .ant-menu-item-selected {
            background: unset !important;
            span,
            .extra-icon {
                background: ${(props: any) => props.theme[COLORS.PRIMARY]} !important;
                color: ${(props: any) => props.theme[COLORS.WHITE]} !important;
            }
        }
    }
`;

export default style();
