import React, { FunctionComponent } from 'react';
import { Moment } from 'moment';
import styled from 'styled-components';
import { Col, Row, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';

import { GameStatsComponent } from './components';
import { DrawerLayout } from '../../Layout';
import style from './styles';
import { convertNumberToCommaString, hex2rgba, nFormatter } from '../../utils/helper';
import { ROUTES } from '../../router';
import checkers from '../../assets/images/checkers.jpeg';
import chess from '../../assets/images/chess.jpeg';

const { RangePicker } = DatePicker;

const DashboardWrapper = styled.div`
    ${style}
`;

interface Props {
    gameStats: { [key: string]: number };
    depositsData: any;
    withdrawalsData: any;
    dateRange: [Moment, Moment];
    setDateRange: (dates: [Moment, Moment]) => void;
    insightsData: any;
}

const colors = ['#1E87F0', '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#00ff00', '#00ffff'];

const DashBoard: FunctionComponent<Props> = ({
    gameStats,
    depositsData,
    withdrawalsData,
    insightsData,
    dateRange,
    setDateRange,
}: Props) => {
    const navigate = useNavigate();

    const handleClick = (link: string) => {
        if (link === 'Payments') {
            navigate(ROUTES.PAYMENTS);
        } else if (link === 'Users') {
            navigate(ROUTES.USERS);
        } else if (link === 'Games') {
            navigate(ROUTES.GAMES);
        }
    };

    return (
        <DrawerLayout>
            <DashboardWrapper>
                <div className="dashboard-links">
                    {['Payments', 'Users', 'Games'].map((link, index) => (
                        <div
                            key={link}
                            className="dashboard-link"
                            style={{
                                backgroundColor: hex2rgba(colors[index], 0.1),
                                color: colors[index],
                                border: `1px solid ${colors[index]}`,
                            }}
                            onClick={handleClick.bind(null, link)}
                        >
                            {link}
                        </div>
                    ))}
                </div>
                <Row style={{ gap: 15, marginTop: 15, marginBottom: 15 }}>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Current Withdrawal Balance</div>
                        <div className="dashboard-card-content-item-value primary-text">
                            <span className="currency">KES</span>{' '}
                            {convertNumberToCommaString(withdrawalsData?.current_withdrawal_balance ?? 0)}
                        </div>
                    </div>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Current Working Account Balance</div>
                        <div className="dashboard-card-content-item-value primary-text">
                            <span className="currency">KES</span>{' '}
                            {convertNumberToCommaString(withdrawalsData?.current_working_account_balance ?? 0)}
                        </div>
                    </div>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Current Deposits Account Balance</div>
                        <div className="dashboard-card-content-item-value primary-text">
                            <span className="currency">KES</span>{' '}
                            {convertNumberToCommaString(withdrawalsData?.current_deposits_account_balance ?? 0)}
                        </div>
                    </div>
                </Row>
                <Row style={{ width: '100%', gap: 15 }}>
                    <Col style={{ flex: 3 }}>
                        <Row style={{ gap: 15, marginBottom: 15 }}>
                            <div className="dashboard-card-content-item">
                                <div className="dashboard-card-content-item-label">Platform Fees</div>
                                <div className="dashboard-card-content-item-value">
                                    <span className="currency">KES</span> {depositsData?.platform_fees ?? 0}
                                </div>
                            </div>

                            <div className="dashboard-card-content-item">
                                <div className="dashboard-card-content-item-label">Withdrawal Charges</div>
                                <div className="dashboard-card-content-item-value">
                                    <span className="currency">KES</span> {depositsData?.withdrawal_charges ?? 0}
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <GameStatsComponent gameStats={gameStats} />
                        </Row>
                    </Col>
                    <Col style={{ flex: 5 }}>
                        <div className="dashboard-card">
                            <div className="dashboard-card-header">
                                <div className="dashboard-card-header-title">INSIGHTS</div>
                                <div className="dashboard-card-header-date">
                                    <RangePicker
                                        value={dateRange}
                                        onChange={(values) => {
                                            if (values && values[0] && values[1]) {
                                                setDateRange([values[0], values[1]]);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="dashboard-card-content">
                                <div className="dashboard-card-checkers">
                                    <div className="dashboard-card-stats-item-wrapper">
                                        <div className="dashboard-card-stats-item">
                                            <div className="dashboard-card-stats-item-label">Total games</div>
                                            <div className="dashboard-card-stats-item-value primary">
                                                {insightsData?.checkers_gameplays ?? 0}
                                            </div>
                                        </div>
                                        <div className="dashboard-card-title">
                                            <img src={checkers} alt="checkers" />
                                        </div>
                                    </div>
                                    <div className="dashboard-card-stats-item-wrapper">
                                        <div className="dashboard-card-stats-item" style={{ marginTop: 15 }}>
                                            <div className="dashboard-card-stats-item-label">Wins</div>
                                            <div className="dashboard-card-stats-item-value" style={{ marginTop: 10 }}>
                                                {insightsData?.game_wins?.checkers_wins ?? 0}
                                            </div>
                                        </div>
                                        <div
                                            className="dashboard-card-stats-item"
                                            style={{ alignItems: 'flex-end', marginTop: 15 }}
                                        >
                                            <div className="dashboard-card-stats-item-label">Amount</div>
                                            <div
                                                className="dashboard-card-stats-item-value success"
                                                style={{ marginTop: 10 }}
                                            >
                                                <span className="currency">KES</span>{' '}
                                                {nFormatter(insightsData?.game_win_amounts?.checkers_wins ?? 0)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-card-chess">
                                    <div className="dashboard-card-stats-item-wrapper">
                                        <div className="dashboard-card-stats-item">
                                            <div className="dashboard-card-stats-item-label">Total games</div>
                                            <div className="dashboard-card-stats-item-value primary">
                                                {insightsData?.chess_gameplays ?? 0}
                                            </div>
                                        </div>
                                        <div className="dashboard-card-title">
                                            <img src={chess} alt="checkers" />
                                        </div>
                                    </div>
                                    <div className="dashboard-card-stats-item-wrapper">
                                        <div className="dashboard-card-stats-item" style={{ marginTop: 15 }}>
                                            <div className="dashboard-card-stats-item-label">Wins</div>
                                            <div className="dashboard-card-stats-item-value" style={{ marginTop: 10 }}>
                                                {insightsData?.game_wins?.chess_wins ?? 0}
                                            </div>
                                        </div>
                                        <div
                                            className="dashboard-card-stats-item"
                                            style={{ alignItems: 'flex-end', marginTop: 15 }}
                                        >
                                            <div className="dashboard-card-stats-item-label">Amount</div>
                                            <div
                                                className="dashboard-card-stats-item-value success"
                                                style={{ marginTop: 10 }}
                                            >
                                                <span className="currency">KES</span>{' '}
                                                {nFormatter(insightsData?.game_win_amounts?.chess_wins ?? 0)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row style={{ gap: 15, marginTop: 15 }}>
                                <div className="dashboard-card-content-item bordered">
                                    <div className="dashboard-card-content-item-label">Total gameplays</div>
                                    <div
                                        className="dashboard-card-content-item-value primary"
                                        style={{ marginTop: 20 }}
                                    >
                                        {insightsData?.total_revenue_based_gameplays ?? 0}
                                    </div>
                                </div>
                                <div className="dashboard-card-content-item bordered">
                                    <div className="dashboard-card-content-item-label">Total Wins</div>
                                    <div className="dashboard-card-content-item-value primary-text">
                                        {insightsData?.total_user_wins ?? 0}
                                    </div>
                                </div>
                                <div className="dashboard-card-content-item bordered">
                                    <div className="dashboard-card-content-item-label">Total Win amount</div>
                                    <div className="dashboard-card-content-item-value">
                                        <span className="currency">KES</span>{' '}
                                        {nFormatter(insightsData?.total_user_win_amount ?? 0)}
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row style={{ gap: 15, marginTop: 15 }}>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Total Deposits</div>
                        <div className="dashboard-card-content-item-value primary-text">
                            {withdrawalsData?.total_deposits ?? 0}
                        </div>
                    </div>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Amount Deposited</div>
                        <div className="dashboard-card-content-item-value">
                            <span className="currency">KES</span>{' '}
                            {convertNumberToCommaString(withdrawalsData?.total_deposits_value ?? 0)}
                        </div>
                    </div>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Total Withdrawals</div>
                        <div className="dashboard-card-content-item-value primary-text">
                            {withdrawalsData?.total_withdrawals ?? 0}
                        </div>
                    </div>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Amount Withdrawn</div>
                        <div className="dashboard-card-content-item-value">
                            <span className="currency">KES</span>{' '}
                            {convertNumberToCommaString(withdrawalsData?.total_withdrawal_value ?? 0)}
                        </div>
                    </div>
                </Row>
            </DashboardWrapper>
        </DrawerLayout>
    );
};

export default DashBoard;
