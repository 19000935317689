export function lpad(value: number | string, padding = 3) {
    const zeroes = new Array(padding + 1).join('0');
    return (zeroes + value).slice(-padding);
}

export function capitalizeFirstLetter(string?: string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function hex2rgba(hex: string, alpha = 1) {
    const [r, g, b] = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [];
    if (typeof r === 'undefined') return '';
    return `rgba(${r},${g},${b},${alpha})`;
}

export function nFormatter(num: number, digits = 2) {
    const si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    // eslint-disable-next-line no-restricted-syntax
    for (i = si.length - 1; i > 0; i -= 1) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function convertNumberToCommaString(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function titleCase(str: string) {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.replace(word[0], word[0].toUpperCase()))
        .join(' ');
}

export function checkIfWebsiteOnIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window);
}

export const playClickAction = () => {
    if (checkIfWebsiteOnIOS()) {
        window.open('https://apps.apple.com/us/app/weplay-arcade/id1642292314', '_blank');
    } else {
        const link = document.createElement('a');
        link.href = 'https://games.weplay.africa/files/weplay.apk';
        link.download = 'weplay.apk';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export * from './objectHelper';

export * from './axiosErrorHelper';

export * from './image-helper';
