import { css } from 'styled-components';

const popoverStyles = () => css`
    .ant-popover-title {
        color: red !important;
    }

    .trending-btn {
        height: 28px !important;
        padding: 0 9px !important;
        display: block;
        /* margin: 4px 0 4px auto; */
        color: #fff;
        border-radius: 5px;
        padding: 5px;
        font-size: 10px;
        background: #2ecc71;
        color: white;
        border: 1px solid #2ecc71;

        &:hover {
            opacity: 0.8;
        }
    }

    .highlight-btn {
        height: 28px !important;
        padding: 0 9px !important;
        display: block;
        /* margin: 4px 0 4px auto; */
        color: #fff;
        border-radius: 5px;
        padding: 5px;
        font-size: 10px;
        background: #e67e22;
        color: white;
        border: 1px solid #e67e22;

        &:hover {
            opacity: 0.8;
        }
    }

    .trending-text {
        color: #2ecc71;
        font-weight: 600;
        font-size: 12px;
    }
    .highlight-text {
        font-weight: 600;
        color: #e67e22;
        font-size: 12px;
    }
`;

export { popoverStyles };
