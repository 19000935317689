import React from 'react';
import styled from 'styled-components';
import { Button, Input, Table } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { DrawerLayout } from '../../Layout';
import style from './styles';
import { PaginationConfig, paginationStyles } from '../common';

const CategoryWrapper = styled.div`
    ${style}
    ${paginationStyles}
`;

interface Props {
    columns: any;
    data?: Category[];
    search: string;
    setSearch: (value: any) => void;
    loading?: boolean;
    onAddClick?: () => void;
}

const Category: React.FC<Props> = ({ columns, data, search, setSearch, loading, children, onAddClick }) => {
    return (
        <DrawerLayout>
            <CategoryWrapper>
                <div className={'header'}>
                    <div className="flex-1" />
                    <div>
                        <Input
                            value={search}
                            allowClear
                            placeholder={'Search category'}
                            prefix={<SearchOutlined />}
                            onChange={(event) => {
                                setSearch(event.target.value);
                            }}
                        />
                    </div>
                    <Button
                        className={'primary-button add-button'}
                        icon={<PlusOutlined />}
                        onClick={() => onAddClick?.()}
                    >
                        Add Category
                    </Button>
                </div>
                <Table
                    loading={loading}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={data}
                    pagination={PaginationConfig}
                />
                {children}
            </CategoryWrapper>
        </DrawerLayout>
    );
};

export default Category;
