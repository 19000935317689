import { css } from 'styled-components';

const selectStyles = () => css`
    .ant-select-selector {
        height: 45px !important;
        line-height: 43px !important;
        border-radius: 5px !important;
        background: #fafafa;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        font-weight: 500;

        .ant-select-selection-placeholder {
            line-height: 43px !important;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-item {
            line-height: 0 !important;
            top: 30px;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
            line-height: 0 !important;
        }
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
        content: '';
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: unset;
    }
`;

export default selectStyles();
