import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Users from '../../components/users';
import { ApiClient } from '../../api';
import { Columns } from './data';
import { ROUTES } from '../../router';

const UsersContainer = () => {
    const navigate = useNavigate();
    const [search, setSearch] = React.useState<string>('');

    const { data, isFetching, fetchNextPage, fetchPreviousPage, isFetchingNextPage, isFetchingPreviousPage } =
        useInfiniteQuery<Pagination<User>>(['users', search], ApiClient.fetchUsers, {
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                return lastPage.nextPage;
            },
        });

    const users = React.useMemo(() => {
        return data?.pages.map((page) => page.data).flat();
    }, [data]);

    const total = React.useMemo(() => {
        return data?.pages?.[0]?.total;
    }, [data]);

    function onDetailClick(id: string) {
        return navigate(ROUTES.USERS_DETAILS.split(':').shift() + `${id}`);
    }

    return (
        <Users
            data={users}
            loading={isFetching}
            columns={Columns(onDetailClick)}
            total={total ?? 0}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            isFetchingNextPage={isFetchingNextPage}
            isFetchingPreviousPage={isFetchingPreviousPage}
            setFetchSearch={setSearch}
        />
    );
};

export default UsersContainer;
