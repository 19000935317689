import React from 'react';
import { useQuery } from 'react-query';
import { Modal, Skeleton } from 'antd';
import { ApiClient } from '../../api';
import KnockoutDetails from '../../components/knockout/KnockoutDetails';

export interface KnockoutDetailRef {
    openModal: any;
}

const KnockoutRoomDetail = React.forwardRef<KnockoutDetailRef>((_, ref) => {
    const [knockoutId, setKnockoutId] = React.useState<string>();

    const { data, isFetching } = useQuery(['knockout', knockoutId], ApiClient.getKnockoutDetail, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(knockoutId),
    });

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: (record: any) => setKnockoutId(record?.id || record.toString()),
        }),
        [],
    );

    return (
        <Modal
            destroyOnClose
            title={'Knockout Details'}
            visible={Boolean(knockoutId)}
            onCancel={() => setKnockoutId(undefined)}
            footer={null}
            maskClosable={false}
            width={800}
        >
            <Skeleton loading={isFetching}>
                <KnockoutDetails data={data} />
            </Skeleton>
        </Modal>
    );
});

KnockoutRoomDetail.displayName = 'Knockout Details';

export default KnockoutRoomDetail;
