import React, { forwardRef } from 'react';
import { Form, Input, Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { DeveloperForm } from '../../../components/developers/components';
import { ApiClient } from '../../../api';
import { generateImageData } from '../../../utils/helper';

export interface EditDeveloperFormRef {
    openModal: (values: any) => void;
}

const EditDeveloperForm = forwardRef<EditDeveloperFormRef>((_, ref) => {
    const queryClient = useQueryClient();

    const [visible, setVisible] = React.useState(false);

    const [form] = Form.useForm();

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: (values: Developer) => {
                form.setFieldsValue({
                    ...values,
                    avatar: values?.avatar ? [generateImageData(values.avatar)] : [],
                });
                setVisible(true);
            },
        }),
        [],
    );

    const { mutate: onFinish, isLoading } = useMutation(ApiClient.updateDeveloper, {
        onSuccess: async () => {
            await queryClient.cancelQueries('developers');
            await queryClient.invalidateQueries('developers');
            ApiClient.showSuccess('Developer successfully updated');
            setVisible(false);
        },
    });

    return (
        <Modal
            destroyOnClose
            title={'Edit Developer'}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <DeveloperForm form={form} onFinish={onFinish} loading={isLoading}>
                <Form.Item hidden name={'id'}>
                    <Input />
                </Form.Item>
            </DeveloperForm>
        </Modal>
    );
});

EditDeveloperForm.displayName = 'Edit Developer Form';

export default EditDeveloperForm;
