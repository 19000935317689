import images from '../../../../assets/avatars';

const imgDataInternal = images.map((img, index) => {
    return {
        id: index,
        image: img,
        alt: 'avatar',
    };
});

const imgData = imgDataInternal;

export { imgData };
