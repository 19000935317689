import React from 'react';
import { DrawerLayout } from '../../../Layout';
// import GamePlayedContainer from '../../../containers/users/user-details/GamePlayedContainer';
import TransactionContainer from '../../../containers/users/user-details/TransactionContainer';

const ViewUser = () => {
    return (
        <DrawerLayout>
            {/*<GamePlayedContainer />*/}
            <TransactionContainer />
        </DrawerLayout>
    );
};

export default ViewUser;
