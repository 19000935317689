import { DEFAULT_COLORS } from './colors';

const generateTheme = () => {
    return Object.assign({}, DEFAULT_COLORS);
};

const LIGHT_THEME = generateTheme();

const COLORS = {
    PRIMARY: 'PRIMARY',
    PRIMARY_TEXT: 'PRIMARY_TEXT',
    SECONDARY: 'SECONDARY',
    WHITE: 'WHITE',

    //FORMS
    CARD_SHADOW: 'CARD_SHADOW',
    FORM_COLOR: 'FORM_COLOR',
    BORDER: 'BORDER',
    ICONS: 'ICONS',

    //STATE
    DECLINED: 'DECLINED',
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

const DEVICE = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
};

const DEVICE_MAX = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
};

export { LIGHT_THEME, COLORS, DEVICE, DEVICE_MAX };

export type ColorType = keyof typeof COLORS;
