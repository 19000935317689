import axios from 'axios';
import { notification } from 'antd';

function extractErrorMessage(data: any) {
    if (typeof data == 'string') {
        return data;
    }
    const messages = data?.message || data?.messages;
    if (Array.isArray(messages)) {
        return extractErrorMessage(messages[0]?.message || messages[0]);
    }
    return extractErrorMessage(messages?.message || messages);
}

export function axiosErrorHelper() {
    console.log('');
}

export function mutationError(error: any) {
    if (axios.isAxiosError(error)) {
        const errorData = error.response?.data;
        if (errorData) {
            const message = extractErrorMessage(errorData);
            notification.error({
                type: 'error',
                message: 'Error!',
                description: message || 'Something went wrong',
            });
        }
    }
}
