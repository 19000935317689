import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { COLORS } from '../../config/theme';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router';
import { InMemoryJWTManager } from '../../api';

const LogOutWrapper = styled.div`
    padding: 12px;
    background: ${(props: any) => props.theme[COLORS.WHITE]};
    box-shadow: ${(props: any) => props.theme[COLORS.CARD_SHADOW]};
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
`;

const LogOut = () => {
    const navigate = useNavigate();
    return (
        <LogOutWrapper>
            <Button
                type="link"
                className="sign-out"
                onClick={() => {
                    InMemoryJWTManager.resetAccessToken();
                    navigate(ROUTES.SIGN_IN);
                }}
            >
                Sign Out
            </Button>
        </LogOutWrapper>
    );
};

export default LogOut;
