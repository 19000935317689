import { notification } from 'antd';

export * from './authQueries';

export * from './gameQueries';

export * from './leagueQueries';

export * from './gameRoomQueries';

export * from './categoryQueries';

export * from './reviewsQueries';

export * from './commentQueries';

export * from './userQueries';

export * from './creatorQueries';

export * from './knockoutQueries';

export * from './moneyWalletQueries';

export * from './dashboardQueries';

export * from './insightQueries';

export * from './featureQueries';

export * from './trackerQueries';

export function getNextPageParam<T>(lastPage: Pagination<T>) {
    if (lastPage.nextPage !== null) {
        return lastPage.nextPage;
    }
    return undefined;
}

export function showSuccess(message?: string) {
    if (typeof message == 'string') {
        notification.success({
            type: 'success',
            message: 'Success',
            description: message,
        });
    }
}

export function showError(message?: string) {
    if (typeof message == 'string') {
        notification.success({
            type: 'error',
            message: 'Error!',
            description: message,
        });
    }
}
