import { InfiniteData, QueryFunctionContext } from 'react-query';
import { API } from '../api';

export function createGame(data: any) {
    return API.post(API.PATH.createGame, data).then((res) => res.data);
}

export function updateGame({ gameId, data }: { gameId: string; data: any }) {
    return API.patch(API.PATH.updateGame(gameId), data).then((res) => res.data);
}

export function resetApiKey(gameId: string) {
    return API.post(API.PATH.resetApiKey, { gameId }).then((res) => res.data);
}

export function getGameDetails({ signal, queryKey }: QueryFunctionContext) {
    const [, gameId] = queryKey as string[];
    return API.get(API.PATH.getSingleGame(gameId), { signal }).then((res) => res.data);
}

export function fetchGames({ signal, pageParam = 1 }: QueryFunctionContext) {
    return API.get(API.PATH.getGames, {
        params: {
            limit: 5,
            page: pageParam,
        },
        signal: signal,
    }).then((response) => response.data);
}

export function fetchAllGames({ signal, pageParam = 1 }: QueryFunctionContext) {
    return API.get(API.PATH.getGames, {
        params: {
            limit: 25,
            page: pageParam,
        },
        signal: signal,
    }).then((response) => response.data);
}

export function searchGames({ signal, queryKey }: QueryFunctionContext) {
    const [, filter] = queryKey as string[];
    return API.get(API.PATH.getGames, {
        params: {
            limit: 15,
            title: filter || undefined,
        },
        signal: signal,
    }).then((response) => response.data?.data || []);
}

// Highlighted

export function fetchHighlightedGames({ signal, pageParam = 1 }: QueryFunctionContext) {
    return API.get(API.PATH.getGames, {
        params: {
            highlighted: true,
            limit: 5,
            page: pageParam,
        },
        signal: signal,
    }).then((response) => response.data);
}

export function toggleHighlight(gameId: string) {
    return API.patch(API.PATH.toggleHighlight(gameId)).then((response) => response.data);
}

// Trending

export function fetchTrendingGames({ signal, pageParam = 1 }: QueryFunctionContext) {
    return API.get(API.PATH.getGames, {
        params: {
            trending: true,
            limit: 5,
            page: pageParam,
        },
        signal: signal,
    }).then((response) => response.data);
}

export function toggleTrending(gameId: string) {
    return API.patch(API.PATH.toggleTrending(gameId)).then((response) => response.data);
}

// Recent Played game for user

export function getPlayedGames({ signal, queryKey }: QueryFunctionContext) {
    const [, userId] = queryKey as string[];
    return API.get(API.PATH.playedGames(userId), {
        signal: signal,
    }).then((response) => response.data);
}

// Selection purpose use only

export function selectGame<T>(pageData: InfiniteData<T>) {
    return {
        pages: pageData.pages.slice(-1),
        pageParams: pageData.pageParams.slice(-1),
    };
}

export function getTrendingGames({ signal }: QueryFunctionContext) {
    return API.get(API.PATH.getTrendingGames, { signal }).then((res) => res.data);
}
