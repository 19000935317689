import React, { FC } from 'react';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';

const AuthUserWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;

    .user-avatar {
        height: 40px;
        width: 40px;
        border-radius: 20px;
    }
    .avatar-wrapper {
        height: 40px;
        width: 40px;
        border-radius: 10rem;
        background-color: #2980b9;
        display: flex;
        align-items: center;
        justify-content: center;

        h5 {
            margin: unset;
            font-size: 15px;
            font-weight: bold;
            color: #fff;
        }
    }

    .name {
        display: flex;
        flex-direction: column;
        line-height: initial;
        padding: 0 12px 0 8px;
        .user-name {
            font-size: 16px;
            font-weight: bold;
        }
        .user-role {
            font-size: 11px;
            margin-left: 3px;
        }
    }

    .anticon-down {
        font-size: 11px;
        border-radius: 12px;
        border: 1px solid #efefef;
        padding: 4px;
    }
`;

interface AuthUserProps {
    onClick: () => void;
}

const AuthUser: FC<AuthUserProps> = ({ onClick }) => {
    const getProfileInfo = useSelector((state: RootState) => state.auth.data);

    return (
        <AuthUserWrapper onClick={onClick}>
            {/* <img className={'user-avatar'} alt={'user-avatar'} src={admin.avatar} /> */}
            <div className="avatar-wrapper">
                <h5>{getProfileInfo?.fullName?.charAt(0)}</h5>
            </div>
            <div className={'name'}>
                <span className={'user-name'}>{getProfileInfo?.fullName}</span>
                <span className={'user-role'}>Admin</span>
            </div>
            <DownOutlined size={18} />
        </AuthUserWrapper>
    );
};

export default AuthUser;
