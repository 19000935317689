import React from 'react';
import { Alert, Button, Popconfirm, Typography } from 'antd';
import { useMutation } from 'react-query';
import { ApiClient } from '../../../../api';

interface IProps {
    gameId?: string;
}

const GenerateApiKey: React.FC<IProps> = ({ gameId }) => {
    const { mutate, isLoading, data } = useMutation('resetApiKey', ApiClient.resetApiKey);

    if (!gameId) {
        return null;
    }

    if (data?.apiKey) {
        return (
            <Alert
                message={
                    <Typography.Text code copyable strong ellipsis>
                        {data.apiKey}
                    </Typography.Text>
                }
                type="success"
                showIcon
            />
        );
    }

    return (
        <Popconfirm
            title={'Are you Sure?'}
            placement="left"
            okText="Yes"
            cancelText="No"
            onConfirm={() => mutate(gameId)}
        >
            <Button loading={isLoading} className="edit-button primary-button">
                Reset Api Key
            </Button>
        </Popconfirm>
    );
};

export default GenerateApiKey;
