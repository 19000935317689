import React from 'react';
import styled from 'styled-components';
import { DrawerLayout } from '../../../Layout';
import TopBar from '../../top-bar';
import styles from './styles';
import NewKnockoutForm from './NewKnockoutForm';

const AddKnockoutWrapper = styled.div`
    ${styles}
`;

const AddKnockout = () => {
    return (
        <DrawerLayout>
            <AddKnockoutWrapper>
                <TopBar title="Knockouts" subtitle={'Add New Knockout'} isAdd={false} path={-1} />
                <NewKnockoutForm />
            </AddKnockoutWrapper>
        </DrawerLayout>
    );
};

export default AddKnockout;
