import React from 'react';
import styled from 'styled-components';
import { Collapse, CollapsePanelProps, Typography } from 'antd';
import styles from '../styles/collapse-test-styles';

import searching from '../../../assets/images/searching.png';

const { Title, Paragraph } = Typography;

const CollapseTestWrapper = styled.div`
    ${styles}
`;

const PanelWrap: React.FC<CollapsePanelProps & { color: string; level: number }> = (props) => {
    return <Collapse.Panel {...props} />;
};

const Panel = styled(PanelWrap)`
    background: ${(props) => props.color};
    z-index: ${(props) => props.level};
`;

const CollapseTest = () => {
    const comp = React.useMemo(() => {
        return (
            <>
                <Collapse accordion defaultActiveKey={1} ghost expandIcon={() => null}>
                    <Panel color={'#2BD9D9'} level={7} header="01 Join the fun, Everyone is a winner" key="1">
                        <div className="panel-header">
                            <Title>01 Join the fun, Everyone is a winner</Title>
                            <img src={searching} className="panel-image" alt={'search'} />
                        </div>
                        <Paragraph>
                            There’s something for everyone on Weplay Arcade. Action, educational, sports, puzzle games,
                            brain teasers, magical quests and more
                        </Paragraph>
                    </Panel>
                    <Panel color={'#0EB665'} level={6} header="02 Discover New games" key="2" id="second">
                        <div className="panel-header">
                            <Title>02 Discover New games</Title>
                            <img src={searching} className="panel-image" alt={'search'} />
                        </div>
                        <Paragraph>
                            We create a personalized game library built just for you. Find a game you like, and start
                            playing. The longer you play, the more Units you earn.
                        </Paragraph>
                    </Panel>
                    <Panel color={'#082D2A'} level={5} header="03 Win Prices and Redeem Rewards" key="3">
                        <div className="panel-header">
                            <Title>03 Win Prices and Redeem Rewards</Title>
                            <img src={searching} className="panel-image" alt={'search'} />
                        </div>
                        <Paragraph>Play and collect units then redeem your units for awesome rewards</Paragraph>
                    </Panel>
                    <Panel color={'#f7b131'} level={4} header="04 Play with friends and make new ones" key="4">
                        <div className="panel-header">
                            <Title>04 Play with friends and make new ones</Title>
                            <img src={searching} className="panel-image" alt={'search'} />
                        </div>
                        <Paragraph>
                            Weplay social experience allows you to see what your friends are playing, compete with them
                            on the leaderboards, or just have a chat.
                        </Paragraph>
                    </Panel>
                    <Panel color={'#f83608'} level={3} header="05 Contests and Tournaments " key="5">
                        <div className="panel-header">
                            <Title>05 Contests and Tournaments </Title>
                            <img src={searching} className="panel-image" alt={'search'} />
                        </div>
                        <Paragraph>Compete against friends and other users and win prizes and rewards</Paragraph>
                    </Panel>
                </Collapse>
            </>
        );
    }, []);

    return <CollapseTestWrapper>{comp}</CollapseTestWrapper>;
};

export default CollapseTest;
