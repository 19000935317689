import { css } from 'styled-components';

const style = () => css`
    .profile-container {
        background-color: #fff;
        /* padding: 10px; */
        border-radius: 5px;
        padding: 15px 20px;
        min-height: 300px;
    }
    .profile-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            margin: 0;
            font-size: 17px;
            color: rgba(0, 0, 0, 0.7);
        }
        h4 {
            margin: 0;
            text-transform: capitalize;
            margin-top: 5px;
        }
    }
    .avatar {
        width: 80px;
        height: 80px;
        border-radius: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #111;
        h5 {
            font-size: 35px;
            margin: 0;
            text-transform: uppercase;
        }
    }
    .update-btn {
        height: 40px !important;
        padding: 0 15px !important;
        /* margin-left: 16px; */
        border-radius: 5px;
        background-color: #2980b9;
        border: 1px solid #2980b9;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
        display: none;
        content: '';
    }
`;

export default style();
