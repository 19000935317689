import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { Col, Form, Input, Modal, Row } from 'antd';
import { API, ApiClient } from '../../../../api';
import UploadContainer from '../../../../containers/upload';
import { mutationError } from '../../../../utils/helper';

interface ModalProps {
    visible: boolean;
    setVisible: any;
}

const AddDeveloperModal: FC<ModalProps> = ({ visible, setVisible }) => {
    const [form] = Form.useForm();

    const { isLoading, mutate } = useMutation(ApiClient.addDeveloper, {
        onSuccess: function () {
            ApiClient.showSuccess('Developer successfully added');
            setVisible(false);
        },
    });

    function onCreate(values: any) {
        try {
            const submitData = { ...values };
            submitData['avatar'] = API.getImageUrl(values?.avatar[0]?.url || values?.avatar[0]?.response?.url);
            mutate(submitData);
        } catch (error: any) {
            mutationError(error);
        }
    }

    return (
        <Form form={form} layout="vertical" preserve={false} requiredMark={false} onFinish={onCreate}>
            <Modal
                getContainer={false}
                visible={visible}
                title="Add Developer"
                onCancel={() => setVisible(false)}
                centered
                confirmLoading={isLoading}
                destroyOnClose
                okText="Add"
                style={{ padding: 0 }}
                bodyStyle={{ maxHeight: '90vh' }}
                onOk={form.submit}
                okButtonProps={{
                    style: {
                        background: '#1d1d1f',
                        color: '#FFF',
                        fontSize: '13px',
                        borderRadius: '5px',
                        outline: 'none',
                        border: '1px solid #272729',
                        height: 'auto',
                        margin: '5px',
                    },
                }}
                cancelButtonProps={{
                    style: {
                        background: '#FFF',
                        color: '#E74C3C',
                        fontSize: '13px',
                        borderRadius: '5px',
                        outline: 'none',
                        border: '1px solid #E74C3C',
                        width: 'auto',
                        height: 'auto',
                        margin: '5px',
                    },
                }}
            >
                <Row gutter={16}>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            label={'Full Name'}
                            name="fullName"
                            rules={[{ required: true, message: 'Please enter full name' }]}
                        >
                            <Input placeholder={'Enter Full Name'} className={'weplay-input'} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24} className="gutter-row">
                        <UploadContainer
                            label={'User Avatar'}
                            formItemName={'avatar'}
                            fileName="file"
                            isMultiple={false}
                            maxCount={1}
                        />
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default AddDeveloperModal;
