import React from 'react';
import { Input, Switch } from 'antd';
import { titleCase } from '../../../utils/helper';
import moment from 'moment';

export const columns = (toggle: any, updateValue: any) => {
    return [
        {
            key: 1,
            dataIndex: 'name',
            title: 'Feature Name',
            render: (val: any) => titleCase(val ? val.split('_').join(' ') : '-'),
        },
        {
            key: 2,
            dataIndex: 'description',
            title: 'Description',
            render: (val: any) => (val ? val : '-'),
        },
        {
            key: 4,
            dataIndex: 'value',
            title: 'Value',
            render: (val: any, record: any) => {
                return (
                    <Input
                        value={val}
                        onChange={(e) => updateValue(e.target.value, record)}
                        style={{ width: '100px', textAlign: 'center', borderRadius: '5px' }}
                    />
                );
            },
        },
        {
            key: 3,
            dataIndex: 'enabled',
            title: 'Status',
            render: (value: any, record: any) => {
                return <Switch checked={value} onChange={() => toggle(record)} />;
            },
        },
        {
            key: 5,
            dataIndex: 'updatedAt',
            title: 'Last Updated At',
            render: (value: any) => {
                return value ? moment(value).format('DD/MM/YY hh:mm A') : '-';
            },
        },
    ];
};
