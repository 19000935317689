import React from 'react';
import styled from 'styled-components';
import { Card, Col, Row, Skeleton, Table, Typography } from 'antd';
import { GiMoneyStack } from 'react-icons/gi';
import { useQuery } from 'react-query';
import { ApiClient } from '../../api';
import { GameDetails } from '../common';

interface IProps {
    data: RoomDetails;
}

const Wrapper = styled.div`
    h2.ant-typography {
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 24px;
        text-transform: capitalize;
    }

    .ant-card-head-title {
        padding: 8px 0;
    }

    .game-details,
    .data-card {
        box-shadow: rgb(231 231 233 / 60%) 5px 8px 24px 5px;
    }

    .data-card {
        margin-bottom: 20px;
        border: none;
    }

    .fees {
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 3px;
    }

    .fees-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    h5.ant-typography {
        color: rgba(0, 0, 0, 0.7);
    }

    .ant-card-body {
        padding: 15px 15px 0 24px;
    }

    .ant-table-wrapper {
        margin-top: 20px;
    }

    .ant-table-title {
        padding-left: 16px;
        padding-top: 0;
        padding-bottom: 0;
    }

    h4.ant-typography {
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.75);
        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
    }

    .description {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.67);
        margin-top: 0.5rem;
    }
`;
const { Title } = Typography;

const columns = [
    {
        title: 'Position',
        dataIndex: 'position',
    },
    {
        title: 'From',
        dataIndex: 'from',
    },
    {
        title: 'To',
        dataIndex: 'to',
    },
    {
        title: 'Coins',
        dataIndex: 'coin',
    },
    {
        title: 'Points',
        dataIndex: 'points',
    },
];

const RoomDetails: React.FC<IProps> = ({ data }) => {
    const { data: gameData, isFetching } = useQuery(['game-detail', data.gameId], ApiClient.getGameDetails, {
        enabled: Boolean(data.gameId),
        refetchOnWindowFocus: false,
        refetchInterval: false,
    });

    return (
        <Wrapper>
            <Row gutter={10}>
                <Col span={12}>
                    <Card title={<Title level={2}>{data?.name}</Title>} bordered={false} className="data-card">
                        <div className="fees-container">
                            Fees:{' '}
                            <span className="fees">
                                {data?.fee} <GiMoneyStack color="green" fontSize={20} />{' '}
                            </span>
                        </div>
                        <div className="fees-container">
                            Platform Fees:{' '}
                            <span className="fees">
                                {data?.platformFee} <GiMoneyStack color="green" fontSize={20} />{' '}
                            </span>
                        </div>
                        <h6 className="description">{data?.description}</h6>
                    </Card>
                </Col>

                <Col span={12}>
                    <Skeleton loading={isFetching}>
                        <GameDetails item={gameData} disabled />
                    </Skeleton>
                </Col>
            </Row>

            <Table
                title={() => <Title level={4}>Rewards</Title>}
                dataSource={data.rewards}
                columns={columns}
                pagination={false}
            />
        </Wrapper>
    );
};

export default RoomDetails;
