import React, { useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ApiClient } from '../../api';
import Categories from '../../components/category';
import { Columns } from './data';
import { EditCategoryFormContainer, EditCategoryFormRef, NewCategoryFormContainer, NewCategoryFormRef } from './forms';

const CategoryContainer = () => {
    const newCategoryRef = useRef<NewCategoryFormRef>(null);
    const editCategoryRef = useRef<EditCategoryFormRef>(null);

    const queryClient = useQueryClient();

    const [search, setSearch] = useState('');

    const { mutateAsync } = useMutation(ApiClient.deleteCategory, {
        onMutate: async () => {
            await queryClient.cancelQueries('categories');
        },
        onSuccess: (_, categoryId: string) => {
            queryClient.setQueryData('categories', (old: any) => {
                return old?.filter((value) => value.id !== categoryId);
            });
            ApiClient.showSuccess('Category successfully deleted');
        },
    });

    const { data: categories, isFetching } = useQuery<Category[]>('categories', ApiClient.getCategories, {
        refetchOnWindowFocus: false,
        initialData: [],
    });

    const data = useMemo(() => {
        const sRegex = new RegExp(search, 'gi');
        return categories?.filter((value) => sRegex.test(value?.name));
    }, [search, categories]);

    const onEditPress = (record: any) => editCategoryRef.current?.openModal(record);

    const onAddPress = () => newCategoryRef.current?.openModal();

    const onDelete = (categoryId: string) => mutateAsync(categoryId).catch(null);

    return (
        <Categories
            columns={Columns(onDelete, onEditPress)}
            data={data}
            search={search}
            setSearch={setSearch}
            loading={isFetching}
            onAddClick={onAddPress}
        >
            <NewCategoryFormContainer ref={newCategoryRef} />
            <EditCategoryFormContainer ref={editCategoryRef} />
        </Categories>
    );
};

export default CategoryContainer;
