import React, { useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import GameRooms from '../../components/game-rooms';
import { EditRoomFormContainer, EditRoomFormRef, NewRoomFormContainer, NewRoomFormRef } from './forms';
import GameRoomDetailContainer, { RoomDetailRef } from './GameRoomDetailContainer';
import { ApiClient } from '../../api';
import { Columns } from './data';

const RoomsContainer = () => {
    const queryClient = useQueryClient();
    const newRoomRef = useRef<NewRoomFormRef>(null);
    const editRoomRef = useRef<EditRoomFormRef>(null);
    const detailRef = useRef<RoomDetailRef>(null);
    const [search, setSearch] = useState('');

    const mutation = useMutation(ApiClient.deleteGameRooms, {
        onMutate: async () => {
            await queryClient.cancelQueries('game-rooms');
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries('game-rooms');
            ApiClient.showSuccess('Room successfully deleted');
        },
    });

    const { data, isFetching } = useInfiniteQuery('game-rooms', ApiClient.getGameRooms, {
        refetchOnWindowFocus: false,
    });

    const roomList = useMemo(() => {
        if (data) {
            return data?.pages.map((page) => page.data?.map((d) => d.rooms)).flat(2);
        }
        return [];
    }, [data]);

    const onDelete = (id: string) => mutation.mutateAsync(id).catch(null);

    const onEdit = (record: any) => editRoomRef?.current?.openModal?.(record);

    const onAddClick = () => newRoomRef.current?.openModal();

    const onDetailClick = (record: any) => detailRef.current?.openModal(record);

    return (
        <GameRooms
            columns={Columns(onDelete, onEdit, onDetailClick)}
            data={roomList}
            search={search}
            setSearch={setSearch}
            onAddClick={onAddClick}
            loading={isFetching}
        >
            <NewRoomFormContainer ref={newRoomRef} />
            <EditRoomFormContainer ref={editRoomRef} />
            <GameRoomDetailContainer ref={detailRef} />
        </GameRooms>
    );
};

export default RoomsContainer;
