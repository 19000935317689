import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { FireFilled } from '@ant-design/icons';
import { COLORS } from '../../../../config/theme';

const { Text } = Typography;

const CategoryCardWrapper = styled.div`
    position: relative;
    box-shadow: ${(props) => props.theme[COLORS.CARD_SHADOW]};
    border-radius: 16px;
    margin: 8px 16px;
    padding: 1%;
    min-width: 280px;
    /* height: 128px; */
    min-height: 128px;
    display: flex;
    flex-direction: column;

    @media (max-width: 540px) {
        min-width: 200px;
        min-height: 100px;
        padding: 1.5%;
    }

    button {
        background: #1e2a3b !important;
        border: none !important;
        align-self: flex-end;

        .anticon {
            color: #e2e8f0;
        }
    }

    .title {
        font-size: 24px;
        font-weight: 400;
        color: #082d2a;
        padding: 0 8px;
        flex: 1;
        @media (max-width: 540px) {
            font-size: 20px;
        }
    }
    .popular {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        background: #082d2a;
        border-radius: 32px;

        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #ef4444;
        width: max-content;

        .anticon {
            margin-right: 8px;
            margin-left: 2px;
        }
    }
`;

interface IProps {
    data: CategoriesEntity;
    index?: number;
}

const colors = ['#E5FFAE', '#FFF2AF', '#FFF1F1', '#EBFCFF'];

const CategoryCard: React.FC<IProps> = ({ data, index = 0 }) => {
    return (
        <CategoryCardWrapper className="card" style={{ backgroundColor: colors[index % 4] }}>
            <Text className="title">{data.name}</Text>
            {data.name === 'Board' && (
                <div className="popular">
                    <FireFilled />
                    Super Popular
                </div>
            )}
        </CategoryCardWrapper>
    );
};

export default CategoryCard;
