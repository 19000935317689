import { QueryFunctionContext } from 'react-query';
// import omit from 'lodash/omit';
import { API } from '../api';

// export function getGameRooms({ signal, pageParam = 1 }: QueryFunctionContext) {
//     return API.get(API.PATH.getRooms, {
//         signal,
//         params: {
//             page: pageParam,
//         },
//     }).then((res) => res.data);
// }

export function getRatingStats({ signal, queryKey }: QueryFunctionContext) {
    const [, gameId] = queryKey as string[];
    return API.get(API.PATH.getRatingStats(gameId), { signal }).then((res) => res.data);
}

export function getPendingReviews({ signal, queryKey }: QueryFunctionContext) {
    const [, gameId] = queryKey as string[];
    return API.get(API.PATH.getReviews(gameId), {
        params: {
            approved: false,
        },
        signal: signal,
    }).then((response) => response.data);
}

export function getApprovedReviews({ signal, queryKey }: QueryFunctionContext) {
    const [, gameId] = queryKey as string[];
    return API.get(API.PATH.getReviews(gameId), {
        params: {
            approved: true,
        },
        signal: signal,
    }).then((response) => response.data);
}

// export function createGameRooms(data: any) {
//     return API.post(API.PATH.createRoom, data).then((res) => res.data);
// }
//

export function approveReview(reviewId: string) {
    return API.patch(API.PATH.approveReview(reviewId)).then((res) => res.data);
}

export function hideReview(reviewId: string) {
    return API.patch(API.PATH.hideReview(reviewId)).then((res) => res.data);
}

//
// export function deleteGameRooms(gameId: string) {
//     return API.delete(API.PATH.deleteRoom(gameId)).then((res) => res.data);
// }
