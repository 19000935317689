import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { Table } from 'antd';
import styles from './styles';
import { columns } from './data';
import { ApiClient } from '../../../api';
import queryClient from '../../../store/queryClient';

const FeatureWrapper = styled.div`
    ${styles}
`;
const FeatureManagement = () => {
    const [loading, setLoading] = React.useState(false);
    const [updateValue, setUpdateValue] = React.useState<any>({});
    const { data, isLoading } = useQuery('featureFlags', ApiClient.getFeatureFlags);

    const { mutate: enableFeature } = useMutation(ApiClient.enableFeatureFlag, {
        onSuccess: function (d: any) {
            const newData = data.map((item: any) => {
                if (item.id === d.id) {
                    return { ...item, enabled: true };
                }
                return item;
            });
            queryClient.setQueryData('featureFlags', newData);
            ApiClient.showSuccess('Feature successfully enabled');
            setLoading(false);
        },
        onError: function () {
            ApiClient.showError('Something went wrong');
            setLoading(false);
        },
    });

    const { mutate: disableFeature } = useMutation(ApiClient.disableFeatureFlag, {
        onSuccess: function (d: any) {
            const newData = data.map((item: any) => {
                if (item.id === d.id) {
                    return { ...item, enabled: false };
                }
                return item;
            });
            queryClient.setQueryData('featureFlags', newData);
            ApiClient.showSuccess('Feature successfully disabled');
            setLoading(false);
        },
        onError: function () {
            ApiClient.showError('Something went wrong');
            setLoading(false);
        },
    });

    const onToggle = React.useCallback((record: any) => {
        setLoading(true);
        if (record.enabled) {
            disableFeature(record.id);
        } else {
            enableFeature(record.id);
        }
    }, []);

    const callAPIToUpdateValue = () => {
        setLoading(true);
        ApiClient.updateFeatureFlag({
            value: updateValue.value,
            id: updateValue.id,
        })
            .then(() => {
                ApiClient.showSuccess('Feature successfully updated');
                setUpdateValue({});
                setLoading(false);
            })
            .catch(() => {
                ApiClient.showError('Something went wrong');
                setUpdateValue({});
                setLoading(false);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (updateValue.id) {
                callAPIToUpdateValue();
            }
        }, 1500);
        return () => clearTimeout(timer);
    }, [updateValue]);

    const updateInputValue = (value: any, record: any) => {
        setUpdateValue({ ...record, value });
        queryClient.setQueryData('featureFlags', (old: any) => {
            return old.map((item: any) => {
                if (item.id === record.id) {
                    return { ...item, value };
                }
                return item;
            });
        });
    };

    return (
        <FeatureWrapper>
            <Table
                columns={columns(onToggle, updateInputValue)}
                loading={isLoading || loading}
                dataSource={data}
                pagination={false}
            />
        </FeatureWrapper>
    );
};

export default FeatureManagement;
