import React, { FC } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { CheckCircleOutlined, PaperClipOutlined } from '@ant-design/icons';

import ApprovedReviews from './components/approved-reviews';
import PendingReviews from './components/pending-reviews';
import styles from './styles';
import { ApiClient } from '../../../../api';

const { TabPane } = Tabs;

const ReviewsWrapper = styled.div`
    ${styles}
`;

interface Props {
    gameId?: string;
}

const Review: FC<Props> = ({ gameId }) => {
    const { data: approvedData } = useQuery<Pagination<Review>>(
        ['approvedReview', gameId],
        ApiClient.getApprovedReviews,
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            enabled: Boolean(gameId),
        },
    );

    const { data: pendingData } = useQuery<Pagination<Review>>(['pendingReview', gameId], ApiClient.getPendingReviews, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(gameId),
    });

    if (!gameId) {
        return null;
    }

    return (
        <ReviewsWrapper>
            <div className="reviews-container">
                <h1 className="rating-heading">Reviews</h1>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane
                        tab={
                            <span className="approved-tab" style={{ color: 'rgb(141, 230, 58)' }}>
                                <CheckCircleOutlined />
                                Approved ({approvedData?.total || 0})
                            </span>
                        }
                        key="1"
                    >
                        <ApprovedReviews gameId={gameId} reviews={approvedData} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span className="pending-tab" style={{ color: 'orangered' }}>
                                <PaperClipOutlined />
                                Pending ({pendingData?.total || 0})
                            </span>
                        }
                        key="2"
                    >
                        <PendingReviews gameId={gameId} reviews={pendingData} />
                    </TabPane>
                </Tabs>
            </div>
        </ReviewsWrapper>
    );
};

export default Review;
