import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Row } from 'antd';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from '../../router';
import styles from './styles';

const TopBarWrapper = styled.div`
    ${styles}
`;
interface TopBarProps {
    title: string;
    subtitle?: any;
    isAdd?: boolean;
    path?: any;
    isSearch?: boolean;
    search?: string;
    onChange?: any;
    placeholder?: string;
}
const TopBar: FC<TopBarProps> = ({ subtitle, isAdd, isSearch, search, onChange, placeholder }) => {
    const navigate = useNavigate();
    return (
        <TopBarWrapper>
            <Row className="top-bar">
                <span className={'sub-title'}> {subtitle}</span>
                <div className="search-container">
                    {isSearch && (
                        <Input
                            value={search}
                            allowClear
                            placeholder={placeholder}
                            onChange={onChange}
                            prefix={<SearchOutlined />}
                        />
                    )}
                    {isAdd ? (
                        <Button
                            className={'primary-button add-button'}
                            icon={<PlusOutlined />}
                            onClick={() => navigate(ROUTES.ADD_GAMES)}
                        >
                            Add Game
                        </Button>
                    ) : null}
                </div>
            </Row>
        </TopBarWrapper>
    );
};

export default TopBar;
