import { PURGE } from 'redux-persist';
import {
    IS_USER_SIGNING_IN,
    USER_SIGN_IN_SUCCESS,
    USER_SIGN_IN_FAILURE,
    USER_LOG_OUT,
    AuthReducer,
    GET_PROFILE,
    IS_GETTING_PROFILE,
} from '../types';

const initialState: AuthReducer = {
    loading: false,
    data: {
        fullName: '',
        email: '',
    },
    authenticated: false,
};

const authReducer = (state: AuthReducer = initialState, action: any) => {
    switch (action.type) {
        case IS_USER_SIGNING_IN:
            return {
                ...state,
                loading: true,
            };
        case USER_SIGN_IN_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                authenticated: true,
            };
        case USER_SIGN_IN_FAILURE:
            return {
                loading: false,
                data: {},
                authenticated: false,
            };
        case IS_GETTING_PROFILE:
            return {
                ...state,
                loading: true,
            };
        case GET_PROFILE:
            return {
                loading: false,
                data: action.payload,
                authenticated: true,
            };
        case USER_LOG_OUT:
            return {
                ...initialState,
            };
        case PURGE:
            return {};
        default:
            return state;
    }
};

export default authReducer;
