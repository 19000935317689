import React from 'react';
import { message, notification, Upload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';

import { API, axios } from '../../api';
import upload from '../../assets/icons/upload-cloud.svg';
import ReOrderImages from './ReOrderImages';

const UploadDrag: React.FC<
    DraggerProps & {
        fileName?: string;
        reOrderImage?: boolean;
    }
> = ({ fileName, reOrderImage, ...props }) => {
    const uploadFile = {
        name: fileName ?? 'file',
        action: API.baseUrl + `/files`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info?.file?.response?.url, info.fileList);
            }
            if (status === 'done') {
                message.success(`File uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`File upload failed.`);
            }
        },
    };

    const beforeUpload = (file) => {
        const isLt25M = file.size / 1024 / 1024 < 10;
        if (!isLt25M) {
            notification.error({
                message: 'Error',
                description: 'Document must smaller than 10 MB!',
            });
            return false;
        }
        return true;
    };

    const listType: DraggerProps['listType'] = reOrderImage === true ? 'picture' : 'picture';

    return (
        <React.Fragment>
            <Upload.Dragger
                {...uploadFile}
                listType={listType}
                headers={{
                    Authorization: `${(axios.defaults.headers as any)?.Authorization}`,
                }}
                beforeUpload={beforeUpload}
                showUploadList={!reOrderImage}
                {...props}
            >
                <img src={upload} alt="upload" width={20} style={{ marginRight: 5, color: '#2980b9' }} />
                <span className="ant-upload-text">Drag your file here or browse to upload</span>
            </Upload.Dragger>
            {reOrderImage == true ? (
                <ReOrderImages fileList={props.fileList} onChange={props.onChange} listType={listType} />
            ) : null}
        </React.Fragment>
    );
};

export default UploadDrag;
