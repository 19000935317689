import React from 'react';
import { useParams } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import { ApiClient } from '../../../api';
import UserTransactions from '../../../components/users/view-user/UserTransactions';
import Columns from '../data/transactionColumn';

const TransactionContainer = () => {
    const userId = useParams<'userId'>()?.userId;
    const { data, isFetching, fetchNextPage } = useInfiniteQuery<Pagination<Transaction>>(
        ['transactions', userId],
        ApiClient.getUserTransactions,
        {
            refetchOnWindowFocus: false,
        },
    );

    return (
        <UserTransactions
            data={data?.pages[0]}
            loading={isFetching}
            columns={Columns()}
            fetchPage={(page) => fetchNextPage({ pageParam: page })}
        />
    );
};

export default TransactionContainer;
