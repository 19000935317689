import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { Avatar, Badge, Button, Carousel, Col, Image, Popconfirm, Rate, Row, Skeleton, Typography } from 'antd';

import styles from './styles';
import { DrawerLayout } from '../../../../Layout';
import TopBar from '../../../top-bar';
import { API, ApiClient } from '../../../../api';
import Delete from '../../../../assets/icons/trash-2.svg';
import { ROUTES } from '../../../../router';
import TotalRating from './components/TotalRating';
import Review from '../review';
import GenerateApiKey from './GenerateApiKey';
import { mutationError } from '../../../../utils/helper';

const ViewGameWrapper = styled.div`
    ${styles}
`;

const { Title } = Typography;

const ViewGame = () => {
    const navigate = useNavigate();
    const gameId = useParams<'gameId'>()?.gameId;

    const { data, isFetching } = useQuery<GameDetails>(['game', gameId], ApiClient.getGameDetails, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    const { data: ratingData } = useQuery<ReviewAggregate>(['reviews', gameId], ApiClient.getRatingStats, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    function editGame(id) {
        navigate(ROUTES.EDIT_GAMES.split(':').shift() + `${id}`, {
            state: { gameId: id, gameData: data },
        });
    }

    const onDelete = async (id?: string) => {
        try {
            await API.delete(API.PATH.deleteGame(id as any));
            ApiClient.showSuccess('Game successfully deleted');
            navigate(ROUTES.GAMES);
        } catch (e: any) {
            mutationError(e);
        }
    };

    return (
        <DrawerLayout>
            <TopBar
                title="Game"
                subtitle={data?.title !== undefined ? `${data?.title}` : 'Title'}
                isAdd={false}
                path={'/games'}
            />
            <ViewGameWrapper>
                <Skeleton loading={isFetching}>
                    <div className="container">
                        <Row gutter={10}>
                            <Col
                                span={24}
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}
                            >
                                <GenerateApiKey gameId={gameId} />
                                <Button className="edit-button primary-button" onClick={() => editGame(data?.id)}>
                                    Edit
                                </Button>
                                <Popconfirm
                                    title={'Are you Sure?'}
                                    placement="bottomRight"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => onDelete(data?.id)}
                                >
                                    <img src={Delete} alt="delete" className="delete-icon" />
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Row gutter={16} justify="center" style={{ marginTop: 25 }}>
                            <Col span={12}>
                                <div className="game-info">
                                    <Image
                                        width={200}
                                        className="game-thumbnail"
                                        src={API.getImageUrl(data?.thumbnail)}
                                        preview={false}
                                    />
                                    <div className="content_info">
                                        <Title level={3}>{data?.title}</Title>
                                        <div className="rating">
                                            {ratingData?.rating?.average}{' '}
                                            <Rate value={Number(ratingData?.rating?.average)} allowHalf disabled />
                                        </div>
                                        {data?.categories.map((category, ind) => (
                                            <Badge
                                                count={category.name}
                                                key={ind}
                                                style={{
                                                    backgroundColor: '#52c41a',
                                                    marginRight: '2px',
                                                    marginTop: '7px',
                                                    marginLeft: '2px',
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="developer_info" style={{ marginTop: '15px' }}>
                                    <Title level={5}>Developer:</Title>

                                    <Avatar
                                        src={
                                            <Image
                                                src={API.getImageUrl(data?.createdBy?.avatar)}
                                                style={{ objectFit: 'cover', height: 40 }}
                                                preview={false}
                                            />
                                        }
                                    />
                                    <span className="developer-name">{data?.createdBy?.fullName}</span>
                                </div>
                                <h6 className="description">{data?.description}</h6>
                            </Col>
                            <Col span={12}>
                                <Carousel
                                    autoplay
                                    arrows
                                    nextArrow={<IoIosArrowForward />}
                                    prevArrow={<IoIosArrowBack />}
                                    effect="fade"
                                    adaptiveHeight
                                    className="carousal"
                                >
                                    {data?.images?.map((image, ind) => {
                                        return <img key={ind} src={API.getImageUrl(image)} alt="carousal-img" />;
                                    })}
                                </Carousel>
                            </Col>
                        </Row>

                        <div className="rating_container">
                            <h1 className="rating-heading">Ratings and Review</h1>
                            <TotalRating data={ratingData} />
                        </div>
                        <Row>
                            <Col span={16}>
                                <Review gameId={data?.id} />
                            </Col>
                        </Row>
                    </div>
                </Skeleton>
            </ViewGameWrapper>
        </DrawerLayout>
    );
};

export default ViewGame;
