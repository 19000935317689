import { css } from 'styled-components';

const style = () => css`
    width: 100%;
    margin: 0 auto;

    .top-bar {
        margin: 0 0 10px;
        align-items: center;

        .back-arrow {
            width: 25px;
            height: 25px;
            cursor: pointer;
            /* color: #777c93; */
            color: #0984e3;

            &:hover {
                opacity: 0.8;
            }
        }

        .sub-title {
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #6c7392;
            text-transform: capitalize;
        }

        .title {
            margin: 0 0 0 5px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.8);
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }

            span {
                color: #e5e5e5;
                font-weight: 500;
            }
        }

        .line_sep {
            margin-left: 5px;
            margin-right: 5px;
        }

        .close {
            margin-left: auto;
            cursor: pointer;
            color: #6c7392;
        }

        .add-button {
            height: 40px !important;
            padding: 0 15px !important;
            margin-left: 16px;
            border-radius: 12px;
            background: rgba(0, 0, 0, 0.8);
            border: 1px solid rgba(0, 0, 0, 0.8);
        }
    }

    .ant-input-affix-wrapper {
        height: 40px;
        border-radius: 16px;
        max-width: 250px;

        .ant-input-prefix {
            margin-right: 6px;
        }
    }

    .search-container {
        margin-left: auto;
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }

    .divider {
        margin-top: 15px;
        border-top: 1px solid #e5e5e5;
        margin-bottom: 24px;
    }
`;

export default style();
