import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Form, Skeleton } from 'antd';
import moment from 'moment';
import { DrawerLayout } from '../../../Layout';
import TopBar from '../../top-bar';
import styles from '../new-knockout/styles';
import NewKnockoutForm from '../new-knockout/NewKnockoutForm';
import { ApiClient } from '../../../api';

const AddKnockoutWrapper = styled.div`
    ${styles}
`;

const EditKnockout = () => {
    const knockoutId = useParams<'knockoutId'>()?.knockoutId;
    const [form] = Form.useForm();

    const { isLoading } = useQuery(['Knockout', knockoutId], ApiClient.getKnockoutDetail, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            form.setFieldsValue({
                ...response,
                qualifierStart: moment(response.qualifierStart),
                qualifierEnd: moment(response.qualifierEnd),
                semiFinalStart: moment(response.semiFinalStart),
                finalStart: moment(response.finalStart),
            });
        },
    });

    return (
        <DrawerLayout>
            <AddKnockoutWrapper>
                <TopBar title="Knockouts" subtitle={'Edit Knockout'} isAdd={false} path={-1} />
                <Skeleton loading={isLoading}>
                    <NewKnockoutForm form={form} knockoutId={knockoutId} buttonTitle={'Edit Knockout'} />
                </Skeleton>
            </AddKnockoutWrapper>
        </DrawerLayout>
    );
};

export default EditKnockout;
