import React from 'react';
import omit from 'lodash/omit';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row } from 'antd';
import styles from './styles/knockout-form';
import { RewardList, SelectGame } from '../../common';
import { API, ApiClient } from '../../../api';
import { mutationError } from '../../../utils/helper';

const NewKnockoutFormWrapper = styled.div`
    ${styles}
`;

interface IProps {
    form?: FormInstance;
    buttonTitle?: string;
    knockoutId?: string;
}

const NewKnockoutForm: React.FC<IProps> = ({ form: _form, buttonTitle, knockoutId }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);

    const [form] = Form.useForm(_form);

    const onFinish = React.useCallback(async (values: any) => {
        setLoading(true);
        try {
            await API.post(API.PATH.createKnockout, values);
            setLoading(false);
            ApiClient.showSuccess('Knockout successfully added');
            navigate(-1);
        } catch (e: any) {
            mutationError(e);
            setLoading(false);
        }
    }, []);

    const onUpdate = React.useCallback(
        async (values: any) => {
            setLoading(true);
            try {
                await API.patch(
                    API.PATH.updateKnockout(knockoutId as string),
                    omit({ ...values }, ['rewards', 'coin', 'start', 'end', 'gameId']),
                );
                setLoading(false);
                ApiClient.showSuccess('Knockout successfully updated');
                navigate(-1);
            } catch (e: any) {
                mutationError(e);
                setLoading(false);
            }
        },
        [knockoutId],
    );

    return (
        <NewKnockoutFormWrapper>
            <Form
                layout="vertical"
                form={form}
                onFinish={knockoutId ? onUpdate : onFinish}
                initialValues={{
                    rewards: [
                        {
                            position: 1,
                            from: 1,
                        },
                    ],
                }}
            >
                <div className="container">
                    <Row gutter={16}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Game'}
                                name="gameId"
                                rules={[{ required: true, message: 'Select knockout game' }]}
                            >
                                <SelectGame
                                    fetchDetails={!!knockoutId}
                                    disabled={!!knockoutId}
                                    placeholder={'Select knockout game'}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={'Knockout Name'}
                                name="name"
                                rules={[{ required: true, message: 'Enter knockout name' }]}
                            >
                                <Input placeholder={'Enter knockout name'} className={'weplay-input'} />
                            </Form.Item>
                            <Form.Item
                                label="Knockout Description"
                                name="description"
                                rules={[{ required: true, message: 'Enter Description' }]}
                            >
                                <Input.TextArea rows={5} placeholder="Enter Description" className={'weplay-input'} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={'Coins Required to join'}
                                name="coin"
                                rules={[{ required: true, message: 'Enter knockout coin value' }]}
                            >
                                <InputNumber
                                    disabled={!!knockoutId}
                                    placeholder={'Enter number of coins for knockout'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={'Maximum wait time for next round'}
                                name="maximumGameTimeInMinutes"
                                rules={[{ required: true, message: 'Enter maximum wait time' }]}
                            >
                                <InputNumber
                                    disabled={!!knockoutId}
                                    placeholder={'Enter maximum wait time for next round'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={'Qualifier Start Date'}
                                name="qualifierStart"
                                rules={[{ required: true, message: 'Select Qualifier start date' }]}
                            >
                                <DatePicker
                                    disabled={!!knockoutId}
                                    showTime
                                    placeholder={'Select Qualifier start date'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    showNow={false}
                                    disabledDate={(current) => current.isBefore(moment())}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={'Qualifier End Date'}
                                name="qualifierEnd"
                                rules={[{ required: true, message: 'Select Qualifier end date' }]}
                            >
                                <DatePicker
                                    disabled={!!knockoutId}
                                    showTime
                                    placeholder={'Select Qualifier end date'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    showNow={false}
                                    disabledDate={(current) => current.isBefore(moment())}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={'Semifinal Start Date'}
                                name="semiFinalStart"
                                rules={[{ required: true, message: 'Select Semifinal start date' }]}
                            >
                                <DatePicker
                                    disabled={!!knockoutId}
                                    showTime
                                    placeholder={'Select Semifinal start date'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    showNow={false}
                                    disabledDate={(current) => current.isBefore(moment())}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={'Final Start Date'}
                                name="finalStart"
                                rules={[{ required: true, message: 'Select Final start date' }]}
                            >
                                <DatePicker
                                    disabled={!!knockoutId}
                                    showTime
                                    placeholder={'Select Final start date'}
                                    className={'weplay-input'}
                                    style={{ width: '100%' }}
                                    showNow={false}
                                    disabledDate={(current) => current.isBefore(moment().add(1, 'day'))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <RewardList disabled={!!knockoutId} title="Knockout Rewards" />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button type="primary" htmlType="submit" className="create-button" loading={loading}>
                                {buttonTitle ?? 'Add Knockout'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </NewKnockoutFormWrapper>
    );
};

export default NewKnockoutForm;
