import React from 'react';
import styled from 'styled-components';
import { Input, Table, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PaginationConfig } from '../../common';
import style from '../styles/user-transaction';
import { useParams } from 'react-router-dom';
import { lpad } from '../../../utils/helper';

const { Title } = Typography;

const TransactionWrapper = styled.div`
    ${style}
`;

interface IProps {
    columns: any;
    data?: Pagination<Transaction>;
    loading: boolean;
    fetchPage?: (pageNumber: number) => void;
}

const UserTransactions: React.FC<IProps> = ({ columns, data, loading, fetchPage }) => {
    const userId = useParams<'userId'>()?.userId as string;

    const [search, setSearch] = React.useState<any>('');

    const result = React.useMemo(() => {
        return data?.data;
    }, [search, data]);

    return (
        <TransactionWrapper>
            <div className={'header'}>
                <Title level={3}>
                    User Transaction <small>({lpad(userId, 8)})</small>
                </Title>
                <div className="flex-1" />
                <div>
                    <Input
                        value={search}
                        allowClear
                        placeholder={'Search transaction'}
                        prefix={<SearchOutlined />}
                        onChange={(event) => {
                            setSearch(event.target.value);
                        }}
                    />
                </div>
            </div>
            <Table
                loading={loading}
                rowKey={'id'}
                columns={columns}
                dataSource={result}
                locale={{ emptyText: 'No Transaction' }}
                pagination={{ ...PaginationConfig, total: data?.total, onChange: fetchPage }}
            />
        </TransactionWrapper>
    );
};

export default UserTransactions;
