import { QueryClient } from 'react-query';
import { mutationError } from '../../utils/helper';
import { ApiClient } from '../../api';

const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onError: mutationError,
        },
    },
});

queryClient.setMutationDefaults('toggleHighlight', {
    mutationFn: ApiClient.toggleHighlight,
    onSuccess: async function (data) {
        await queryClient.cancelQueries('games');
        await queryClient.cancelQueries('highlighted');

        queryClient.invalidateQueries('games');
        queryClient.invalidateQueries('highlighted');

        ApiClient.showSuccess(data.message);
    },
});

queryClient.setMutationDefaults('toggleTrending', {
    mutationFn: ApiClient.toggleTrending,
    onSuccess: async function (data) {
        await queryClient.cancelQueries('games');
        await queryClient.cancelQueries('trending');

        queryClient.invalidateQueries('games');
        queryClient.invalidateQueries('trending');

        ApiClient.showSuccess(data.message);
    },
});

queryClient.setMutationDefaults('approveReview', {
    mutationFn: ApiClient.approveReview,
    onSuccess: async function (data) {
        queryClient.invalidateQueries(['approvedReview']);
        queryClient.invalidateQueries(['pendingReview']);
        queryClient.invalidateQueries(['reviews']);

        ApiClient.showSuccess(data.message);
    },
});

queryClient.setMutationDefaults('hideReview', {
    mutationFn: ApiClient.hideReview,
    onSuccess: async function (data) {
        queryClient.invalidateQueries(['approvedReview']);
        queryClient.invalidateQueries(['pendingReview']);
        queryClient.invalidateQueries(['reviews']);

        ApiClient.showSuccess(data.message);
    },
});

queryClient.setMutationDefaults('createComment', {
    mutationFn: ApiClient.createComment,
    onSuccess: async function (data) {
        queryClient.invalidateQueries(['approvedReview']);

        ApiClient.showSuccess(data.message || 'Reply Added Successfully');
    },
});

queryClient.setMutationDefaults('editComment', {
    mutationFn: ApiClient.editComment,
    onSuccess: async function () {
        queryClient.invalidateQueries(['approvedReview']);

        ApiClient.showSuccess('Reply Updated Successfully');
    },
});

queryClient.setMutationDefaults('deleteComment', {
    mutationFn: ApiClient.deleteComment,
    onSuccess: async function (data) {
        queryClient.invalidateQueries(['approvedReview']);

        ApiClient.showSuccess(data.message);
    },
});

export default queryClient;
