import React, { FC } from 'react';
import { Button, Form, FormProps, Input } from 'antd';
import UploadContainer from '../../../../containers/upload';

interface IProps extends FormProps {
    loading?: boolean;
}

const DeveloperForm: FC<IProps> = ({ children, loading, ...props }) => {
    return (
        <Form layout={'vertical'} {...props}>
            <Form.Item
                label={'Full Name'}
                name="fullName"
                rules={[{ required: true, message: 'Please enter full name' }]}
            >
                <Input placeholder={'Enter Full Name'} className={'weplay-input'} />
            </Form.Item>
            <UploadContainer
                label={'User Avatar'}
                formItemName={'avatar'}
                fileName="file"
                isMultiple={false}
                maxCount={1}
            />
            {children}
            <Form.Item style={{ textAlign: 'right', margin: 0 }}>
                <Button className={'primary-button'} htmlType={'submit'} loading={loading}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

export default DeveloperForm;
