/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import styled from 'styled-components';

const ProgressWrapper = styled.div`
    height: 15px;
    width: 250px;
    background-color: #e0e0de;

    .progress-bar {
        height: 100%;
        border-radius: inherit;
    }
    .progress-content {
        color: #fff;
        font-weight: 600;
    }
`;

interface Props {
    color: any;
    percentage?: any;
}

const ProgressBar: FC<Props> = ({ color, percentage }) => {
    return (
        <ProgressWrapper>
            <div
                className="progress-bar"
                style={{
                    width: `${percentage && percentage}%`,
                    backgroundColor: `${percentage === undefined ? '' : color}`,
                }}
            >
                <span className="progress-content"></span>
            </div>
        </ProgressWrapper>
    );
};

export default ProgressBar;
