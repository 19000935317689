import React, { useCallback } from 'react';
import styled from 'styled-components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, InputNumber, Row } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import styles from './league-reward.styles';

import { updateObject } from '../../../utils/helper';

const LeagueRewardWrapper = styled.div`
    ${styles}
`;

interface FormInput {
    rewards: Array<{
        position: number;
        from: number;
        to?: number;
        coin?: number;
    }>;
}

interface IProps {
    disabled?: boolean;
    title: string;
}

const LeagueReward: React.FC<IProps> = ({ disabled, title }) => {
    const form = Form.useFormInstance<FormInput>();

    const onAddClick = useCallback((fields: FormListFieldData[], operation: FormListOperation) => {
        const currentLength = fields.length;
        const fromVal = form.getFieldValue(['rewards', currentLength - 1, 'from']);
        const toVal = form.getFieldValue(['rewards', currentLength - 1, 'to']);
        if (!toVal) {
            const rewards = form.getFieldValue('rewards');
            updateObject(rewards, [currentLength - 1, 'to'], fromVal);
        }
        operation.add({
            position: currentLength + 1,
            from: !currentLength ? 1 : toVal ? toVal + 1 : fromVal + 1,
        });
    }, []);

    const onRemoveClick = useCallback((fields: FormListFieldData[], operation: FormListOperation, index: number) => {
        if (fields.length != index + 1 || index == 0) {
            return null;
        }

        operation.remove(index);
    }, []);

    const onChangeValue = useCallback((value: number, namePath: InternalNamePath) => {
        if (!value) {
            return;
        }
        const [index] = namePath as [number];
        const toVal = form.getFieldValue(['rewards', index + 1, 'from']);
        if (toVal - 1 != value) {
            const rewards = form.getFieldValue(['rewards']) as FormInput['rewards'];
            const diff = value - toVal + 1;
            rewards.map((reward, idx) => {
                if (idx > index) {
                    if (reward.to) {
                        reward.to += diff;
                    }
                    reward.from += diff;
                }
                return reward;
            });
            form.setFieldsValue({
                rewards,
            });
        }
    }, []);

    return (
        <LeagueRewardWrapper>
            <Form.List name={'rewards'}>
                {(fields, operation) => {
                    return (
                        <React.Fragment>
                            <Divider orientation="left" orientationMargin="0">
                                {title}
                            </Divider>
                            <div className="league-reward">
                                {fields.map((field, index) => {
                                    return (
                                        <Row className="league-field" gutter={16} key={`reward-${index}`}>
                                            <Col span={2}>
                                                <Form.Item
                                                    label={index == 0 ? 'Position' : undefined}
                                                    name={[field.name, 'position']}
                                                    rules={[{ required: true, message: 'Enter position' }]}
                                                >
                                                    <div className="position">{index + 1} .</div>
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    label={index == 0 ? 'From' : undefined}
                                                    name={[field.name, 'from']}
                                                    rules={[{ required: true, message: 'Enter from position' }]}
                                                >
                                                    <InputNumber
                                                        disabled
                                                        placeholder={'from'}
                                                        className={'weplay-input'}
                                                        style={{ width: '100%' }}
                                                        min={0}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    label={index == 0 ? 'To' : undefined}
                                                    name={[field.name, 'to']}
                                                    dependencies={['rewards', field.name + 1, 'to']}
                                                    rules={[
                                                        { required: true, message: 'Enter to position' },
                                                        ({ getFieldValue }) => {
                                                            return {
                                                                validator(_, value) {
                                                                    const checkVal = getFieldValue([
                                                                        'rewards',
                                                                        field.name,
                                                                        'from',
                                                                    ]);
                                                                    if (checkVal > value && value) {
                                                                        return Promise.reject(
                                                                            `value must be greater than ${
                                                                                checkVal - 1
                                                                            }`,
                                                                        );
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            };
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        disabled={disabled}
                                                        placeholder={'to'}
                                                        className={'weplay-input'}
                                                        style={{ width: '100%' }}
                                                        min={0}
                                                        onChange={(val) => onChangeValue(val, [field.name, 'to'])}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    label={index == 0 ? 'Points' : undefined}
                                                    name={[field.name, 'points']}
                                                    rules={[{ required: false, message: 'Enter winning points' }]}
                                                >
                                                    <InputNumber
                                                        disabled={disabled}
                                                        placeholder={'Enter winning points'}
                                                        className={'weplay-input'}
                                                        style={{ width: '100%' }}
                                                        min={0}
                                                        controls={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    label={index == 0 ? 'Coins' : undefined}
                                                    name={[field.name, 'coin']}
                                                    rules={[{ required: true, message: 'Enter reward coins' }]}
                                                >
                                                    <InputNumber
                                                        disabled={disabled}
                                                        placeholder={'Enter reward coins'}
                                                        className={'weplay-input'}
                                                        style={{ width: '100%' }}
                                                        min={0}
                                                    />
                                                </Form.Item>
                                                {!disabled ? (
                                                    <MinusCircleOutlined
                                                        className="remove-action"
                                                        onClick={() => onRemoveClick(fields, operation, index)}
                                                    />
                                                ) : null}
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </div>
                            <div className="buttons">
                                {!disabled ? (
                                    <Button
                                        icon={<PlusOutlined />}
                                        className={'primary-button add-button'}
                                        onClick={() => onAddClick(fields, operation)}
                                    >
                                        Add
                                    </Button>
                                ) : null}
                            </div>
                        </React.Fragment>
                    );
                }}
            </Form.List>
        </LeagueRewardWrapper>
    );
};

export default LeagueReward;
