import React from 'react';
import { List } from 'antd';
import { useInfiniteQuery } from 'react-query';
import { ApiClient } from '../../../../api';
import TopBar from '../../../top-bar';
import PaginationComponent from '../pagination';
import HighlightedCard from './card';

const HighLightedGames: React.FC = () => {
    const { data, isFetching, fetchNextPage } = useInfiniteQuery<Pagination<Game>>(
        'highlighted',
        ApiClient.fetchHighlightedGames,
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            select: ApiClient.selectGame,
            getNextPageParam: ApiClient.getNextPageParam,
        },
    );

    const gameData = data?.pages[0];

    return (
        <>
            <div className="header">
                <TopBar title="HighLighted Games" isAdd={false} path={'/games'} />
            </div>
            <div className="game-container">
                <List
                    loading={isFetching}
                    grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}
                    dataSource={gameData?.data}
                    locale={{ emptyText: 'No Game Highlighted' }}
                    rowKey={(item) => item?.id}
                    renderItem={(item) => (
                        <List.Item>
                            <HighlightedCard data={item} />
                        </List.Item>
                    )}
                    footer={
                        <PaginationComponent
                            total={gameData?.total || 0}
                            pageSize={5}
                            currentPage={gameData?.page || 0}
                            onChange={(page: number) => fetchNextPage({ pageParam: page })}
                        />
                    }
                />
            </div>
        </>
    );
};

export default HighLightedGames;
