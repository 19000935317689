import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import * as Images from '../../../assets/images/gallery';
import * as Images2 from '../../../assets/images/gallery-2';
import { playClickAction } from '../../../utils/helper';

const FirstPageWrapper = styled.div`
    margin-bottom: 30px;
    .page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        .page-content {
            width: 95%;
            display: grid;
            column-gap: 20px;
            row-gap: 20px;
            margin-bottom: 40px;
            .page-text-wrapper{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                flex:1,
                flex-grow: 1;
                padding: 20px 30px;
                border-radius: 30px;
                .heading{
                    font-size: clamp(2.5rem, 6vw, 4.5rem);
                    font-weight: bold;
                }
                .description{
                    font-size: 1.4rem;
                    font-weight: normal;
                }
            }
            .one{
                background-color: #E5FFAE;
                color: #174233;
                grid-area: one;
            }
            .image {
                width: 100%;
                object-fit: cover;
            }
            .two {
                grid-area: two;
            }
            .three {
                grid-area: three;
            }
            .four {
                grid-area: four;
            }
            .five {
                grid-area: five;
            }
            .six {
                grid-area: six;
            }
            .seven {
                grid-area: seven;
                background-color: #FFF1AF;
                color: #F73609;
            }
            .eight {
                grid-area: eight;
            }
            .nine {
                grid-area: nine;
            }
            .ten {
                grid-area: ten;
                background-color: #EAFCFF;
                color: #063B6B;
            }
            .eleven {
                grid-area: eleven;
            }
            .twelve {
                grid-area: twelve;
                background-color: #FFF1F1;
                color: #F73609;
            }
            grid-template-areas:
                "one one one two three four"
                "five six seven seven seven eight"
                "nine ten ten eleven twelve twelve";
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            align-items: center;
            @media (max-width: 900px) {
                grid-template-areas:
                    "one one one two three"
                    "four five six seven seven"
                    "eight nine ten ten ten"
                    "eleven twelve twelve twelve twelve";
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr 1fr 1fr;
            }
            @media (max-width: 768px) {
                column-gap: 10px;
                row-gap: 20px;
                grid-template-areas:
                    "one one one two"
                    "three four five six"
                    "seven seven eight nine"
                    "ten ten ten eleven"
                    "twelve twelve twelve twelve";
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr auto 1fr 1fr 1fr;
            }
            @media (max-width: 600px) {
                grid-template-areas:
                    "one one one"
                    "two three four"
                    "seven seven seven"
                    "five six eight"
                    "ten ten ten"
                    "nine eleven ."
                    "twelve twelve twelve";
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr auto 1fr auto 1fr auto 1fr;
            }
        }
    }
`;

const FirstPage = () => {
    return (
        <FirstPageWrapper id="first-page">
            <div className="page-container">
                <div className="page-content">
                    <div className="page-text-wrapper one">
                        <div className="heading">Competition</div>
                        <div className="description">Live Games and Tournaments</div>
                    </div>
                    <img src={Images2.gallery1} alt="player" className="image two" />
                    <img src={Images2.gallery2} alt="player" className="image three" />
                    <img src={Images2.gallery3} alt="player" className="image four" />
                    <img src={Images2.gallery4} alt="player" className="image five" />
                    <img src={Images2.gallery5} alt="player" className="image six" />
                    <div className="page-text-wrapper seven">
                        <div className="heading">Tournaments</div>
                        <div className="description">Knockout, Leagues and Teams</div>
                    </div>
                    <img src={Images2.gallery6} alt="player" className="image eight" />
                    <img src={Images2.gallery7} alt="player" className="image nine" />
                    <div className="page-text-wrapper ten">
                        <div className="heading">Friends</div>
                        <div className="description">Chat and Play</div>
                    </div>
                    <img src={Images2.gallery8} alt="player" className="image eleven" />
                    <div className="page-text-wrapper twelve">
                        <div className="heading">Rewards</div>
                        <div className="description">Win from Gaming</div>
                    </div>
                </div>
                <Button
                    className="primary-button"
                    style={{ marginTop: '60px', marginBottom: '60px' }}
                    onClick={playClickAction}
                >
                    Start playing now
                    <ArrowRightOutlined />
                </Button>
            </div>
        </FirstPageWrapper>
    );
};

export default FirstPage;
