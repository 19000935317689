import React, { forwardRef } from 'react';
import { Form, Input, Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { GameRoomForm } from '../../../components/game-rooms/components';
import { ApiClient } from '../../../api';

export interface EditRoomFormRef {
    openModal: (values: any) => void;
}

const EditRoomForm = forwardRef<EditRoomFormRef>((_, ref) => {
    const queryClient = useQueryClient();

    const [visible, setVisible] = React.useState(false);

    const [form] = Form.useForm();

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: (values) => {
                form.setFieldsValue(values);
                setVisible(true);
            },
        }),
        [],
    );

    const { mutate: onFinish, isLoading } = useMutation(ApiClient.updateGameRooms, {
        onSuccess: async (newRoom) => {
            await queryClient.cancelQueries('game-rooms');
            await queryClient.invalidateQueries('game-rooms');

            queryClient.setQueryData('game-rooms', (old: any) => {
                console.log(old, newRoom);
                // return old?.map((value) => {
                //     if (value.id == newRoom.id) {
                //         value = newRoom;
                //     }
                //     return value;
                // });

                return old;
            });

            ApiClient.showSuccess('Room successfully updated');
            setVisible(false);
        },
    });

    return (
        <Modal
            destroyOnClose
            title={'Edit room'}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            maskClosable={false}
            width={800}
        >
            <GameRoomForm form={form} onFinish={onFinish} loading={isLoading}>
                <Form.Item hidden name={'id'}>
                    <Input />
                </Form.Item>
            </GameRoomForm>
        </Modal>
    );
});

EditRoomForm.displayName = 'Edit Room Form';

export default EditRoomForm;
