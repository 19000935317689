import React from 'react';
import moment from 'moment';
import { useMutation } from 'react-query';
import { DeleteOutlined, EyeInvisibleOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Comment, Popconfirm, Rate } from 'antd';

import EditModal, { EditModalRef } from './edit-modal';
import ReplyModal from './reply-modal';
import { useImageSource } from '../../../../../../utils/hooks';

interface PropsData {
    data: Review;
}

const ApprovedReviewList: React.FC<PropsData> = ({ data }) => {
    const { mutate, isLoading } = useMutation<any, any, string>('hideReview');
    const { mutate: onDelete } = useMutation<any, any, string>('deleteComment');

    const [visible, setVisible] = React.useState<boolean>(false);

    const editRef = React.useRef<EditModalRef>(null);

    // data for specific reviews:
    const user = data?.user;
    const content = (
        <>
            <span>{data?.content}</span>
        </>
    );

    const avatar = (user?.profileURL && useImageSource(user?.profileURL)) || (
        <div className="custom-avatar">{user?.username?.charAt(0)}</div>
    );
    const actions = [
        <Rate key={'rating-element'} allowHalf disabled value={data?.rating} />,
        <Button
            key="comment-list-reply-to-0"
            className="reply-to primary-button"
            icon={<MessageOutlined />}
            onClick={() => setVisible(true)}
        >
            Reply
        </Button>,
        <Button
            key="comment-list-hide"
            className="hide-review primary-button"
            icon={<EyeInvisibleOutlined />}
            loading={isLoading}
            onClick={(e) => {
                e.stopPropagation();
                mutate(data.id);
            }}
        >
            Hide
        </Button>,
    ];

    // data for comments and admin reply and edit
    const reply = (replies) => (
        <div className="reply-content">
            <h4>{replies}</h4>
        </div>
    );

    function editModalOpen(initialData: CommentsEntity) {
        editRef.current?.openModal(initialData);
    }

    const replyAction = (commentData) => {
        return [
            <span key={'edit'} onClick={() => editModalOpen(commentData)}>
                Edit
            </span>,
            <Popconfirm
                title={'Are you Sure?'}
                placement="topLeft"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete(commentData?.id)}
                key={'del-pop'}
            >
                <DeleteOutlined key={'del-icon'} style={{ color: 'orangered' }} />
            </Popconfirm>,
        ];
    };

    // Parent review component. Admin comment will be displayed as a children for a particular review
    const MainComments = ({ children }) => {
        return (
            <Comment
                content={content}
                datetime={moment(data?.createdAt).format('MMMM D, YYYY')}
                author={user?.username}
                avatar={avatar}
                actions={actions}
            >
                {children}
            </Comment>
        );
    };

    return (
        <>
            <div>
                <MainComments>
                    {data?.comments?.map((val: any) => (
                        <Comment
                            key={val?.id}
                            content={reply(val?.content)}
                            datetime={moment(val?.createdAt).format('MMMM D, YYYY')}
                            actions={replyAction(val)}
                        />
                    ))}
                </MainComments>
            </div>
            <EditModal reviewId={data.id} ref={editRef} />
            <ReplyModal visible={visible} setVisible={setVisible} reviewId={data.id} />
        </>
    );
};

export default ApprovedReviewList;
