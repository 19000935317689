import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';

import styles from './styles/mobile-preview';
import { API } from '../../../api';

const PreviewWrapper = styled.div`
    ${styles}
`;

interface IProps {
    devInfo: any;
}

const GameMobilePreview: FC<IProps> = ({ devInfo }) => {
    const prevData = Form.useWatch([]);

    const ref = useRef<Array<HTMLDivElement | null>>([]);

    const scrollRef = useRef<HTMLDivElement>(null);

    const timerRef = useRef<NodeJS.Timer>();

    const timeOut = useRef<NodeJS.Timeout>();

    const onMouseEnter = () => {
        timeOut.current = setTimeout(() => {
            let a = 0;
            timerRef.current = setInterval(() => {
                const c = ref.current[a];
                a += 1;
                if (c) {
                    // scrollRef.current.clientWidth * a + 8 * a
                    scrollRef.current?.scrollBy({
                        left: scrollRef.current.clientWidth + 8,
                        behavior: 'smooth',
                    });
                } else if (timerRef.current) {
                    clearInterval(timerRef.current);
                }
            }, 1800);
        }, 500);
    };

    const onMouseLeave = () => {
        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        scrollRef.current?.scroll(0, 0);
    };

    const thumbnail = prevData?.thumbnail?.[0] || prevData?.thumbnail;

    const avatar = prevData?.avatar?.[0] || prevData?.createdBy?.avatar;

    const developerInfo = devInfo;

    return (
        <PreviewWrapper>
            <div className={'images'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={scrollRef}>
                {prevData?.images?.map((value, index) => {
                    return (
                        <div className={'image'} key={index} ref={(instance) => (ref.current[index] = instance)}>
                            <img src={API.getImageUrl(value?.response?.url || value?.url || value)} alt={`${index}`} />
                        </div>
                    );
                })}
            </div>
            <div className={'thumbnail'}>
                {thumbnail?.response?.url ? (
                    <img src={API.getImageUrl(thumbnail.response.url)} alt="user-thumbnail" />
                ) : (
                    thumbnail !== undefined && (
                        <img src={API.getImageUrl(thumbnail?.url || thumbnail)} alt="user-thumbnail" />
                    )
                )}
            </div>
            <div className="title">{prevData?.title == undefined ? 'Game Title' : prevData?.title}</div>
            <div className={'developer'}>
                {developerInfo?.avatar !== undefined && (
                    <img src={API.getImageUrl(developerInfo?.avatar)} alt="created-by-avatar" />
                )}
                {avatar?.response?.url ? (
                    <img src={API.getImageUrl(avatar.response.url)} alt="created-by-avatar" />
                ) : (
                    avatar !== undefined && <img src={API.getImageUrl(avatar?.url || avatar)} alt="created-by-avatar" />
                )}

                <div>
                    {developerInfo?.fullName}
                    {prevData?.createdBy?.fullName}
                </div>
            </div>

            <div className="description" style={{ marginTop: 10 }}>
                <p>{prevData?.description}</p>
            </div>
        </PreviewWrapper>
    );
};

export default GameMobilePreview;
