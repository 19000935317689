import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Form, Skeleton } from 'antd';
import moment from 'moment';
import { DrawerLayout } from '../../../Layout';
import TopBar from '../../top-bar';
import styles from '../new-league/styles';
import NewLeagueForm from '../new-league/NewLeagueForm';
import { ApiClient } from '../../../api';

const AddLeagueWrapper = styled.div`
    ${styles}
`;

const EditLeague = () => {
    const leagueId = useParams<'leagueId'>()?.leagueId;
    const [form] = Form.useForm();

    const { isLoading } = useQuery(['league', leagueId], ApiClient.getLeagueDetails, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            form.setFieldsValue({
                ...response,
                start: moment(response.start),
                end: moment(response.end),
            });
        },
    });

    return (
        <DrawerLayout>
            <AddLeagueWrapper>
                <TopBar title="Leagues" subtitle={'Edit League'} isAdd={false} path={-1} />
                <Skeleton loading={isLoading}>
                    <NewLeagueForm form={form} leagueId={leagueId} buttonTitle={'Edit League'} />
                </Skeleton>
            </AddLeagueWrapper>
        </DrawerLayout>
    );
};

export default EditLeague;
