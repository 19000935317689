import { css } from 'styled-components';

const styles = () => css`
    position: relative;
    height: calc(100vh - 64px);
    overflow: hidden;

    .slick-slider {
        height: calc(100vh - 64px);

        .slick-list {
            height: 100%;

            .slick-track {
                height: inherit;
            }

            .slide-image {
                height: inherit;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    height: 100%;
                    z-index: 2;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%),
                        linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
                }
            }
        }

        .slick-slide > div {
            height: inherit;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: opacity(60%);
            }
        }
    }
`;

export default styles();
