import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import UploadList from 'antd/lib/upload/UploadList';
import { DraggerProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

type IProps = Pick<DraggerProps, 'fileList' | 'onChange' | 'listType'>;

const ReOrderImages: React.FC<IProps> = ({ fileList, onChange, listType }) => {
    function handleImageRemove(file: UploadFile) {
        console.log(file, fileList);
        const newFileList = fileList?.filter((item) => item.uid !== file.uid);
        onChange?.({
            fileList: newFileList,
        } as any);
    }

    if (!fileList) return null;

    return (
        <ReactSortable
            list={fileList as any}
            setList={(val) => {
                onChange?.({
                    fileList: val,
                } as any);
            }}
        >
            {fileList.map((item, idx) => (
                <React.Fragment key={`list-${idx}`}>
                    <UploadList
                        key={idx + 1}
                        locale={{ previewFile: 'Preview', removeFile: 'Remove' }}
                        showDownloadIcon={false}
                        listType={listType}
                        onRemove={handleImageRemove}
                        items={[item]}
                    />
                </React.Fragment>
            ))}
        </ReactSortable>
    );
};

export default ReOrderImages;
