const prefix = 'creator';

export const get_game_developers = (val) => {
    if (val) return `${prefix}?name=${val}`;
    return `${prefix}`;
};

export const addDeveloper = `${prefix}`;

export const getDevelopers = `${prefix}`;

export const editDeveloper = (creatorId: string) => `${prefix}/${creatorId}`;
