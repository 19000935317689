import React, { FunctionComponent } from 'react';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';
import style from './styles';
const SignInWrapper = styled.div`
    ${style}
`;

interface Props {
    isSigningIn: boolean;
    onFinish: (values: any) => void;
}

const SignIn: FunctionComponent<Props> = ({ isSigningIn, onFinish }: Props) => {
    return (
        <SignInWrapper>
            <div className="sign-in-card">
                <Form layout="vertical" onFinish={onFinish}>
                    <div className="heading">Login to Account</div>
                    <div className="sub-heading">Please enter your email and password to continue</div>
                    <div className="form-wrapper">
                        <Form.Item label="Email Address" name="email">
                            <Input className="weplay-input" />
                        </Form.Item>
                        <Form.Item label="Password" name="password">
                            <Input.Password type="password" className="weplay-input" />
                        </Form.Item>

                        <Button className="primary-button" htmlType="submit" loading={isSigningIn}>
                            Sign In
                        </Button>
                    </div>
                </Form>
            </div>
        </SignInWrapper>
    );
};
export default SignIn;
