import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Table } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { DrawerLayout } from '../../Layout';
import { ROUTES } from '../../router';
import style from './styles';
import { PaginationConfig, paginationStyles } from '../common';

const KnockoutWrapper = styled.div`
    ${style}
    ${paginationStyles}
`;

interface Props {
    columns: any;
    data: any[];
    search: string;
    loading?: boolean;
    setSearch: (value: any) => void;
}

const Knockout: React.FC<Props> = ({ search, setSearch, data, columns, loading }) => {
    const navigate = useNavigate();

    return (
        <DrawerLayout>
            <KnockoutWrapper>
                <div className={'header'}>
                    <div className="flex-1" />
                    <div>
                        <Input
                            value={search}
                            allowClear
                            placeholder={'Search knockout'}
                            prefix={<SearchOutlined />}
                            onChange={(event) => {
                                setSearch(event.target.value);
                            }}
                        />
                    </div>
                    <Button
                        className={'primary-button add-button'}
                        icon={<PlusOutlined />}
                        onClick={() => navigate(ROUTES.ADD_KNOCKOUT)}
                    >
                        Add Knockout
                    </Button>
                </div>
                <Table
                    loading={loading}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={data}
                    pagination={PaginationConfig}
                />
            </KnockoutWrapper>
        </DrawerLayout>
    );
};

export default Knockout;
