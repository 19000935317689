import React, { ForwardRefRenderFunction } from 'react';
import { useMutation } from 'react-query';
import { Col, Form, Input, Modal, Row } from 'antd';

const { TextArea } = Input;

interface Props {
    reviewId: string | number;
}

export interface EditModalRef {
    openModal: (initialData: CommentsEntity) => void;
}

const EditModal: ForwardRefRenderFunction<EditModalRef, Props> = ({ reviewId }, ref) => {
    const [form] = Form.useForm();

    const [visible, setVisible] = React.useState(false);

    React.useImperativeHandle(ref, () => {
        return {
            openModal: function (initialData) {
                form.setFieldsValue({ ...initialData, reviewId });
                setVisible(true);
            },
        };
    });

    const { mutate, isLoading } = useMutation('editComment', {
        onSettled: (data) => {
            data && setVisible(false);
        },
    });

    return (
        <Form form={form} layout="vertical" preserve={false} requiredMark={false} onFinish={mutate}>
            <Modal
                getContainer={false}
                visible={visible}
                title="Edit Comment"
                onCancel={() => setVisible(false)}
                centered
                confirmLoading={isLoading}
                okText="Update"
                style={{ padding: 0 }}
                bodyStyle={{ maxHeight: '90vh' }}
                onOk={form.submit}
                okButtonProps={{
                    style: {
                        background: 'rgb(87, 187, 138)',
                        color: '#FFF',
                        fontSize: '13px',
                        borderRadius: '5px',
                        outline: 'none',
                        border: '1px solid rgb(87, 187, 138)',
                        height: 'auto',
                        margin: '5px',
                    },
                }}
                cancelButtonProps={{
                    style: {
                        background: '#FFF',
                        color: '#E74C3C',
                        fontSize: '13px',
                        borderRadius: '5px',
                        outline: 'none',
                        border: '1px solid #E74C3C',
                        width: 'auto',
                        height: 'auto',
                        margin: '5px',
                    },
                }}
            >
                <Row gutter={16}>
                    <Col span={24} className="gutter-row">
                        <Form.Item hidden name="reviewId">
                            <Input />
                        </Form.Item>
                        <Form.Item hidden name="id">
                            <Input />
                        </Form.Item>
                        <Form.Item name="content" rules={[{ required: true, message: 'Please enter Comments' }]}>
                            <TextArea
                                placeholder={'Write your comment here'}
                                autoSize={{ minRows: 4, maxRows: 6 }}
                                className={'weplay-input'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default React.forwardRef(EditModal);
