import React, { FC } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import style from './styles';
import { API, ApiClient } from '../../../api';
import UploadContainer from '../../../containers/upload';
import { ROUTES } from '../../../router';
import GameMobilePreview from './GameMobilePreview';
import SelectDeveloper from '../components/add-developer';

const NewGameFormWrapper = styled.div`
    ${style}
`;

const { TextArea } = Input;
const { Option } = Select;

const getImageUrl = (imageList: any[] = []) => {
    return imageList.map((val) => {
        return val?.response?.url ? API.baseUrl + val?.response?.url : val?.url || val;
    });
};

interface IProps {
    form?: FormInstance;
    buttonTitle?: string;
    gameId?: string;
}

const NewGameForm: FC<IProps> = ({ form: _form, buttonTitle, gameId }) => {
    const [form] = Form.useForm(_form);

    const [devInfo, setDevInfo] = React.useState<any>({});
    const navigate = useNavigate();

    const create = useMutation(ApiClient.createGame, {
        onSuccess: () => {
            ApiClient.showSuccess('Game successfully added');
        },
    });

    const update = useMutation(ApiClient.updateGame, {
        onSuccess: () => {
            ApiClient.showSuccess('Game successfully updated');
        },
    });

    const onFinish = React.useCallback(async (values: any) => {
        try {
            const submitData = { ...values };

            submitData['thumbnail'] = Array.isArray(values?.thumbnail)
                ? getImageUrl(values?.thumbnail)[0]
                : values?.thumbnail;

            submitData['images'] = getImageUrl(values?.images);

            delete submitData.avatar;
            delete submitData.fullName;
            if (gameId) {
                await update.mutateAsync({ gameId: gameId as string, data: submitData });
            } else {
                await create.mutateAsync(submitData);
            }
            navigate(ROUTES.GAMES);
        } catch (e: any) {}
    }, []);

    const { data: categories } = useQuery('categories', ApiClient.getCategories, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return (
        <NewGameFormWrapper>
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16} justify={'start'}>
                    <Col span={24} lg={16}>
                        <div className="container">
                            <Row gutter={16}>
                                <Col span={12} className="gutter-row">
                                    <Form.Item
                                        label={'Game Title'}
                                        name="title"
                                        rules={[{ required: true, message: 'Enter title' }]}
                                    >
                                        <Input placeholder={'Enter game title'} className={'weplay-input'} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="gutter-row">
                                    <Form.Item
                                        label={'Game URL'}
                                        name="gameURL"
                                        rules={[{ required: true, message: 'Enter Game Url' }]}
                                    >
                                        <Input placeholder={'Enter game url'} className={'weplay-input'} name="url" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Game Description"
                                        name="description"
                                        rules={[{ required: true, message: 'Enter Description' }]}
                                    >
                                        <TextArea rows={4} placeholder="Enter Description" className={'weplay-input'} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12} className="gutter-row">
                                    <Form.Item label={'Categories'} name="categories">
                                        <Select
                                            placeholder={'Select Categories'}
                                            mode="multiple"
                                            allowClear
                                            options={categories}
                                            fieldNames={{
                                                label: 'name',
                                                value: 'id',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="gutter-row">
                                    <SelectDeveloper setDevInfo={setDevInfo} />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12} className="gutter-row">
                                    <Form.Item label={'Orientation'} name="orientation">
                                        <Select placeholder={'Select Orientation'} allowClear>
                                            <Option value="Portrait">Portrait</Option>
                                            <Option value="Landscape">Landscape</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24} className="gutter-row">
                                    <UploadContainer
                                        label={'Game Thumbnail'}
                                        formItemName={'thumbnail'}
                                        fileName={'file'}
                                        isMultiple={false}
                                        maxCount={1}
                                    />
                                </Col>
                            </Row>

                            <Row className="gutter">
                                <Col span={24} className="gutter-row">
                                    <UploadContainer
                                        label={'Images'}
                                        formItemName={'images'}
                                        fileName={'file'}
                                        isMultiple={true}
                                        reOrderImage
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="create-button"
                                        loading={create.isLoading || update.isLoading}
                                    >
                                        {buttonTitle || 'Add Game'}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={8}>
                        <GameMobilePreview devInfo={devInfo} />
                    </Col>
                </Row>
            </Form>
        </NewGameFormWrapper>
    );
};

export default NewGameForm;
