import { QueryFunctionContext } from 'react-query';
import { API } from '../api';

export function getLeagues({ signal }: QueryFunctionContext) {
    return API.get(API.PATH.getLeagues, { signal }).then((res) => res.data);
}

export function getLeagueDetails({ signal, queryKey }: QueryFunctionContext) {
    const [, leagueId] = queryKey as string[];
    return API.get(API.PATH.getLeagueDetail(leagueId), { signal }).then((res) => res.data);
}
