import React from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SignIn from '../../../components/authentication/sign-in';
import { userSignedIn } from '../store/actions';
import { ApiClient } from '../../../api';
import { ROUTES } from '../../../router';

function useLocationState<T>(): T | undefined {
    const location = useLocation();
    return location.state as T | undefined;
}

const SignInContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navState = useLocationState<{ from: Location }>();

    const { mutate, isLoading } = useMutation('signIn', ApiClient.logIn, {
        onSuccess: async function (data) {
            await dispatch(userSignedIn(data));
            if (navState?.from?.pathname) {
                navigate(navState.from.pathname);
            } else {
                navigate(ROUTES.DASHBOARD);
            }
        },
    });

    return <SignIn isSigningIn={isLoading} onFinish={mutate} />;
};

export default SignInContainer;
