import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import { DrawerLayout } from '../../Layout';
import { GameCard } from './components';
import styles from './styles';
import { ApiClient } from '../../api';
import TopBar from '../top-bar';
import TrendingGames from './components/trending-games';
import HighLightedGames from './components/highlighted-games';
import PaginationComponent from './components/pagination';

const GamesWrapper = styled.div`
    ${styles}
`;

const Games = () => {
    const [, setLoading] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState('');

    const { data, isLoading, isFetching, fetchNextPage } = useInfiniteQuery<Pagination<Game>>(
        'games',
        ApiClient.fetchGames,
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            select: ApiClient.selectGame,
            getNextPageParam: ApiClient.getNextPageParam,
        },
    );

    const gameData = data?.pages[0];

    const gamesList = React.useMemo(() => {
        const sRegex = new RegExp(search, 'gi');
        return gameData?.data.filter((value) => sRegex.test(value?.title));
    }, [search, data]);

    return (
        <DrawerLayout>
            <GamesWrapper>
                <div className="header">
                    <TopBar
                        title="All Games"
                        isAdd={true}
                        path={'/games'}
                        isSearch
                        search={search}
                        placeholder={'Search Games'}
                        onChange={(event) => {
                            setSearch(event.target.value);
                        }}
                    />
                </div>
                <div className="game-container">
                    <List
                        loading={isFetching}
                        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}
                        dataSource={gamesList}
                        locale={{ emptyText: 'No Games' }}
                        rowKey={(item) => item?.id}
                        renderItem={(item) => (
                            <List.Item>
                                <GameCard data={item} loading={isLoading} setLoading={setLoading} />
                            </List.Item>
                        )}
                        footer={
                            <PaginationComponent
                                total={gameData?.total || 0}
                                pageSize={5}
                                currentPage={gameData?.page || 0}
                                onChange={(page: number) => fetchNextPage({ pageParam: page })}
                            />
                        }
                    />
                </div>
                <TrendingGames />
                <HighLightedGames />
            </GamesWrapper>
        </DrawerLayout>
    );
};

export default Games;
