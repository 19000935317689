import moment from 'moment';
import { lpad } from '../../../utils/helper';

const Columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        render: (value) => lpad(value, 5).toUpperCase(),
    },
    {
        title: 'Username',
        dataIndex: 'username',
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        render: (value) => `KES ${Number(value.redeemable ?? 0) + Number(value.unreedemable ?? 0)}`,
    },
    {
        title: 'First Deposit',
        dataIndex: 'initialDeposit',
        render: (value) => `KES ${value ?? 0}`,
    },
    {
        title: 'Latest version',
        dataIndex: 'latestVersion',
        render: (value) => (value ? 'Yes' : 'No'),
    },
    {
        title: 'Platform',
        dataIndex: 'platform',
        render: (value) => `${value ? value?.toUpperCase?.() : 'N/A'}`,
    },
    {
        title: 'Close Taps',
        dataIndex: 'closeTaps',
        render: (value) => `${value ?? 0}`,
    },
    {
        title: 'Update Taps',
        dataIndex: 'updateTaps',
        render: (value) => `${value ?? 0}`,
    },
];
export { Columns };
