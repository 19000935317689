import { AxiosResponse } from 'axios';
import { API, InMemoryJWTManager } from '../../../../api';
import {
    // IS_USER_SIGNING_IN,
    USER_SIGN_IN_SUCCESS,
    // USER_SIGN_IN_FAILURE,
    USER_LOG_OUT,
    GET_PROFILE,
    IS_GETTING_PROFILE,
} from '../types';
import { mutationError } from '../../../../utils/helper';

// const isUserSigningIn = () => ({
//     type: IS_USER_SIGNING_IN,
// });

const onUserSignInSuccess = (payload) => ({
    type: USER_SIGN_IN_SUCCESS,
    payload,
});

// const onUserSignInFailure = () => ({
//     type: USER_SIGN_IN_FAILURE,
// });

const onUserLogout = () => ({
    type: USER_LOG_OUT,
});

const isGettingProfile = () => ({
    type: IS_GETTING_PROFILE,
});

const getAdminProfile = (payload) => ({
    type: GET_PROFILE,
    payload,
});

interface AuthRes {
    accessToken: string;
    data: {
        fullName: string;
    };
}

const userSignedIn = (data: AuthRes) => {
    return async (dispatch) => {
        dispatch(onUserSignInSuccess(data.data));
        sessionStorage.setItem('token', data.accessToken);
        InMemoryJWTManager.setAccessToken(data.accessToken);
        dispatch(isGettingProfile);
        await API.get(API.PATH.getProfile)
            .then((res: AxiosResponse<any>) => {
                dispatch(getAdminProfile(res?.data));
            })
            .catch(mutationError);
    };
};

const getProfile = () => {
    return (dispatch) => {
        dispatch(isGettingProfile);
        API.get(API.PATH.getProfile).then((res: AxiosResponse<any>) => {
            dispatch(getAdminProfile(res?.data));
        });
    };
};
const userLogout = () => {
    return (dispatch) => {
        return dispatch(onUserLogout());
    };
};

export { userLogout, userSignedIn, getProfile };
