import { css } from 'styled-components';

const style = () => css`
    .container {
        max-width: 100% !important;
        padding: unset !important;
        height: 90vh;
        background-color: #fff;
        position: relative;

        .bubble-canvas {
            height: 100%;
            width: 100%;
            //background: linear-gradient(to bottom, #edfffa 0%, #31c5d6 100%);
            position: relative;
            overflow: hidden;
        }
    }

    .container1 {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-bottom: 3rem;

        .title {
            font-weight: 600;
            line-height: 140%;
            color: #f83608;
            margin-bottom: 0;
            font-size: clamp(2rem, 5.5vw, 10rem);
        }
        .caption {
            font-weight: 400;
            line-height: 140%;
            color: #082d2a;
            margin-top: 0;
            font-size: clamp(1rem, 3vw, 6rem);
        }
    }

    .bubbles-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .avatar-bubble {
        width: 150px; /* Adjust size as needed */
        height: 150px;
        position: absolute;
        border-radius: 50%;
        animation: floatAnimation infinite 10s ease-in-out;
        bottom: -160px;
    }

    .avatar-bubble img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    @keyframes floatAnimation {
        to {
            transform: translateY(-110vh); /* Move up beyond the viewport */
        }
    }
`;

export default style();
