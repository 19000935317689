import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    box-shadow: ${(props: any) => props.theme[COLORS.CARD_SHADOW]};
    padding: 0.7rem;
    border-radius: 16px;

    display: flex;

    .game-image {
        height: 100px;
        aspect-ratio: 1.25;
        border-radius: 8px;
    }

    .game-detail-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        overflow: hidden;

        .change-game {
            position: absolute;
            right: 10px;
            color: ${(props) => props.theme[COLORS.DECLINED]};
        }

        .developer-name {
            display: block;
            font-size: 12px;
            text-transform: capitalize;
            .user-avatar {
                height: 28px;
                width: 28px;
                margin-right: 10px;
                border-radius: 20px;
            }
        }

        h5 {
            margin-bottom: 4px;
        }

        .game-stats {
            display: flex;
            margin-top: auto;
            align-items: center;
            justify-content: space-between;

            .ant-rate {
                font-size: 10px;
            }
        }
    }
`;

export default style();
