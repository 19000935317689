import React, { FC } from 'react';
import { Rate, Typography } from 'antd';
import styled from 'styled-components';
import { EditOutlined, LineChartOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { API } from '../../../api';
import styles from './game-details.styles';

const { Title, Link } = Typography;

const GameDetailsWrapper = styled.div`
    ${styles}
`;

interface IProps {
    item: any;
    onChange?: () => void;
    disabled?: boolean;
}

const GameDetails: FC<IProps> = ({ item, onChange, disabled }) => {
    if (!item) {
        return null;
    }
    return (
        <GameDetailsWrapper className="game-details">
            <img className="game-image" src={item?.images?.at(0)} alt={'game-image'} />
            <div className="game-detail-container">
                {!disabled ? <EditOutlined className="change-game" onClick={() => onChange?.()} /> : null}
                <Title level={5} ellipsis={{ rows: 1 }}>
                    {item.title}
                </Title>
                <Link className={'developer-name'}>
                    <img src={API.getImageUrl(item?.createdBy?.avatar)} alt="user-avatar" className="user-avatar" />
                    {item?.createdBy?.fullName}
                </Link>
                <div className="game-stats">
                    <Rate allowHalf disabled value={item?.rating} />
                    <div className="icons">
                        <LineChartOutlined style={{ marginRight: 10, color: item?.trending ? 'green' : '' }} />
                        <ThunderboltOutlined style={{ color: item?.highlighted ? 'orange' : '' }} />
                    </div>
                </div>
            </div>
        </GameDetailsWrapper>
    );
};

export default GameDetails;
