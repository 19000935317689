/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form } from 'antd';
import React from 'react';
import styled from 'styled-components';
import style from './styles';
import { API } from '../../api';
import UploadDrag from './UploadDrag';

const UploadWrapper = styled.div`
    ${style}
`;

interface UploadProps {
    formItemName: string | (number | string)[];
    label?: string;
    isMultiple?: boolean;
    maxCount?: number;
    fileName?: string;
    reOrderImage?: boolean;
}

const FileUpload = ({ formItemName, label, fileName, isMultiple, maxCount, reOrderImage }: UploadProps) => {
    const [fileList, setFileList] = React.useState<any[]>([]);

    const addFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        // for single upload
        // fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = API.getImageUrl(file.response.url);
            }
            return file;
        });

        setFileList(fileList);
    };

    return (
        <UploadWrapper>
            <Form.Item
                name={formItemName}
                label={label}
                valuePropName="fileList"
                getValueFromEvent={addFile}
                rules={[{ required: true, message: 'Please select file!' }]}
                requiredMark={false}
            >
                <UploadDrag
                    fileName={fileName}
                    multiple={isMultiple}
                    maxCount={maxCount}
                    fileList={fileList}
                    onChange={handleChange}
                    reOrderImage={reOrderImage}
                />
            </Form.Item>
        </UploadWrapper>
    );
};

export default FileUpload;
