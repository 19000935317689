import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Table, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { DrawerLayout } from '../../Layout';
import { ROUTES } from '../../router';
import style from './styles';
import { PaginationConfig } from '../common';

const LeagueWrapper = styled.div`
    ${style}
`;

const { Title } = Typography;

interface Props {
    columns: any;
    data: any[];
    search: string;
    loading?: boolean;
    setSearch: (value: any) => void;
}

const Leagues: React.FC<Props> = ({ search, setSearch, data, columns, loading }) => {
    const navigate = useNavigate();

    return (
        <DrawerLayout>
            <LeagueWrapper>
                <div className={'header'}>
                    <Title level={3}>League</Title>
                    <div className="flex-1" />
                    <div>
                        <Input
                            value={search}
                            allowClear
                            placeholder={'Search league'}
                            prefix={<SearchOutlined />}
                            onChange={(event) => {
                                setSearch(event.target.value);
                            }}
                        />
                    </div>
                    <Button
                        className={'primary-button add-button'}
                        icon={<PlusOutlined />}
                        onClick={() => navigate(ROUTES.ADD_LEAGUES)}
                    >
                        Add League
                    </Button>
                </div>
                <Table
                    loading={loading}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={data}
                    pagination={PaginationConfig}
                />
            </LeagueWrapper>
        </DrawerLayout>
    );
};

export default Leagues;
