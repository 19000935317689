const prefix = 'game';

const file_prefix = 'file';

export const createGame = `${prefix}`;

export const uploadFile = `${file_prefix}`;

export const getGames = `${prefix}`;

export const getTrendingGames = `${prefix}/trending-games`;

export const getSingleGame = (gameId: string) => `${prefix}/${gameId}`;

export const updateGame = (gameId: string) => `${prefix}/${gameId}`;

export const deleteGame = (gameId: string) => `${prefix}/${gameId}`;

export const resetApiKey = `${prefix}/reset-api-key`;

export const playedGames = (userId: string) => `${prefix}/recently-played-games/${userId}`;
