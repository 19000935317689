import { css } from 'styled-components';
import { selectStyles } from '../../../common-styles';

const style = () => css`
    .container {
        box-shadow: 0 2px 6px rgb(0 0 0 / 7%);
        background: #ffffff;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 5px;
        padding: 40px 25px;
        max-width: 1200px;

        .create-button {
            height: 40px !important;
            padding: 0 15px !important;
            margin-left: 16px;
            border-radius: 5px;
            background-color: #2980b9;
            border: 1px solid #2980b9;
        }

        ${selectStyles}
    }
`;

export default style();
