import React, { forwardRef } from 'react';
import { Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { DeveloperForm } from '../../../components/developers/components';
import { ApiClient } from '../../../api';

export interface NewDeveloperFormRef {
    openModal: () => void;
}

const NewDeveloperForm = forwardRef<NewDeveloperFormRef>((_, ref) => {
    const queryClient = useQueryClient();
    const [visible, setVisible] = React.useState(false);

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: () => setVisible(true),
        }),
        [],
    );

    const { mutate: onFinish, isLoading } = useMutation(ApiClient.addDeveloper, {
        onSuccess: async (newDeveloper) => {
            await queryClient.cancelQueries('developers');

            queryClient.setQueryData('developers', (old: any) => {
                return [newDeveloper, ...(old || [])];
            });

            ApiClient.showSuccess('Developer successfully added');
            setVisible(false);
        },
    });

    return (
        <Modal
            destroyOnClose
            title={'Add Developer'}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <DeveloperForm onFinish={onFinish} loading={isLoading} />
        </Modal>
    );
});

NewDeveloperForm.displayName = 'New Developer Form';

export default NewDeveloperForm;
