import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Slider from 'react-slick';
import styles from './styles';
import BtnSlider from './BtnSlider';
import SliderCard, { TrendingGame } from './SliderCard';
import { ApiClient } from '../../../../api';

import './styles/slick.css';
import './styles/slick-theme.css';

const SliderWrapper = styled.div`
    ${styles}
`;

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1200,
    pauseOnHover: false,
    draggable: false,
    arrows: false,
};

const ImageSlider = () => {
    const ref = React.useRef<any>(null);

    const nextSlide = () => ref.current?.slickNext();

    const prevSlide = () => ref.current?.slickPrev();

    const { data } = useQuery<TrendingGame[]>('homepage-games', ApiClient.getTrendingGames);

    return (
        <SliderWrapper>
            <Slider ref={(instance) => (ref.current = instance)} {...settings}>
                {data?.map((obj, index) => {
                    return (
                        <div key={index} className={'slide-image'}>
                            <img src={obj?.images[0].imageURL} alt={obj?.title} loading="eager" />
                            <SliderCard key={index} item={obj} index={index} slideIndex={0} />
                        </div>
                    );
                })}
            </Slider>
            <div className="btn-arrows">
                <BtnSlider nextSlide={nextSlide} prevSlide={prevSlide} />
            </div>
        </SliderWrapper>
    );
};

export default ImageSlider;
