import React from 'react';
import styled from 'styled-components';
import { DrawerLayout } from '../../../Layout';
import TopBar from '../../top-bar';
import styles from './styles';
import NewLeagueForm from './NewLeagueForm';

const AddLeagueWrapper = styled.div`
    ${styles}
`;

const AddLeague = () => {
    return (
        <DrawerLayout>
            <AddLeagueWrapper>
                <TopBar title="Leagues" subtitle={'Add New League'} isAdd={false} path={-1} />
                <NewLeagueForm />
            </AddLeagueWrapper>
        </DrawerLayout>
    );
};

export default AddLeague;
