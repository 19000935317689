import React, { FC } from 'react';
import { Button, Form, Select } from 'antd';
import styled from 'styled-components';
import { API } from '../../../../api';
import Plus from '../../../../assets/icons/user-plus.svg';
import style from './styles/index';
import AddDeveloperModal from './AddDeveloperModal';
import { mutationError } from '../../../../utils/helper';

const Container = styled.div`
    ${style}
`;

interface Props {
    setDevInfo: any;
}
interface addProps {
    onClick: (event: React.MouseEvent) => void;
}
const AddButton: FC<addProps> = ({ onClick }: addProps) => {
    return (
        <Container>
            <div className="add-container">
                <Button onClick={onClick} className="primary-button add">
                    <img src={Plus} alt="add-user" className="plus-icon" />
                    Add New
                </Button>
            </div>
        </Container>
    );
};

const SelectDeveloper: FC<Props> = ({ setDevInfo }: Props) => {
    const [developers, setDevelopers] = React.useState<Developer[]>([]);
    const [visible, setVisible] = React.useState<boolean>(false);

    const getDevelopers = React.useCallback((value) => {
        API.get<Developer[]>(API.PATH.get_game_developers(value))
            .then(({ data }) => setDevelopers(data))
            .catch((error: any) => mutationError(error));
    }, []);

    React.useEffect(() => {
        getDevelopers('');
    }, []);

    const onSearch = (val) => {
        getDevelopers(val);
    };

    const onSelect = (value: string) => {
        const findSingleItem = developers?.find((val) => val?.id === value);
        setDevInfo(findSingleItem);
    };

    return (
        <Container>
            <Form.Item label={'Developer'} name="createdBy">
                <Select
                    showSearch
                    allowClear
                    placeholder="Select a Developer"
                    optionFilterProp="children"
                    className="select-box"
                    onSearch={onSearch}
                    onSelect={onSelect}
                    filterOption={(input, option: any) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA: any, optionB) =>
                        optionA.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    dropdownClassName="drop-down"
                    dropdownRender={(menu) => (
                        <React.Fragment>
                            {menu}
                            <AddButton onClick={() => setVisible(true)} />
                        </React.Fragment>
                    )}
                    options={developers}
                    fieldNames={{
                        value: 'id',
                        label: 'fullName',
                    }}
                />
            </Form.Item>
            <AddDeveloperModal visible={visible} setVisible={setVisible} />
        </Container>
    );
};

export default SelectDeveloper;
