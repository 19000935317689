import React from 'react';
import { lpad } from '../../../utils/helper';
import { Tag } from 'antd';

const Columns = () => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (value) => lpad(value, 4).toUpperCase(),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (value) => value?.toLocaleString(),
        },
        {
            title: 'Credit/Debit',
            dataIndex: 'type',
            render: (value) => {
                switch (value) {
                    case 'debit':
                        return <Tag color="error">DEBIT</Tag>;
                    case 'credit':
                        return <Tag color="success">CREDIT</Tag>;
                    default:
                        return <span data-value={value}>{value}</span>;
                }
            },
        },
        {
            title: 'Completed',
            dataIndex: 'completed',
            render: (value) => (value ? 'Yes' : 'No'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
    ];
};

export default Columns;
