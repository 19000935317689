import { QueryFunctionContext } from 'react-query';
import { API } from '../api';

export function getUserTransactions({ signal, queryKey, pageParam = 1 }: QueryFunctionContext) {
    const [, userId] = queryKey as string[];
    return API.get(API.PATH.getUserTransactions(userId), {
        signal,
        params: {
            limit: 10,
            page: pageParam,
        },
    }).then((res) => res.data);
}
