import React from 'react';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';
import { lpad } from '../../../utils/helper';

const Columns = (onDetailClick: (id: string) => void) => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (value) => lpad(value, 5).toUpperCase(),
        },
        {
            title: 'Username',
            dataIndex: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            render: (value, record) => `${record.countryCode || ''}-${value || ''}`,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (value) => moment(value).format('MM/DD/YYYY'),
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id) => (
                <div className={'action-button'}>
                    <div>
                        <EyeOutlined onClick={() => onDetailClick?.(id)} />
                    </div>
                </div>
            ),
        },
    ];
};
export { Columns };
