import React from 'react';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { API, ApiClient } from '../../../api';
import { getProfile } from '../../../containers/authentication/store/actions';
import { RootState } from '../../../store/reducers';
import styles from './styles';
import { mutationError } from '../../../utils/helper';

const ProfileWrapper = styled.div`
    ${styles}
`;

const { Title } = Typography;

const Profile = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    const getProfileInfo = useSelector((state: RootState) => state.auth.data);

    const onFinish = React.useCallback(async (values: any) => {
        setLoading(true);
        try {
            const submitData = { ...values };

            await API.patch(API.PATH.updateProfile, submitData);
            setLoading(false);
            ApiClient.showSuccess('Profile Updated Successfully');
            dispatch(getProfile());
        } catch (e: any) {
            mutationError(e);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        form.setFieldsValue({ fullName: getProfileInfo?.fullName });
        form.setFieldsValue({ email: getProfileInfo?.email });
    }, [getProfileInfo]);

    return (
        <ProfileWrapper>
            <Row gutter={16}>
                <Col span={12}>
                    <div className="profile-container">
                        <div className="profile-info">
                            <div className="avatar">
                                <Title level={5}>{getProfileInfo?.fullName?.charAt(0)}</Title>
                            </div>
                            <Title level={4}> {getProfileInfo?.fullName}</Title>
                            <Title level={3}>{getProfileInfo?.email}</Title>
                        </div>

                        <Row gutter={10} style={{ marginTop: '10px', padding: '20px' }}>
                            <Col span={24}>
                                <Form layout="vertical" form={form} requiredMark={false} onFinish={onFinish}>
                                    <Form.Item
                                        label={'Full Name'}
                                        rules={[{ required: true, message: 'Enter Full name' }]}
                                        name={'fullName'}
                                    >
                                        <Input className={'weplay-input'} placeholder={'Enter full name'} />
                                    </Form.Item>
                                    <Form.Item
                                        label={'Email'}
                                        rules={[{ required: true, message: 'Enter email' }]}
                                        name={'email'}
                                    >
                                        <Input className={'weplay-input'} placeholder={'Enter first name'} />
                                    </Form.Item>

                                    <Button className="primary-button update-btn" htmlType="submit" loading={loading}>
                                        Update
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </ProfileWrapper>
    );
};

export default Profile;
