import { css } from 'styled-components';
import { selectStyles } from '../../components/common-styles';

const style = () => css`
    user-select: none;
    background-color: #ffffff;
    .ant-layout-content,
    .ant-layout-footer {
        .container {
            max-width: 1600px;
            padding: 0 8px;
            margin: auto;
        }
    }
    .heading {
        font-size: 2.3rem;
        font-weight: 600;
        color: #000000;
        margin-top: 80px;
        text-align: center;
    }
    .form-wrapper {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .form {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    ${selectStyles}

    > .ant-layout-content {
        .primary-button {
            padding: 0 32px;
            border-radius: 16px;
            background: #0fe880 !important;
            color: #000000 !important;
            border: none !important;

            font-weight: 400;
            font-size: 16px;
            line-height: 140%;

            margin-bottom: 16px;
        }
    }
    .textWrapper {
        flex: 1;
        @media (max-width: 768px) {
            flex: inherit;
        }
    }

    .ant-layout-footer {
        padding: 24px 8px 14px;
    }
    /* .scroll-snap {
        scroll-snap-type: y mandatory;
    } */
`;

export default style();
