import { QueryFunctionContext } from 'react-query';
import { API } from '../api';

export function fetchUsers({ signal, pageParam = 1, queryKey }: QueryFunctionContext) {
    const nameObj = queryKey[1] ? { name: queryKey[1] } : {};
    return API.get(API.PATH.getAllUsers, {
        params: {
            limit: 10,
            page: pageParam,
            ...nameObj,
        },
        signal: signal,
    }).then((response) => response.data);
}

// export function fetchUserDetails({ signal, queryKey }: QueryFunctionContext) {
//     const [, userId] = queryKey as string[];
//     return API.get(API.PATH.getAllUsers, { signal }).then((res) => res.data);
// }
