import React from 'react';
import styled from 'styled-components';
import { DrawerLayout } from '../../../Layout';
import styles from './styles';
import NewGameForm from './NewGameForm';
import TopBar from '../../top-bar';

const AddGameWrapper = styled.div`
    ${styles}
`;

const AddGame = () => {
    return (
        <DrawerLayout>
            <AddGameWrapper>
                <TopBar title="Games" subtitle={'Add New Game'} isAdd={false} path={-1} />
                <NewGameForm />
            </AddGameWrapper>
        </DrawerLayout>
    );
};

export default AddGame;
