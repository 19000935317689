import React from 'react';
import styled from 'styled-components';
import style from './styles';
import { Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
// import LogoLarge from '../../../../assets/images/Layer-2.png';
// import * as Top from '../../../../assets/images/top';
import MainImage from '../../../../assets/images/top/image.png';
import { playClickAction } from '../../../../utils/helper';

const { Paragraph } = Typography;

const WelcomeTextWrapper = styled.div`
    ${style}
`;

const WelcomeText = () => {
    return (
        <WelcomeTextWrapper id="welcome-page">
            <div className="container">
                {/* <div className="grid-container">
                    <div className="grid-content">
                        <img src={Top.One} alt="player" className="image one" />
                        <img src={Top.Two} alt="player" className="image two" />
                        <img src={Top.Three} alt="player" className="image three" />
                        <img src={Top.Four} alt="player" className="image four" />
                        <img src={Top.Five} alt="player" className="image five" />
                        <img src={Top.Six} alt="player" className="image six" />
                        <img src={Top.Seven} alt="player" className="image seven" />
                        <img src={Top.Eight} alt="player" className="image eight" />
                        <img src={Top.Nine} alt="player" className="image nine" />
                        <img src={Top.Ten} alt="player" className="image ten" />
                        <img src={Top.Eleven} alt="player" className="image eleven" />
                    </div>
                </div> */}
                <img src={MainImage} alt="player" className="main-image" />
                {/* <div className="text-container">
                    <div className="weplay-div" style={{ marginBottom: '1rem' }}>
                        <img src={LogoLarge} alt="logo" className="weplay-small" />
                    </div>
                </div> */}
                <Paragraph className="para red" style={{ fontSize: '3.5rem' }}>
                    Mobile Social Gaming
                </Paragraph>
                <div
                    style={{
                        marginTop: '1.5rem',
                        fontSize: '1.9rem',
                        fontFamily: 'inherit',
                        padding: '20px',
                        textAlign: 'center',
                    }}
                >
                    Play hundreds of online games including action, educational, sports, puzzle, <br /> brain teasers,
                    magical quests and more. Enjoy leaderboards, achievements, <br /> multiplayer functionality,
                    dashboard and more
                </div>
                <Button className="primary-button" style={{ marginTop: '2rem' }} onClick={playClickAction}>
                    Start playing now
                    <ArrowRightOutlined />
                </Button>
            </div>
        </WelcomeTextWrapper>
    );
};

export default WelcomeText;
