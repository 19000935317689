import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    .header {
        display: flex;

        .flex-1 {
            flex: 1;
        }

        .ant-input-affix-wrapper {
            height: 40px;
            border-radius: 12px;
            max-width: 250px;

            .ant-input-prefix {
                margin-right: 6px;
            }
        }

        .add-button {
            height: 40px !important;
            padding: 0 15px !important;
            margin-left: 16px;
            border-radius: 12px;
        }
    }

    .action-button {
        display: flex;
        justify-content: flex-start;

        > div {
            padding: 0 8px;
            margin-right: 1px;
            + div {
                border-left: 1px solid #c4c4c4;
            }
        }

        .anticon-eye {
            color: ${(props) => props.theme[COLORS.PRIMARY]};
        }

        .anticon-edit {
            color: ${(props) => props.theme[COLORS.PRIMARY]};
        }
        .anticon-delete {
            color: ${(props) => props.theme[COLORS.DECLINED]};
        }
    }
    .ant-table {
        box-shadow: ${(props) => props.theme[COLORS.CARD_SHADOW]};
        border-radius: 15px;
        overflow: hidden;
        margin-top: 10px;
        .ant-table-thead > tr > th {
            font-weight: 600;
        }

        .ant-table-cell {
            .ant-tag {
                border-radius: 4px;
            }
        }

        .ant-table-cell span[data-value='Debit'] {
            color: ${(props) => props.theme[COLORS.DECLINED]};
        }

        .ant-table-cell span[data-value='Credit'] {
            background: red;
            padding: 4px;
            border-radius: 4px;
            color: ${(props) => props.theme[COLORS.SUCCESS]};
        }
    }
`;

export default style();
