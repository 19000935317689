/* eslint-disable react/prop-types */
import React from 'react';
import FileUpload from '../../components/upload';

interface Props {
    formItemName: string;
    label?: string;
    fileName?: string;
    isMultiple?: boolean;
    maxCount?: number;
    reOrderImage?: boolean;
}

const UploadContainer = ({
    formItemName,
    label,
    fileName,
    isMultiple = false,
    maxCount,
    reOrderImage = false,
}: Props) => {
    return (
        <FileUpload
            formItemName={formItemName}
            label={label}
            fileName={fileName}
            isMultiple={isMultiple}
            maxCount={maxCount}
            reOrderImage={reOrderImage}
        />
    );
};

export default UploadContainer;
