import React, { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiClient } from '../../api';
import Developers from '../../components/developers';
import { Columns } from './data';
import {
    EditDeveloperFormContainer,
    EditDeveloperFormRef,
    NewDeveloperFormContainer,
    NewDeveloperFormRef,
} from './forms';

const DeveloperContainer = () => {
    const newDeveloperRef = useRef<NewDeveloperFormRef>(null);
    const editDeveloperRef = useRef<EditDeveloperFormRef>(null);

    const [search, setSearch] = useState('');

    const { data: developers, isFetching } = useQuery<Developer[]>('developers', ApiClient.getDevelopers, {
        refetchOnWindowFocus: false,
        initialData: [],
    });

    const data = useMemo(() => {
        const sRegex = new RegExp(search, 'gi');
        return developers?.filter((value) => sRegex.test(value?.fullName));
    }, [search, developers]);

    const onEditPress = (record: Developer) => editDeveloperRef.current?.openModal(record);

    const onAddPress = () => newDeveloperRef.current?.openModal();

    const onDelete = async (developerId: string) => {
        console.log(developerId);
    };

    return (
        <Developers
            columns={Columns(onDelete, onEditPress)}
            data={data}
            search={search}
            setSearch={setSearch}
            loading={isFetching}
            onAddClick={onAddPress}
        >
            <NewDeveloperFormContainer ref={newDeveloperRef} />
            <EditDeveloperFormContainer ref={editDeveloperRef} />
        </Developers>
    );
};

export default DeveloperContainer;
