import React from 'react';
import { Popconfirm, Avatar } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { API } from '../../../api';

const Columns = (onDelete: (categoryId: string) => Promise<void>, onEdit) => {
    return [
        {
            title: 'AVATAR',
            dataIndex: 'avatar',
            render: (value, record) => {
                if (value?.startsWith('/files/')) {
                    value = API.baseUrl.concat(value);
                }
                return <Avatar src={value}>{record?.fullName?.charAt(0)}</Avatar>;
            },
            width: '100px',
        },
        {
            title: 'NAME',
            dataIndex: 'fullName',
        },
        {
            title: 'ACTIONS',
            dataIndex: 'id',
            align: 'left',
            render: (id, record) => (
                <div className={'action-button'}>
                    <div>
                        <EditOutlined onClick={() => onEdit?.(record)} />
                    </div>
                    <div style={{ display: 'none' }}>
                        <Popconfirm
                            disabled
                            title="Sure to delete?"
                            okText={'Yes'}
                            cancelText={'No'}
                            onConfirm={async () => await onDelete(id)}
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </div>
                </div>
            ),
            width: '10%',
        },
    ];
};
export { Columns };
