import React from 'react';
import { useQuery } from 'react-query';
import { Modal, Skeleton } from 'antd';
import { ApiClient } from '../../api';
import RoomDetails from '../../components/game-rooms/RoomDetails';

export interface RoomDetailRef {
    openModal: any;
}

const GameRoomDetail = React.forwardRef<RoomDetailRef>((_, ref) => {
    const [roomId, setRoomId] = React.useState<string>();

    const { data, isFetching } = useQuery(['game-room', roomId], ApiClient.getGameRoomDetails, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(roomId),
    });

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: (record: any) => setRoomId(record?.id || record.toString()),
        }),
        [],
    );

    return (
        <Modal
            destroyOnClose
            title={'Room Details'}
            visible={Boolean(roomId)}
            onCancel={() => setRoomId(undefined)}
            footer={null}
            maskClosable={false}
            width={800}
        >
            <Skeleton loading={isFetching}>
                <RoomDetails data={data} />
            </Skeleton>
        </Modal>
    );
});

GameRoomDetail.displayName = 'Room Details';

export default GameRoomDetail;
