import React, { forwardRef } from 'react';
import { Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { GameRoomForm } from '../../../components/game-rooms/components';
import { ApiClient } from '../../../api';

export interface NewRoomFormRef {
    openModal: () => void;
}

const NewRoomForm = forwardRef<NewRoomFormRef>((_, ref) => {
    const queryClient = useQueryClient();
    const [visible, setVisible] = React.useState(false);

    React.useImperativeHandle(
        ref,
        () => ({
            openModal: () => setVisible(true),
        }),
        [],
    );

    const { mutate: onFinish, isLoading } = useMutation(ApiClient.createGameRooms, {
        onSuccess: async () => {
            await queryClient.cancelQueries('game-rooms');
            await queryClient.invalidateQueries('game-rooms');

            ApiClient.showSuccess('Room successfully added');
            setVisible(false);
        },
    });

    return (
        <Modal
            destroyOnClose
            title={'Create new Room'}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            maskClosable={false}
            width={800}
        >
            <GameRoomForm onFinish={onFinish} loading={isLoading} />
        </Modal>
    );
});

NewRoomForm.displayName = 'New Room Form';

export default NewRoomForm;
