import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './store';
import { AppRouter } from './router';
import { LIGHT_THEME } from './config/theme';
import GlobalStyle from './assets/styles/globalStyle';
import { InMemoryJWTManager } from './api';
import queryClient from './store/queryClient';

function App() {
    const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        async function initApp() {
            const token = (await sessionStorage.getItem('token')) || undefined;
            InMemoryJWTManager.setAccessToken(token);
        }
        initApp()
            .catch(null)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return null;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <ThemeProvider theme={LIGHT_THEME}>
                    <Router>
                        <GlobalStyle />
                        <PersistGate persistor={persistor}>
                            <AppRouter />
                        </PersistGate>
                    </Router>
                </ThemeProvider>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
