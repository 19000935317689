import React from 'react';
import styled from 'styled-components';
import { Col, Row, Tabs } from 'antd';
import { DrawerLayout } from '../../Layout';
import { DeploymentUnitOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import Profile from './profile';
import ChangePassword from './change-password';
import FeatureManagement from './feature-management';
const { TabPane } = Tabs;

const SettingsWrapper = styled.div`
    form {
        max-width: 600px;
        margin: auto;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #16a085;
    }
`;

const Settings = () => {
    return (
        <DrawerLayout>
            <SettingsWrapper>
                <Row gutter={16}>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1" type="card">
                            <TabPane
                                tab={
                                    <span>
                                        <UserOutlined />
                                        Profile
                                    </span>
                                }
                                key="1"
                            >
                                <Profile />
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        <UnlockOutlined />
                                        Password
                                    </span>
                                }
                                key="2"
                            >
                                <ChangePassword />
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        <DeploymentUnitOutlined />
                                        Feature Management
                                    </span>
                                }
                                key="3"
                            >
                                <FeatureManagement />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </SettingsWrapper>
        </DrawerLayout>
    );
};

export default Settings;
