import PrivacyPolicy from './privacy-policy.md';
import TermsConditions from './terms-condition.md';
import CookiePolicy from './cookie-policy.md';
import FairPlayPolicy from './fair-play-policy.md';

export default {
    PrivacyPolicy,
    TermsConditions,
    CookiePolicy,
    FairPlayPolicy,
};
