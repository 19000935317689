import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { Button, Comment, Rate } from 'antd';
import moment from 'moment';

interface PropsData {
    data: Review;
}

const PendingReviewList: FC<PropsData> = ({ data }) => {
    const { isLoading, mutate } = useMutation<any, any, string>('approveReview');

    const user = data?.user;
    const content = <span>{data?.content}</span>;
    const avatar = user?.profileURL || <div className="custom-avatar">{user?.username?.charAt(0)}</div>;

    const actions = [
        <Rate key={'rating-element'} allowHalf disabled value={data?.rating} />,
        <Button
            key="comment-list-reply-to-0"
            className="primary-button approved-btn"
            loading={isLoading}
            onClick={(e) => {
                e.stopPropagation();
                mutate(data.id);
            }}
        >
            Approve
        </Button>,
    ];

    return (
        <div>
            <Comment
                content={content}
                datetime={moment(data?.createdAt).format('MMMM D, YYYY')}
                author={user?.username}
                avatar={avatar}
                actions={actions}
            />
        </div>
    );
};

export default PendingReviewList;
