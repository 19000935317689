import { Pagination } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { PaginationConfig } from '../../../common';

const Wrapper = styled.div`
    .ant-pagination {
        display: flex;
        flex-wrap: wrap;
        row-gap: 8px;
        justify-content: flex-end;
    }
`;

interface Props {
    total: number;
    pageSize: number;
    currentPage: number;
    onChange: any;
}

const PaginationComponent: FC<Props> = ({ total, currentPage, pageSize, onChange }) => {
    if (!total) {
        return null;
    }
    return (
        <Wrapper>
            <Pagination
                total={total}
                pageSize={pageSize}
                current={currentPage}
                onChange={onChange}
                responsive
                showTotal={PaginationConfig.showTotal}
                itemRender={PaginationConfig.itemRender}
            />
        </Wrapper>
    );
};

export default PaginationComponent;
