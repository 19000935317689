import { css } from 'styled-components';

const style = () => css`
    background: #082d2a;
    max-width: 300px;

    min-height: 60%;

    padding: 16px;
    border-radius: 16px;
    margin: 10px auto;

    .images {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-bottom: 12px;
        /* background: linear-gradient(112.17deg, #f05918 14.43%, #bc79ff 94.82%); */
        background-color: #111;
        min-height: 100px;
        border-radius: 16px;
        //min-width: 268px;

        .image {
            min-width: 100%;
            margin-right: 8px;
            border-radius: 16px;
            /* background-color: #c4c4c4; */
            object-fit: cover;

            :last-child {
                margin-right: 0;
            }

            img {
                width: 100%;
                border-radius: 16px;
                object-fit: cover;
            }
        }
    }

    .thumbnail {
        position: relative;
        height: 72px;
        aspect-ratio: 1;
        border-radius: 8px;
        padding: 1px;
        overflow: hidden;
        background: linear-gradient(112.17deg, #f05918 14.43%, #bc79ff 94.82%);

        img {
            border-radius: 8px;
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
        }
    }
    .title {
        color: #ffffff;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        padding: 5px 0 0;
        text-transform: capitalize;
        margin-top: 10px;
    }
    .developer {
        display: flex;
        /* align-items: center; */
        margin-top: 10px;
        img {
            max-height: 20px;
        }
        div {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            color: #0fe880;
            padding-top: 4px;
            margin-left: 10px;
        }
    }

    .description {
        color: #fff;
        /* height: 200px; */

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        p {
            text-overflow: ellipsis;
        }
    }
`;

export default style();
