import { useEffect, useState } from 'react';
import MarkdownFile from '../../assets/markdown';

export type MarkdownFileName = keyof typeof MarkdownFile;

export default function useMarkdownFile(fileName: MarkdownFileName) {
    const [data, setData] = useState<string>('');

    useEffect(() => {
        fetch(MarkdownFile[fileName])
            .then((res) => res.text())
            .then(setData);
    }, []);

    return data;
}
