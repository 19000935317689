import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import styles from './styles';
import { Button, Col, Form, Input, Layout, Select } from 'antd';
import HomeNavbar from '../../components/homapage/component/HomeNavbar';
import HomeFooter from '../../components/homapage/component/HomeFooter';

const ContactWrapper = styled(Layout)`
    ${styles}
`;

const ContactContainer = () => {
    const [form] = Form.useForm();
    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const onVerify = useCallback((token) => {
        setToken(token);
    }, []);

    const onFinish = (values: any) => {
        if (token) {
            window.open(`mailto:support@weplayarcade.com?subject=${values?.topic}&body=${values?.message}`);
        }
        setRefreshReCaptcha((r) => !r);
    };

    return (
        <ContactWrapper>
            <HomeNavbar isContact />
            <div className="heading">Contact Us</div>
            <div className="form-wrapper">
                <div className="form">
                    <Form
                        layout="vertical"
                        style={{ width: '90%', maxWidth: '600px', margin: 'auto' }}
                        form={form}
                        onFinish={onFinish}
                    >
                        <Col span={24} className="gutter-row">
                            <Form.Item label={'Topic'} name="topic">
                                <Select
                                    placeholder={'Select topic'}
                                    options={[
                                        'Business Opportunities/Vendors',
                                        'Legal/Privacy',
                                        'Media/Press Enquiries',
                                        'People/Careers',
                                        'Player Support',
                                        'Security',
                                        'Sponsorships',
                                        'Technical Support',
                                        'Other',
                                    ].map((item, index: number) => ({
                                        key: index.toString(),
                                        id: index?.toString(),
                                        name: item,
                                        value: item,
                                    }))}
                                    fieldNames={{
                                        label: 'name',
                                        value: 'name',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="gutter-row">
                            <Form.Item
                                label={'Your Name'}
                                name="name"
                                rules={[{ required: true, message: 'Enter name' }]}
                            >
                                <Input placeholder={'Enter name'} className={'weplay-input'} />
                            </Form.Item>
                        </Col>
                        {/* <Col span={24} className="gutter-row">
                            <Form.Item
                                label={'Email'}
                                name="email"
                                rules={[{ required: true, message: 'Enter email', type: 'email' }]}
                            >
                                <Input placeholder={'Enter email'} className={'weplay-input'} />
                            </Form.Item>
                        </Col> */}
                        <Col span={24} className="gutter-row">
                            <Form.Item
                                label={'Message'}
                                name="message"
                                rules={[{ required: true, message: 'Enter message' }]}
                            >
                                <Input.TextArea rows={4} placeholder={'Enter message'} className={'weplay-input'} />
                            </Form.Item>
                        </Col>
                        <GoogleReCaptchaProvider reCaptchaKey="6LfP8AEiAAAAAOpq2ohWeheQKtMyeBdbPzZVzAsO">
                            <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
                        </GoogleReCaptchaProvider>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="primary" htmlType="submit" className="create-button">
                                Send message
                            </Button>
                        </Col>
                    </Form>
                </div>
            </div>
            <div style={{ height: 100 }} />
            <HomeFooter />
        </ContactWrapper>
    );
};

export default ContactContainer;
