export const IS_USER_SIGNING_IN = 'IS_USER_SIGNING_IN';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAILURE = 'USER_SIGN_IN_FAILURE';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const GET_PROFILE = 'GET_PROFILE';
export const IS_GETTING_PROFILE = 'IS_GETTING_PROFILE';

export type { AuthReducer } from './authReducer.type';

export * from './user.type';
