import React, { FC, useState } from 'react';
import { Dropdown } from 'antd';
import { BellFilled, MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { COLORS } from '../../config/theme';
import AuthUser from '../AuthUser';
import LogOut from './LogOut';
import { getTitleFromPath } from './data';

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    height: inherit;
    .title {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
    }
    .notification-button {
        font-size: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY]};
    }
`;

interface HeaderProps {
    collapsed: boolean;
    toggle: () => void;
}

const Header: FC<HeaderProps> = ({ collapsed, toggle }) => {
    const [showNotification, setShowNotification] = useState(false);
    const [showUserDropdown, setShowUserDropDown] = useState(false);
    const title = getTitleFromPath(window.location.pathname);

    return (
        <HeaderWrapper>
            {!collapsed ? <MenuOutlined onClick={toggle} /> : null}
            <div className="title" style={{ marginLeft: collapsed ? 0 : 20 }}>
                {title}
            </div>
            <div style={{ marginLeft: 'auto' }} />
            <Dropdown
                overlay={<></>}
                visible={showNotification}
                trigger={['click']}
                onVisibleChange={(visible) => setShowNotification(visible)}
            >
                <BellFilled className="notification-button" />
            </Dropdown>
            <Dropdown
                overlay={<LogOut />}
                visible={showUserDropdown}
                trigger={['click']}
                onVisibleChange={(visible) => setShowUserDropDown(visible)}
            >
                <AuthUser onClick={() => setShowUserDropDown(true)} />
            </Dropdown>
        </HeaderWrapper>
    );
};

export default Header;
