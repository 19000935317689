import { Collapse } from 'antd';
import React from 'react';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import styles from './styles';
import HomeNavbar from './HomeNavbar';
import HomeFooter from '../../components/homapage/component/HomeFooter';

const { Panel } = Collapse;

interface SectionItem {
    title: string;
    content: string;
    color?: string;
}

const SECTIONS: Array<SectionItem> = [
    {
        title: 'What is Weplay?',
        content:
            'Weplay is an esports online gaming community and a social gaming network that allows players to battle in multiplayer games with other players from around the world to win rewards. Weplay provides features like discovery, contests, chats, leaderboards, achievements, multiplayer functionality, tournaments, and more.',
        color: '#f7b131',
    },
    {
        title: 'How do I redeem rewards?',
        content:
            'Redeem rewards by loading your wallet and competing in different contest rooms with prizes then when you win you are able to redeem the prize winnings through gift cards, bank accounts, or mobile money.',
        color: '#f83608',
    },
    {
        title: 'Is Weplay social?',
        content:
            'Weplay is a social gaming network allowing users to chat and socialize while competing with each other over multiplayer games.',
        color: '#082d2a',
    },
    {
        title: 'Where do I find Weplay?',
        content:
            'Find Weplay by searching it on the App Store and Play Store or receiving an invite link from a friend.',
        color: '#f7b131',
    },
    {
        title: 'What does it cost to play on Weplay?',
        content:
            'Users can play free-to-play games or load their Weplay wallet to compete with other players in games with prize winnings.',
        color: '#f83608',
    },
    {
        title: 'What do I need to join Weplay Arcade?',
        content: 'Sign up using your full name, phone number, email, and password to create an account.',
        color: '#082d2a',
    },
    {
        title: 'How do I get started with Weplay?',
        content:
            "To start, download the Weplay app from your device's Play Store or App Store, sign up for an account, and you'll be ready to play and earn!",
        color: '#f7b131',
    },
    {
        title: 'Is Weplay free to join?',
        content: 'Yes! Joining Weplay is absolutely free. There are no membership fees or hidden costs.',
        color: '#f83608',
    },
    {
        title: 'How can I earn real money?',
        content:
            "Players can earn real money by competing in games with prize winnings and tournaments, when you win you can withdraw your money through gift cards, bank accounts, or mobile money. Specific details can be found in each game's description within the platform.",
        color: '#082d2a',
    },
    {
        title: 'How do I withdraw my earnings?',
        content:
            "Go to the 'Wallet' section in the app. Once there, you can request a withdrawal to your preferred payment method. Please note that a minimum withdrawal amount may apply.",
        color: '#f7b131',
    },
    {
        title: 'What payment methods do you support?',
        content:
            'We support several payment methods including mobile money and bank transfers. Availability might vary depending on your region.',
        color: '#f83608',
    },
    {
        title: 'Are there any age restrictions to join Weplay?',
        content: 'Yes, players must be 18 years or older to participate in real money competitions and withdrawals.',
        color: '#082d2a',
    },
    {
        title: 'Are the games fair and how do you ensure this?',
        content:
            'Absolutely! Weplay is committed to maintaining a fair gaming environment. Our games are periodically audited and tested for fairness. We also have a fair play policy that sets out mandatory standards of conduct that are expected from users of the platform and ensures games played adhere to high standards of fairness, competition, skill, and sportsmanship.',
        color: '#f7b131',
    },
    {
        title: 'Can I play with friends?',
        content:
            "Of course! Weplay has a 'Friends' feature that allows you to connect, challenge, and compete with friends, making your gaming experience even more exciting.",
        color: '#f83608',
    },
    {
        title: 'How do you calculate the prize money for tournaments?',
        content:
            'Prize money for tournaments is based on factors such as the number of participants, entry fees, and specific tournament guidelines. Each tournament will have a detailed prize breakdown available for players.',
        color: '#082d2a',
    },
    {
        title: 'What if I face any technical issues?',
        content:
            "If you encounter any technical difficulties, you can contact our support team through the 'Support' section in the app. We aim to resolve all issues promptly.",
        color: '#f7b131',
    },
    {
        title: ' Can I play Weplay games on any device?',
        content:
            "Weplay is primarily designed for mobile devices, so it works best on smartphones and tablets. Ensure your device meets the game's requirements for optimal experience.",
        color: '#f83608',
    },
    {
        title: 'Do I need an internet connection to play?',
        content: 'Yes, you need the internet to play on Weplay, ensure you have a stable internet connection.',
        color: '#082d2a',
    },
    {
        title: 'How do I stay updated with new games and tournaments?',
        content:
            'Keep your app updated and ensure the app’s notification feature is turned on on your phone! We are constantly adding new games and holding tournaments which we announce through the app’s notification.',
        color: '#f7b131',
    },
    {
        title: 'Is my personal information safe with Weplay?',
        content:
            'Absolutely. We prioritize user privacy and security. All personal data is stored in encrypted formats and we never share it with third parties without user consent.',
        color: '#f83608',
    },
    {
        title: 'Is there a refund policy?',
        content:
            'In the event a Weplay game is canceled due to a technical error or any other reason solely attributable to the Platform, the amount paid by all Genuinely Affected Users shall be refunded. Refunds on account of such cancellations, where reviewed and confirmed by the Weplay team in accordance with the terms and conditions, will be made within 14 (fourteen) days of receipt of such request.',
        color: '#082d2a',
    },
    {
        title: 'What if my game has a technical issue?',
        content:
            "If you encounter technical issues, we recommend reaching out to our support team first. We're committed to helping resolve any issues you might face. If the problem persists and you're still within the refund window, you can request a refund.",
        color: '#f7b131',
    },
    {
        title: 'Do I lose when I close the app or game in the middle of a game?',
        content:
            'You have 60 seconds to make a move and get back to the game when you close the app or game, if you take longer it results in a loss. Leaving another player hanging in a game is considered a loss in terms of sportsmanship and fair play.',
        color: '#f83608',
    },
    {
        title: 'Can I withdraw the money that I deposit into my wallet?',
        content:
            'The deposit balance will only be used to play games and cannot be withdrawn, winnings can be withdrawn to your mobile money or bank.',
        color: '#082d2a',
    },
    {
        title: 'How long do I have to make a move on a game?',
        content:
            'You have 60 seconds to make a move in the game, if you don’t make a move in 60 seconds you lose the game and your competitor wins.',
        color: '#f7b131',
    },
    {
        title: 'Do I lose when I leave another player hanging on a game?',
        content:
            'Yes, leaving another player hanging in a game is considered a loss in terms of sportsmanship and fair play.',
        color: '#f83608',
    },
    {
        title: 'What happens when my internet is not stable during the game?',
        content:
            'If your internet connection is unstable during the game, you may experience lag, disconnections, or other gameplay disruptions. This could potentially result in a loss; it’s always recommended to ensure a stable internet connection for the best gaming experience. We give you up to 60 seconds to come back into the game when your internet goes offline during a game.',
        color: '#082d2a',
    },

    {
        title: 'Can I play with my winnings?',
        content: 'Yes, winnings can be used to play games in case your deposit balance is insufficient',
        color: '#f7b131',
    },
];

const FaqWrapper = styled.div`
    ${styles}
`;

const FaqsContainer = () => {
    return (
        <>
            <HomeNavbar />
            <FaqWrapper>
                <h1>Frequently Asked Questions</h1>
                <Collapse
                    accordion
                    bordered={false}
                    expandIconPosition={'right'}
                    ghost
                    style={{ width: '100%' }}
                    expandIcon={() => <ChevronDown size={32} />}
                >
                    {SECTIONS.map((section, index) => (
                        <Panel header={section.title} key={index}>
                            <p>{section.content}</p>
                        </Panel>
                    ))}
                </Collapse>
            </FaqWrapper>
            <HomeFooter />
        </>
    );
};

export default FaqsContainer;
