import { QueryFunctionContext } from 'react-query';
import omit from 'lodash/omit';
import { API } from '../api';

export function getGameRooms({ signal, pageParam = 1 }: QueryFunctionContext) {
    return API.get(API.PATH.getRooms, {
        signal,
        params: {
            page: pageParam,
        },
    }).then((res) => res.data);
}

export function getGameRoomDetails({ signal, queryKey }: QueryFunctionContext) {
    const [, roomId] = queryKey as string[];
    return API.get(API.PATH.getRoomDetail(roomId), { signal }).then((res) => res.data);
}

export function createGameRooms(data: any) {
    return API.post(API.PATH.createRoom, data).then((res) => res.data);
}

export function updateGameRooms(data: any) {
    return API.patch(API.PATH.updateRoom(data.id), omit({ ...data }, ['id'])).then((res) => res.data);
}

export function deleteGameRooms(gameId: string) {
    return API.delete(API.PATH.deleteRoom(gameId)).then((res) => res.data);
}
