import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import background from '../../assets/icons/background.svg';
import { COLORS } from '../../config/theme';

const ErrorWrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url(${background});

    .title {
        color: ${(props) => props.theme[COLORS.WHITE]};
        font-size: 160px;
        line-height: 120px;
        font-weight: bolder;
    }
    .subtitle {
        color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        font-size: 52px;
        font-weight: bolder;
    }
    .caption {
        color: ${(props) => props.theme[COLORS.BORDER]};
        //font-size: 80px;
        //font-weight: bolder;
    }

    .primary-button {
        padding: 5px 16px;
        margin-top: 32px;
    }
`;

const Error404 = () => {
    return (
        <ErrorWrapper>
            <div className="title">404</div>
            <div className="subtitle">Page Not Found</div>
            <div className="caption">Were sorry, the page you requested could not be found</div>
            <div className="caption">Please go back to the home page</div>
            <Link to={'/'}>
                <Button className={'primary-button'}>Go Home</Button>
            </Link>
        </ErrorWrapper>
    );
};

export default Error404;
