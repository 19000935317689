import React, { FC } from 'react';
import styled, { css } from 'styled-components';

function scrollAnimation(i) {
    return css`
        &:nth-child(${i + 1}) {
            animation-delay: ${2.5 * i}s;
        }
    `;
}

const TextChangeWrapper = styled.div`
    overflow: hidden;
    margin-top: 20px;
    height: calc(clamp(2rem, 4vw, 7rem) + 20px);
    padding-bottom: 20px;
    position: relative;

    .text-white {
        color: #ffffff;
    }

    .text-red {
        color: #f83608;
    }

    .sentence {
        color: #ffffff;
        min-height: clamp(2rem, 4vw, 7rem);
        font-weight: bold;
    }

    span {
        display: block;
        color: #ffffff;
        font-size: clamp(2rem, 4vw, 7rem);
        font-weight: bold;

        animation: topToBottom 12.5s linear infinite 0s;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
        width: 100%;

        ${() => loopArr.map((_, index) => scrollAnimation(index + 1))}
    }

    @keyframes topToBottom {
        0% {
            opacity: 0;
        }
        5% {
            opacity: 0;
            transform: translateY(100%) translatex(-50%);
        }
        10% {
            opacity: 1;
            transform: translateY(0px) translatex(-50%);
        }
        25% {
            opacity: 1;
            transform: translateY(0px) translatex(-50%);
        }
        30% {
            opacity: 0;
            transform: translateY(-100%) translatex(-50%);
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
`;

const loopArr = ['READY', 'SET', 'GO', 'GAME ON', 'BEAST MODE'];

const TextChange: FC = () => {
    return (
        <TextChangeWrapper>
            <div className="sentence">
                {loopArr.map((value, index) => (
                    <span className={[3, 5].includes(index) ? 'text-red' : 'text-white'} key={value}>
                        {value}
                    </span>
                ))}
            </div>
        </TextChangeWrapper>
    );
};

export default TextChange;
