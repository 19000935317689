import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ResponsiveContainer, PieChart, Pie, Sector } from 'recharts';
import styles from './styles';

const GameStatsWrapper = styled.div`
    ${styles}
`;

interface Props {
    gameStats: { [key: string]: any };
}

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, value, name } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const minutes = value ?? 0;
    const hoursAndMinutes = `${Math.floor(minutes / 60)}h ${minutes % 60}m`;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
                {name}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`Time: ${hoursAndMinutes}`}
            </text>
        </g>
    );
};

const GameStatsComponent: FunctionComponent<Props> = ({ gameStats }: Props) => {
    const pieData = Object.values(gameStats)
        .filter((val) => val?.minutesPlayed > 0)
        .sort((a, b) => b?.minutesPlayed - a?.minutesPlayed)
        .map((val) => ({
            name: val?.name,
            value: val?.minutesPlayed,
        }));
    const totalMinutes = pieData.reduce((acc, val) => acc + val.value, 0);
    const totalHoursAndMinutes = `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`;
    const [activeIndex, setActiveIndex] = React.useState(0);
    return (
        <GameStatsWrapper>
            <div className="stats-heading">In-Game Hours</div>
            <ResponsiveContainer width="100%" height={225}>
                <PieChart width={300} height={300}>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#1E87F0"
                        dataKey="value"
                        nameKey="name"
                        onMouseEnter={(_, index) => setActiveIndex(index)}
                    />
                </PieChart>
            </ResponsiveContainer>
            <div className="total-hours">
                <div className="total-hours-heading">Total Hours: {totalHoursAndMinutes}</div>
            </div>
        </GameStatsWrapper>
    );
};

export default GameStatsComponent;
